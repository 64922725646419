import {FeatureGroup, LayersControl, Pane} from "react-leaflet";
import * as React from "react"
import {LeafletLayerZIndexes} from "src/constants";
import { useWorkspace } from "src/hooks/useWorkspace";

type RoofFaceFeatureGroupProps = {
  children: React.ReactNode
}

export const RoofFaceFeatureGroup = (props: RoofFaceFeatureGroupProps) => {
  const { state } = useWorkspace()
  const { settings } = state
  if (!settings.isRoofFacesLayerVisible) return null
  return (
    <Pane name="roof-faces" style={{zIndex: LeafletLayerZIndexes.roofFaces}}>
      <FeatureGroup>
        {props.children}
      </FeatureGroup>
    </Pane>
  )
}
