export enum LoadingProcessNames {
  LIGHTMILE_DESIGN = "Importing Lightmile Design",
  SITE_MODEL = "Loading Site Model",
  AERIAL_IMAGERY = "Loading Aerial Imagery",
  DESIGN = "Loading Design",
  CUSTOMER = "Loading Customer",
  DESIGN_CONSTRAINTS = "Loading Design Constraints",
  RENDER_STATIC_IMAGE = "Rendering Static Image",
  SHADE_MODEL = "Loading Shade Model",
  OFFER = "Loading Offer",
  PRODUCT_TO_EQUIPMENT_MAPPING = "Mapping Offer Product Selection to Equipment",
  MAXFILL = "Maximizing Module Layout",

  DESIGN_SIMULATION = "Generating High-Res Simulation",
  RELOAD_DESIGN = "Re-Loading Design", // (and Production Simulation)
  EXPORT = "Exporting to Lightmile",
  UPDATING_OFFER = "Updating Offer",
}

export enum LoadingProcessGroups {
  INITIALIZE_IHD = "Initializing iHD",
  FINALIZE_DESIGN = "Finalizing Design",
  PREPARE_TO_GO_TO_PRICING = "Updating Product Information"
}
