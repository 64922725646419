import {
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { FinancialProductsCell } from "./FinancialProductsCell";
import {
  DesignConstraintType,
  PvProduct,
} from "@sunrun/design-tools-domain-model";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { formatNumber } from "@sunrun/design-tools-themes";

type FinancialProductsRowProps = {
  designConstraint: DesignConstraintType;
  fullscreen: boolean | undefined;
};

export const FinancialProductsRow = ({
  designConstraint, 
  fullscreen
}: FinancialProductsRowProps) => {
  const { state } = useWorkspace();
  const { design, productionSimulation, customer, designConstraints } = state;
  const designFinalized = deriveIsDesignFinalized(state)
  const simulationToUse = designFinalized ? productionSimulation : deriveLowResProductionSimulation(state);
  const systemSunHours = simulationToUse?.getSystemSunHours(design) ?? 0;
  const usageOffsetPercent = simulationToUse?.getUsageOffsetPercent(customer) ?? 0;
  const products = [
    PvProduct.Monthly,
    PvProduct.Prepaid,
    PvProduct.Cash,
    PvProduct.Loan,
  ];
  if (!design || !designConstraints) {
    return null;
  }
  const { acSystemSizeKw, cecAcSystemSizeKw, dcSystemSizeKw } = design;

  let rowLabel: string;
  let getValueLabel: (value: number) => string;
  let designValue: number | undefined;
  switch (designConstraint) {
    case DesignConstraintType.MinSunHours:
      rowLabel = "Min Sun Hours";
      getValueLabel = (value) =>
        formatNumber({ value, maximumFractionDigits: 0 });
      designValue = systemSunHours;
      break;
    case DesignConstraintType.MaxOffsetPercent:
      rowLabel = "Max Offset";
      getValueLabel = (value) =>
        `${formatNumber({ value: value * 100, maximumFractionDigits: 0 })}%`;
      designValue = usageOffsetPercent;
      break;
    case DesignConstraintType.MaxDcSystemSizeKw:
      rowLabel = "Max DC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 0 })}kW`;
      designValue = dcSystemSizeKw;
      break;
    case DesignConstraintType.MinDcSystemSizeKw:
      rowLabel = "Min DC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 1 })}kW`;
      designValue = dcSystemSizeKw;
      break;
    case DesignConstraintType.MaxAcSystemSizeKw:
      rowLabel = "Max AC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 1 })}kW`;
      designValue = acSystemSizeKw;
      break;
    case DesignConstraintType.MaxCecAcSystemSizeKw:
      rowLabel = "Max CEC-AC";
      getValueLabel = (value) =>
        `${formatNumber({ value, maximumFractionDigits: 1 })}kW`;
      designValue = cecAcSystemSizeKw;
      break;
  }

  const rowData = () => {
    let data: { allowed: boolean; value: string | undefined }[] = [];
    products.forEach((product, index) => {
      const constraintsByProduct =
        designConstraints.getConstraintsByProduct(product);
      const constraint = designConstraints.getConstraintByType(
        constraintsByProduct,
        designConstraint
      );
      const { value } = constraint;
      const isConstraintMet = designConstraints.validateDesignConstraint(
        designValue,
        designConstraint,
        product
      );

      const constraintValueLabel = value ? getValueLabel(value) : undefined;
      const cellData = {
        allowed: isConstraintMet,
        value: constraintValueLabel,
      };
      data.push(cellData);
    });
    return data;
  };

  return (
    <TableRow>
      <TableCell sx={{px: fullscreen ? 1 : "default"}}>
        <Typography color="primary" variant="body2">{rowLabel}</Typography>
      </TableCell>
      {rowData().map((cell, index) => (
        <FinancialProductsCell key={index} allowed={cell.allowed} value={cell.value} fullscreen={fullscreen} />
      ))}
    </TableRow>
  );
};
