
import { Typography } from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";

type ErrorModalProps = {
  title: string | undefined;
  message: string | undefined;
  open: boolean;
  onClose: () => void;
  error: Error | undefined;
}

export const ErrorModal = ({ title = "Oops, something went wrong!", message, error, open, onClose }: ErrorModalProps) => {
  return(
    <ModalLayout 
      open={open}
      title={title}
      onClose={onClose}
      actionButtons
      error={error}
    >
      <Typography
        variant="body2"
      >
        {message}
      </Typography>
    </ModalLayout>
  )
}