import {useDomainModel} from "src/hooks/useDomainModel";
import React from "react";
import {useWorkspace} from "src/hooks/useWorkspace";


type UseDomainModelByDesignIdProp = {
  designId?: string
  designVersion?: number
  signedRootDesignId?: string
  interconnectionAppliedDesignId?: string
  copyFromDesignId?: string
}

export const useDomainModelByDesignId = ({
    designId,
    designVersion,
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId,
  } : UseDomainModelByDesignIdProp
) => {
  const {state} = useWorkspace()

  const [siteModelId, setSiteModelId] = React.useState<string | undefined>()
  const [siteModelVersion, setSiteModelVersion] = React.useState<number | undefined>()
  const [geoRasterId, setGeoRasterId] = React.useState<string | undefined>()

  const [productionSimulationId, setProductionSimulationId] = React.useState<string | undefined>()
  const [productionSimulationVersion, setProductionSimulationVersion] = React.useState<number | undefined>()
  const [solarResourceId, setSolarResourceId] = React.useState<string | undefined>()
  const [solarResourceVersion, setSolarResourceVersion] = React.useState<number | undefined>()

  const useDomainModelValue = useDomainModel({
    designId,
    designVersion,
    geoRasterId,
    siteModelId,
    siteModelVersion,
    productionSimulationId,
    productionSimulationVersion,
    solarResourceId,
    solarResourceVersion,
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId,
  })

  React.useEffect(() => {
    if (state.design){
      if (state.design.siteModelId != null && state.design.siteModelVersion != null) {
        setSiteModelId(state.design.siteModelId)
        setSiteModelVersion(state.design.siteModelVersion)
      }
      if (state.design.productionSimulationId != null && state.design.productionSimulationVersion != null) {
        setProductionSimulationId(state.design.productionSimulationId)
        setProductionSimulationVersion(state.design.productionSimulationVersion)
      }
      if (state.design.solarResourceId != null && state.design.solarResourceVersion != null) {
        setSolarResourceId(state.design.solarResourceId)
        setSolarResourceVersion(state.design.solarResourceVersion)
      }
    }
  }, [
    state.design?.siteModelId,
    state.design?.siteModelVersion,
    state.design?.productionSimulationId,
    state.design?.productionSimulationVersion,
    state.design?.solarResourceId,
    state.design?.solarResourceVersion
  ])

  React.useEffect(() => {
    if (state.siteModel && state.siteModel.selectedOrthogonalGeoRasterId){
      setGeoRasterId(state.siteModel.selectedOrthogonalGeoRasterId)
    }
  }, [state.siteModel?.selectedOrthogonalGeoRasterId])

  return useDomainModelValue
}
