import React from "react";
import { SVGOverlay } from "react-leaflet";
import { LatLngBounds } from "leaflet";
import { SwapVertTwoTone } from "@material-ui/icons";

export type MapLabelProps = {
  angleDegrees: number,
  leafletPane: string,
  bounds: LatLngBounds,
  fontStroke: string,
  label: string | undefined
  // feel free to add font/size/color etc if we ever make more use of this component
}
const ratioOfMetersToSvgFontBox = 36;
// draw a non-interactive label in the tooltip pane of the parent leaflet map
// this component's main claim to fame is that it can be rotated, and isn't attched to a leaflet-marker!

export function MapLabel({fontStroke,angleDegrees,bounds,label,leafletPane}: MapLabelProps) {
  if (label){
    const W = (bounds.getEast()-bounds.getWest())*ratioOfMetersToSvgFontBox;
    const H = (bounds.getNorth()-bounds.getSouth())*ratioOfMetersToSvgFontBox;
    return (
      <SVGOverlay pane={leafletPane}
                  attributes={{  viewBox:  `0 0 ${W} ${H}`}}
                  bounds={bounds}
                  key={`0 0 ${W} ${H}`}   //important, this will force MapLabel to rerender
      >
        <g transform={`rotate(${angleDegrees})`} transform-origin="center">
          <text
            fontSize="12"
            fontWeight="lighter"
            fontFamily="system-ui"
            letterSpacing="1px"
            stroke={fontStroke}
            text-align="middle"
            textAnchor="middle"
            dominantBaseline="middle"
            x="50%"
            y="50%"
          >{label}
          </text>
        </g>
      </SVGOverlay>);
  }
  return null

}
