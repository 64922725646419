/**
 * Borrowed from Redux Toolkit https://github.com/reduxjs/redux-toolkit/blob/bc62c9e4fee8149b8c47d3b6c15c911c19b7a3b7/packages/toolkit/src/createAction.ts#L21-L38
 */
import {Map} from "leaflet";
import {Position} from "@sunrun/design-tools-geometry";
import {Module, RoofFace} from "@sunrun/design-tools-domain-model";

export type PayloadAction<P = void,
  T extends string = string,
  M = never,
  E = never> = {
  payload: P
  type: T
} & ([M] extends [never]
  ? {}
  : {
    meta: M
  }) &
  ([E] extends [never]
    ? {}
    : {
      error: E
    })

export type EmptyAction<T extends string = string> = {
  type: T
}

// Workspace event actions (like 'click' + 'roofFace') require all three properties: type, payload, and meta.
// Note the use of the 'meta' property to contain the Leaflet layer is to keep these actions compatible with
// Flux Standard Actions. See https://redux.js.org/tutorials/fundamentals/part-7-standard-patterns#flux-standard-actions
export type WorkspaceEventAction<T extends string = string, M extends EventInfo = EventInfo, P extends WorkspaceEvent = WorkspaceEvent> = {
  type: T
  meta: M
  payload: P
}

export type EventInfo = {
  layer: InteractiveLeafletLayer
  device: InputDevice,
  map?: Map
}

export enum InteractiveLeafletLayer {
  map = 'map',
  roofFace = 'roofFace',
  module = 'module',
}

export enum InputDevice {
  Mouse = 'Mouse',
  Touch = 'Touch',
  Keyboard = 'Keyboard'
}

// FIXME: This union is not good. When we access WorkspaceEvent.propagatedFrom.layer we end up doing type coercion
// to get stuff from the layer and this isn't safe. Also the type isn't a correct abstraction for a Leaflet GeoJSON
// layer since RoofFace and Module are actually domain models with all kinds of methods that you can't invoke on a
// GeoJSON feature. The only reason we aren't seeing bugs yet is that we have been careful not to call these.
export type WorkspaceLayer = Map | RoofFace | Module

// A WorkspaceEvent is an abstraction around Leaflet and window events:
//  * so they can both be handled the same way
//  * so we are binding to objects that are easier to mock during testing.
export type WorkspaceEvent = {
  position: Position
  propagatedFrom: {
    layer: WorkspaceLayer
  }
  button: MouseButtonOrKeyboardKey,
  stopEventPropagation: () => void
}

export enum MouseButtonOrKeyboardKey {
  left = 'left',
  right = 'right',
  keyDown = 'keyDown',
  keyUp = 'keyUp',
  shift = 'shift',
  control = 'control',
  none = 'none'
}

export type WorkspaceEventType = PointerEventType | KeyboardEventType
export type PointerEventType = 'click' | 'pointerDown' | 'pointerMove' | 'pointerUp' | 'dblclick' | 'contextmenu'
export type KeyboardEventType = 'ArrowDown' | 'ArrowUp' | 'ArrowLeft' | 'ArrowRight'

