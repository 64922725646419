"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.findBucketNameByPrefix = void 0;
const client_s3_1 = require("@aws-sdk/client-s3");
const s3Client = new client_s3_1.S3Client({ region: 'us-west-2' });
/**
 * If there is more than one bucket found, then an error is thrown.
 *
 * @param bucketNamePrefix the prefix of the bucket. We are using prefixes to identify buckets because we use CDK generated bucket names which have a bunch of random characters
 * at the end of the name. The beginning of the bucket name seems to be consistent, hence the use of prefixes.
 */
function findBucketNameByPrefix(bucketNamePrefix) {
    return __awaiter(this, void 0, void 0, function* () {
        const listBucketsResp = yield s3Client.send(new client_s3_1.ListBucketsCommand({}));
        const bucketsMatchingPrefix = listBucketsResp.Buckets.filter(bucket => bucket.Name.startsWith(bucketNamePrefix));
        if (bucketsMatchingPrefix.length === 0) {
            throw new Error(`Could not find a bucket matching the prefix ${bucketNamePrefix}`);
        }
        if (bucketsMatchingPrefix.length > 1) {
            throw new Error(`Found more than one bucket matching the prefix ${bucketNamePrefix}: ${JSON.stringify(bucketsMatchingPrefix)}`);
        }
        return bucketsMatchingPrefix[0].Name;
    });
}
exports.findBucketNameByPrefix = findBucketNameByPrefix;
