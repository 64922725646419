import React, { useEffect } from 'react';
import './App.css';
import {Navigate, Route, Routes,} from "react-router-dom";
import ErrorBoundary from 'src/components/ErrorBoundary';
import GlobalProviders from "src/components/GlobalProviders";
import {WorkspaceRoute} from "src/routes/WorkspaceRoute";
import {NotFound} from "src/routes/notFound";
import {LoginOnly} from "src/routes/loginOnly";
import {RequireAuth} from "@sunrun/design-tools-react-router-require-auth";
import {ImportLightmileProjectRoute} from "src/routes/imports/lightmile/projects/$projectId/designs/$designIndex";
import {Imports} from "src/routes/imports";
import {ImportLightmileProjectForm} from "src/routes/imports/lightmile/projects";
import {LightmileImports} from "src/routes/imports/lightmile";
import {WorkspaceLeafletMap} from "src/components/WorkspaceLeafletMap";
import { IFrameEventType } from './types/IFrame';
import { postIFrameMessage } from './hooks/useIFrameHost';
import { ImportLightmileProjectByProspectRoute } from './routes/imports/lightmile/projects/prospects/$prospectId/offers/$offerId';
import { ImportLightmileProspectByProspectForm } from './routes/imports/lightmile/projects/prospects';

export default function App() {
  useEffect(() => {
    postIFrameMessage(IFrameEventType.APP_MOUNTED, {});
  }, []);
  return (
    <ErrorBoundary>
      <GlobalProviders>
        <Routes>
          <Route path="/" element={<RequireAuth/>}>
            <Route index element={<Navigate to="workspace"/>}/>
            <Route path="workspace" element={<WorkspaceRoute><WorkspaceLeafletMap/></WorkspaceRoute>}/>
            <Route path="imports">
              <Route index element={<Imports/>}/>
              <Route path="lightmile">
                <Route index element={<LightmileImports/>}/>
                <Route path="projects">
                  <Route index element={<ImportLightmileProjectForm/>}/>
                  <Route path=":lightmileProjectId">
                    <Route path="designs">
                      <Route path=":lightmileDesignIndex" element={<ImportLightmileProjectRoute/>}/>
                    </Route>
                  </Route>
                  <Route path="prospects">
                    <Route index element={<ImportLightmileProspectByProspectForm/>}/>
                    <Route path=":prospectId">
                      <Route path="offers">
                        <Route path=":offerId" element={<ImportLightmileProjectByProspectRoute/>}/>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="login" element={<LoginOnly/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Route>
        </Routes>
      </GlobalProviders>
    </ErrorBoundary>
  )
};
