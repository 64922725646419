import {useGeoRaster} from "src/hooks/useGeoRaster";
import {useSiteModel} from "src/hooks/useSiteModel";
import {useProductionSimulation} from "src/hooks/useProductionSimulation";
import {useDesign} from "src/hooks/useDesign";
import {useCustomer} from "./useCustomer";
import {useDesignConstraints} from "./useDesignConstraints";
import {useSolarResource} from "./useSolarResource";
import {useSimulateDesign} from "./useSimulateDesign";
import {useExportDesign} from "./useExportDesign";
import {useLegacyFinalizeDesign} from "./useLegacyFinalizeDesign"
import {useReadOnlyDesignSet} from "./useReadOnlyDesignSet";


type UseDomainModelProps = {
  geoRasterId?: string;
  siteModelId?: string;
  siteModelVersion?: number;
  designId?: string;
  designVersion?: number;
  productionSimulationId?: string;
  productionSimulationVersion?: number;
  solarResourceId?: string;
  solarResourceVersion?: number;
  signedRootDesignId?: string;
  interconnectionAppliedDesignId?: string;
  copyFromDesignId?: string;
};

export const useDomainModel = ({
                                 geoRasterId,
                                 siteModelId,
                                 siteModelVersion,
                                 designId,
                                 designVersion,
                                 productionSimulationId,
                                 productionSimulationVersion,
                                 solarResourceId,
                                 solarResourceVersion,
                                 signedRootDesignId,
                                 interconnectionAppliedDesignId,
                                 copyFromDesignId,
                               }: UseDomainModelProps) => {

  useSiteModel({siteModelId, siteModelVersion})
  const {autoSaveStatus, saveDesignMutation} = useDesign({
    designId,
    designVersion,
  });
  useProductionSimulation({productionSimulationId, productionSimulationVersion})
  useCustomer()
  useDesignConstraints()
  useGeoRaster({geoRasterId})   // Takes the longest to load, so declare it last to allow other loading messages to show.
  useSolarResource({solarResourceId, solarResourceVersion});
  const {simulateDesign} = useSimulateDesign({saveDesignMutation, designId, designVersion})
  const {exportDesign} = useExportDesign()
  const {legacyFinalizeDesign} = useLegacyFinalizeDesign({saveDesignMutation})
  useReadOnlyDesignSet({signedRootDesignId, interconnectionAppliedDesignId, copyFromDesignId})

  return {
    simulateDesign,
    exportDesign,
    legacyFinalizeDesign,
    autoSaveStatus
  }
}
