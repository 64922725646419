import { Box, Button, Grid, SvgIcon, Typography, styled } from "@mui/material"
import { formatNumber } from "@sunrun/design-tools-themes";

import type { ReactNode } from "react"
import type { PromotionDisplayComponentProps } from "src/features/promotions/promotionsSlice";

const PromotionsContainer = styled(Box)({
  background: "#FFF",
  borderRadius: "8px",
  lineHeight: "1",
  padding: "21px 24px",
  width: "75%",
});

const PromotionIcon = styled(Box)({
  background: "linear-gradient(270deg, #BBD6FF 0.93%, #A1C7FF 52.27%, #83B5FF 100%)",
  borderRadius: "4px",
  padding: "8px 0",
  textAlign: "center",
  width: "100%",
});

const PromotionButton = styled(Button)({
  display: "inline",
  padding: "0",
  textAlign: "left",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "initial",
    textDecoration: "underline",
  },
  "&.Mui-focusVisible": {
    borderRadius: 0,
    outline: "-webkit-focus-ring-color auto 1px",
  }
});

type EvChargerPromotionMessageProps = {
  children: ReactNode
}

const EvChargerPromotionMessage = ( {children}: EvChargerPromotionMessageProps ) => {
  return (
    <Typography variant="body2" color="#1F2647">
      {children}
    </Typography>
  );
}

export interface EvChargerPromotionProps extends PromotionDisplayComponentProps {
  annualProduction: number,
  annualUsage: number,
}

export const EvChargerPromotion = (props: EvChargerPromotionProps) => {
  const annualProductionNeededToQualify = props.isEligible ? 0 : formatNumber({ value: Math.ceil(props.annualUsage + 2000 - props.annualProduction) })

  let promotionBody;
  if (!props.isEligible) {
    promotionBody = <EvChargerPromotionMessage>Increase production by {annualProductionNeededToQualify} kWh to receive 50% off one EV Charger</EvChargerPromotionMessage>
  } else {
    if (props.isApplied) {
      promotionBody = <EvChargerPromotionMessage>This system will receive 50% off one EV Charger</EvChargerPromotionMessage>
    } else {
      promotionBody = (
        <>
          <EvChargerPromotionMessage>This system qualifies for 50% off one EV Charger</EvChargerPromotionMessage>
          <PromotionButton variant="text" disableRipple={true} onClick={props.onCallToActionClick}>Add now</PromotionButton>
        </>
      );
    }
  }

  return (
    <PromotionsContainer>
      <Grid container>
        <Grid item sx={{width: "52px", paddingRight: "12px"}}>
          <PromotionIcon>
            <SvgIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.3425 0.832771C15.6408 0.98586 15.8012 1.31834 15.7354 1.64711L14.3605 8.52172L19.7634 10.5478C19.9978 10.6357 20.1729 10.8349 20.2302 11.0786C20.2874 11.3224 20.2192 11.5787 20.0483 11.7618L9.5483 23.0118C9.31953 23.2569 8.95586 23.3204 8.65757 23.1673C8.35927 23.0142 8.19882 22.6817 8.26458 22.3529L9.6395 15.4783L4.23667 13.4523C4.0022 13.3643 3.82708 13.1652 3.76986 12.9214C3.71263 12.6776 3.78086 12.4214 3.95172 12.2383L14.4517 0.988289C14.6805 0.743176 15.0442 0.679681 15.3425 0.832771ZM5.81373 12.4417L10.7634 14.2978C11.1076 14.4269 11.3076 14.7866 11.2354 15.1471L10.2533 20.0581L18.1863 11.5584L13.2367 9.70227C12.8924 9.57316 12.6925 9.2135 12.7646 8.85294L13.7468 3.94198L5.81373 12.4417Z" fill="#1F2647"/>
              </svg>
            </SvgIcon>
          </PromotionIcon>
        </Grid>
        <Grid item xs>
          {promotionBody}
        </Grid>
      </Grid>
    </PromotionsContainer>
  )
}
