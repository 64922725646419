import {createSelector} from "reselect";
import {selectDesign} from "src/features/design/designSlice";
import {selectProductionSimulation} from "../productionSimulation/productionSimulationSlice";

export const deriveIsDesignFinalized = createSelector(
  [selectDesign, selectProductionSimulation],
  (design, productionSimulation) => {
    if (design && productionSimulation) {
      return design.isFinalized(productionSimulation);
    }
    return false;
  }
);
