import { createSelector } from "reselect";
import { selectDesign } from "src/features/design/designSlice";
import { selectProductionSimulation } from "src/features/productionSimulation/productionSimulationSlice";
import { determineDesignWarnings } from "@sunrun/design-tools-domain-model";
import { selectCustomer } from "../customer/customerSlice";
import {
  selectInterconnectionAppliedDesign,
  selectSignedRootDesign,
} from "../readOnlyDesignSet/readOnlyDesignSetSlice";
import { selectDesignConstraints } from "../designConstraints/designConstraintsSlice";
import { deriveLowResProductionSimulation } from "../lowResProductionSimulation/deriveLowResProductionSimulation";

export const deriveDesignWarnings = createSelector(
  [
    selectDesign,
    deriveLowResProductionSimulation,
    selectProductionSimulation,
    selectCustomer,
    selectSignedRootDesign,
    selectInterconnectionAppliedDesign,
    selectDesignConstraints,
  ],
  (
    design,
    lowResProductionSimulation,
    productionSimulation,
    customer,
    signedRootDesignSet,
    interconnectionAppliedDesignSet,
    designConstraints
  ) => {
    return determineDesignWarnings({
      activeDesign: design,
      activeLowResProductionSimulation: lowResProductionSimulation,
      activeProductionSimulation: productionSimulation,
      signedRootDesignSet,
      interconnectionAppliedDesignSet,
      customer,
      designConstraints,
    });
  }
);
