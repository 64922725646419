import {createSelector} from "reselect";
import {deriveIsDesignFinalized} from "./deriveIsDesignFinalized";
import {deriveUnMetDesignStatusRequirements} from "./deriveDesignRequirements";

export const deriveDesignReadyForExport = createSelector(
  [deriveIsDesignFinalized, deriveUnMetDesignStatusRequirements],
  (isDesignFinalized, unMetDesignRequirements) => {
    return isDesignFinalized && unMetDesignRequirements.length === 0
  }
)
