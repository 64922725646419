import {
  Box,
  Divider,
  Grid,
} from "@mui/material";
import { DetailsSection } from "./DetailsSection";
import { ProductsDetails } from "./ProductsDetails";
import { DesignDetails } from "./DesignDetails";

const MoreDetails = () => {
  return (
    <>
      <Divider />
      <Box padding={2} overflow="auto">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DetailsSection title="Products">
              <ProductsDetails />
            </DetailsSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailsSection title="Design">
              <DesignDetails />
            </DetailsSection>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MoreDetails;