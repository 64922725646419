import {
  TableCell,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type FinancialProductsCellProps = {
  allowed: boolean;
  value: string | undefined;
  fullscreen: boolean | undefined;
};

export const FinancialProductsCell = ({
  allowed,
  value,
  fullscreen
}: FinancialProductsCellProps) => {
  return (
    <TableCell sx={{borderLeft: "1px solid rgba(224, 224, 224, 1)", px: fullscreen ? 1 : "default"}}>
      <Stack alignItems="center">
        {value ? (
          <>
            {allowed ? (
              <CheckCircleIcon fontSize="small" color="success" />
            ) : (
              <ErrorOutlineIcon fontSize="small" color="primary" />
            )}
            <Typography variant="body2">
              {value}
            </Typography>
          </>
        ) : (
          <Typography variant="body2">
            -
          </Typography>
        )}
      </Stack>
    </TableCell>
  );
};
