import { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";
import { EmptyAction, PayloadAction } from "../../types/state-management/action";
import { produce } from "immer";

export type InfoModalConfig = {
  title?: string;
  message: string;
}

export type CancelContinueModalConfig = {
  title?: string;
  message: string;
  onContinue?: ()=> void;
  cancelText?: string;
  continueText?: string;
}

export type ErrorModalConfig = {
  title?: string;
  message?: string;
  error?: Error;
}

export type Modal = {
  infoModal: InfoModalConfig | undefined;
  errorModal: ErrorModalConfig | undefined;
  financialProductsOpen: boolean;
  isOfferUpdateConfirmationModalOpen: boolean;
  isProductEquipmentMappingErrorModalOpen: boolean;
  isWhatsNewOpen: boolean;
  isHotkeyDescriptionsOpen: boolean;
  isModuleIssuesOpen: boolean;
  cancelContinueModal : CancelContinueModalConfig |undefined
};

export const modalInitialState = {
  infoModal: undefined,
  errorModal: undefined,
  financialProductsOpen: false,
  isOfferUpdateConfirmationModalOpen: false,
  isProductEquipmentMappingErrorModalOpen: false,
  isWhatsNewOpen: false,
  isHotkeyDescriptionsOpen: false,
  isModuleIssuesOpen: false,
  cancelContinueModal : undefined
};

export type ModalAction =
  | PayloadAction<InfoModalConfig, "setInfoModal">
  | EmptyAction<"dismissInfoModal">
  | PayloadAction<ErrorModalConfig, "setErrorModal">
  | EmptyAction<"dismissErrorModal">
  | PayloadAction<boolean, "setIsFinancialProductsOpen">
  | EmptyAction<"dismissFinancialProducts">
  | PayloadAction<boolean, "setIsModuleIssuesOpen">
  | EmptyAction<"dismissModuleIssues">
  | EmptyAction<"displayOfferUpdateConfirmationModal">
  | EmptyAction<"dismissOfferUpdateConfirmationModal">
  | EmptyAction<"displayProductEquipmentMappingErrorModal">
  | EmptyAction<"dismissProductEquipmentMappingErrorModal">
  | EmptyAction<'openWhatsNew'>
  | EmptyAction<'closeWhatsNew'>
  | PayloadAction<boolean, 'setIsWhatsNewOpen'>
  | EmptyAction<'toggleIsHotkeyDescriptionsOpen'>
  | EmptyAction<'closeHotKeyDescriptions'>
  | EmptyAction<'openHotKeyDescriptions'>
  | PayloadAction<boolean, 'setIsHotkeyDescriptionsOpen'>
  | PayloadAction<CancelContinueModalConfig, "setCancelContinueModal">
  | EmptyAction<"dismissCancelContinueModal">;


export const modalReducer = (
  state: WorkspaceState,
  action: WorkspaceAction
): Modal => {
  switch (action.type) {
    case "setInfoModal": {
      return produce(state.modal, (draft) => {
        draft.infoModal = action.payload
      });
    }

    case "dismissInfoModal": {
      return produce(state.modal, (draft) => {
        draft.infoModal = undefined;
      });
    }

    case "setErrorModal": {
      return produce(state.modal, draft => {
        draft.errorModal = action.payload;
      });
    }

    case "dismissErrorModal": {
      return produce(state.modal, draft => {
        draft.errorModal = undefined;
      });
    }

    case "setIsFinancialProductsOpen": {
      return produce(state.modal, (draft) => {
        draft.financialProductsOpen = action.payload
      });
    }

    case "dismissFinancialProducts": {
      return produce(state.modal, (draft) => {
        draft.financialProductsOpen = false;
      });
    }

    case "setIsModuleIssuesOpen": {
      return produce(state.modal, (draft) => {
        draft.isModuleIssuesOpen = action.payload
      });
    }

    case "dismissModuleIssues": {
      return produce(state.modal, (draft) => {
        draft.isModuleIssuesOpen = false;
      });
    }

    case "displayOfferUpdateConfirmationModal": {
      return produce(state.modal, (draft) => {
        draft.isOfferUpdateConfirmationModalOpen = true
      })
    }

    case "dismissOfferUpdateConfirmationModal": {
      return produce(state.modal, (draft) => {
        draft.isOfferUpdateConfirmationModalOpen = false
      })
    }

    case "displayProductEquipmentMappingErrorModal": {
      return produce(state.modal, draft => {
        draft.isProductEquipmentMappingErrorModalOpen = true;
      });
    }

    case "dismissProductEquipmentMappingErrorModal": {
      return produce(state.modal, draft => {
        draft.isProductEquipmentMappingErrorModalOpen = false;
      });
    }

    case "openWhatsNew": {
      return produce(state.modal, draft => {
        draft.isWhatsNewOpen = true
      })
    }

    case "closeWhatsNew": {
      return produce(state.modal, draft => {
        draft.isWhatsNewOpen = false
      })
    }

    case "setIsWhatsNewOpen": {
      return produce(state.modal, draft => {
        draft.isWhatsNewOpen = action.payload
      })
    }

    case "closeHotKeyDescriptions": {
      return produce(state.modal, draft => {
        draft.isHotkeyDescriptionsOpen = false
      })
    }

    case "openHotKeyDescriptions": {
      return produce(state.modal, draft => {
        draft.isHotkeyDescriptionsOpen = true
      })
    }

    case "setIsHotkeyDescriptionsOpen": {
      return produce(state.modal, draft => {
        draft.isHotkeyDescriptionsOpen = action.payload
      })
    }

    case "toggleIsHotkeyDescriptionsOpen": {
      return produce(state.modal, draft => {
        draft.isHotkeyDescriptionsOpen = !state.modal.isHotkeyDescriptionsOpen
      })
    }
    
    case "setCancelContinueModal": {
      return produce(state.modal, (draft) => {
        draft.cancelContinueModal = action.payload
      });
    }

    case "dismissCancelContinueModal": {
      return produce(state.modal, (draft) => {
        draft.cancelContinueModal = undefined;
      });
    }
    default:
      return state.modal;
  }
};
