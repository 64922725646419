import {FeatureGroup, LayersControl, Pane} from "react-leaflet";
import * as React from "react";
import {LeafletLayerZIndexes} from "src/constants";
import { useWorkspace } from "src/hooks/useWorkspace";

type ModuleSpacingLayerGroupProps = {
  children: React.ReactNode
}

export const ModuleSpacingFeatureGroup = (props: ModuleSpacingLayerGroupProps) => {
  const { state } = useWorkspace()
  const { settings } = state
  if (!settings.isModuleSpacingLayerVisible) return null
  return (
    <Pane name="module spacing" style={{zIndex: LeafletLayerZIndexes.moduleSpacing}}>
      <FeatureGroup>
        {props.children}
      </FeatureGroup>
    </Pane>
  )
}
