import {WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";
import {EmptyAction, PayloadAction} from "../../types/state-management/action";
import {produce} from "immer";

export type Notification = {
  snackbarMessage: string | undefined
}

export const notificationInitialState = {
  snackbarMessage: undefined
}

export type NotificationAction =
  | PayloadAction<string, 'showSnackbarMessage'>
  | EmptyAction<'dismissSnackbarMessage'>

export const notificationReducer = (state: WorkspaceState, action: WorkspaceAction): Notification => {
  switch (action.type) {
    case "showSnackbarMessage": {
      return produce(state.notification, draft => {
        draft.snackbarMessage = action.payload
      })
    }

    case "dismissSnackbarMessage": {
      return produce(state.notification, draft => {
        draft.snackbarMessage = undefined
      })
    }

    default:
      return state.notification
  }
}
