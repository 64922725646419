import {ModuleSpacing} from "@sunrun/design-tools-domain-model";
import {GeoJsonLayer} from "src/components/GeoJsonLayer";
import {PathOptions} from "leaflet";
import React from "react";

export type ModuleSpacingProps = {
  moduleSpacing: ModuleSpacing
}

export const ModuleSpacingLayer = (props: ModuleSpacingProps) => {
  const [pathOptions, ] = React.useState<PathOptions>({
    fillOpacity: .1,
    fillColor: "black",
    color: "black",
    weight: .1,
  })
  return (
    <GeoJsonLayer
      data={props.moduleSpacing}
      pathOptions={pathOptions}
      key={props.moduleSpacing.id}
    />
  )
}
