import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {EmptyAction, PayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {Module} from "@sunrun/design-tools-domain-model";

export type ModuleSelection = {
  selectedModuleIds: Set<string>
}

export type ModuleSelectionAction =
  | EmptyAction<'clearSelectedModules'>
  | PayloadAction<WorkspaceEvent, 'selectOneModule'>
  | PayloadAction<WorkspaceEvent, 'selectAllModulesOnRoofFace'>
  | EmptyAction<'selectModulesByMarquee'>

export const moduleSelectionReducer = (state: WorkspaceState, action: WorkspaceAction): ModuleSelection => {
  if (!(state.design && state.siteModel)) {
    return state.moduleSelection
  }
  switch (action.type) {
    case "clearSelectedModules": {
      return {
        selectedModuleIds: new Set()
      }
    }
    case "selectOneModule": {
      const layer = action.payload.propagatedFrom.layer as Module
      const moduleId = layer.id
      if (state.moduleSelection.selectedModuleIds.has(moduleId)) {
        return state.moduleSelection // bail out
      } else {
        const newSet = new Set<string>([moduleId])
        const newlySelectedModule = state.design.getModuleById(moduleId)
        // only leave modules selected if they are on the same roof face as the newly selected module
        if (newlySelectedModule){
          state.moduleSelection.selectedModuleIds.forEach((it: string) => {
              const module = state.design?.getModuleById(it)
              if (module?.properties.roofFaceId === newlySelectedModule.properties.roofFaceId) {
                newSet.add(module.id as string)
              }
            }
          )
        }
        return {
          selectedModuleIds: newSet
        }
      }
    }
    case "selectAllModulesOnRoofFace": {
      if (state.design && state.design.modules) {
        const module = action.payload.propagatedFrom.layer as Module
        const roofFaceId = module.properties.roofFaceId
        return {
          selectedModuleIds: new Set(
            state.design.modules.filter(it => it.properties.roofFaceId === roofFaceId).map(it => it.id)
          )
        }
      }
      return state.moduleSelection // bail out
    }
    case "selectModulesByMarquee": {
      const roofFaceId = state.marquee.roofFaceId
      const selectionPolygon = state.marquee.selectionPolygon
      const roofFaceModules = state.design!.modules.filter(module => module.properties.roofFaceId === roofFaceId)
      const modulesToSelect = roofFaceModules.filter(module => selectionPolygon!.intrudesOn(module))
      return {
        selectedModuleIds: new Set(
          modulesToSelect.map(module => module.id)
        )
      }
    }
    default: {
      return state.moduleSelection
    }
  }
}
