import {useMap, useMapEvents} from "react-leaflet";
import * as React from "react";
import {useWorkspace} from "src/hooks/useWorkspace";
import {createEventHandlers} from "./LeafletEventHandler";
import {InteractiveLeafletLayer} from "../types/state-management/action";

/**
 * Event handlers cannot be passed directly to the Map Container
 * https://react-leaflet.js.org/docs/api-map/#usemapevents
 */

export const LeafletMapContainerEventHandler = () => {
  const {state, dispatch} = useWorkspace()
  const map = useMap()
  const [eventHandlers] = React.useState(createEventHandlers(InteractiveLeafletLayer.map, dispatch, map))
  useMapEvents(eventHandlers)
  return null
}
