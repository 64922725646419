import {List, ListItem, ListItemText} from "@mui/material";
import {ModalLayout} from "./components/ModalLayout";
import {useOfferLineUpdates} from "../../hooks/useOfferLineUpdates";
import {useWorkspace} from "../../hooks/useWorkspace";
import {deriveDesignReadyForExport} from "../../features/designGuidance/deriveDesignReadyForExport";
import {deriveOfferProductChanges} from "src/features/designGuidance/deriveOfferProductChanges";

type OfferUpdateConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
}

export const OfferUpdateConfirmationModal = ({ open, onClose }: OfferUpdateConfirmationModalProps) => {
  const {state} = useWorkspace()
  const productChanges = deriveOfferProductChanges(state)
  if (productChanges.solar.newVariantId || productChanges.backup.newVariantId) {
    const buildProductChangesMessages = (): string[] => {
      const changeList = Object.values(productChanges).flatMap(c => c.productOptionChanges)
      return changeList.map(c => `${c.optionName} will update from ${c.previousValue} to ${c.newValue}`)
    }
    const readyToGoToPricing = deriveDesignReadyForExport(state)
    const headerMsg = readyToGoToPricing ? undefined : "Unable to map all product selections to equipment without making the following changes:"
    const productChangesMessages = buildProductChangesMessages()
    return (
      <ModalLayout
        open={open}
        title="Your product selection will be updated"
        onClose={onClose}
        actionButtons
      >
        <>
          {
            headerMsg &&
            <span>{headerMsg}</span>
          }
          <List>
            {productChangesMessages.map((msg) => (
              <ListItem key={msg}>
                <ListItemText primary={msg}/>
              </ListItem>
            ))}
          </List>
        </>
      </ModalLayout>
    )
  }
  return null
}
