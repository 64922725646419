import {LoadingScreenProvider} from "@sunrun/design-tools-loading-screen";
import {GuidedTourProvider} from "@sunrun/design-tools-guided-tour";
import {AddFeatureStateProvider} from "@sunrun/design-tools-add-feature";
import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import {AuthProvider} from "@sunrun/design-tools-react-router-require-auth";
import {RootThemeProvider} from "@sunrun/design-tools-themes";
import {CancelContinueDialogProvider} from "@sunrun/design-tools-cancel-continue-dialog";
import {WorkspaceProvider, WorkspaceState} from "src/hooks/useWorkspace";
import React from "react";

export type GlobalProvidersProps = {
  children?: React.ReactNode,
  workspaceInitialState?: WorkspaceState
}
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      /**
       * Currently query errors are nonrecoverable, so we throw them up to the top level Error Boundary.
       * https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
       */
      let errorMessage = "Something went wrong querying for data"
      if (error instanceof Error) errorMessage += `: ${error.message}`
      throw new Error(`Something went wrong: ${errorMessage}`)
    }
  }),
})

const GlobalProviders = ({children, workspaceInitialState}: GlobalProvidersProps) => {
  return (
    <RootThemeProvider>
      <GuidedTourProvider>
        <AddFeatureStateProvider>
          <LoadingScreenProvider>
            <QueryClientProvider client={queryClient}>
              <LoadingScreenProvider>
                <AuthProvider>
                  <CancelContinueDialogProvider>
                    <WorkspaceProvider initialState={workspaceInitialState}>
                      {children}
                    </WorkspaceProvider>
                  </CancelContinueDialogProvider>
                </AuthProvider>
              </LoadingScreenProvider>
            </QueryClientProvider>
          </LoadingScreenProvider>
        </AddFeatureStateProvider>
      </GuidedTourProvider>
    </RootThemeProvider>
  );
}

export default GlobalProviders
