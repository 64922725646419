import {createSelector} from "reselect";
import {selectDesign} from "src/features/design/designSlice"
import {selectSiteModel} from "src/features/siteModel/siteModelSlice"
import {selectSolarResource} from "src/features/solarResource/solarResourceSlice";
import {selectIsLeafletDragging} from "src/features/moduleDrag/moduleDragSlice";
import {DesignGuidance} from "@sunrun/design-tools-domain-model";
import {deriveLowResProductionSimulation} from "../lowResProductionSimulation/deriveLowResProductionSimulation";
import {StaticReferences} from "@sunrun/design-tools-domain-model";
import {selectIsModuleNudging} from "../moduleNudge/moduleNudgeSlice";

export const deriveShortStringModules = createSelector(
  [selectDesign, selectSiteModel, selectSolarResource, deriveLowResProductionSimulation, selectIsLeafletDragging, selectIsModuleNudging],
  (design, siteModel, solarResource, lowResProductionSimulation, isLeafletDragging, isModuleNudging): Set<string> => {
    if (isLeafletDragging || isModuleNudging) {
      // Only derive when drag/nudge completes
      return deriveShortStringModules.lastResult()
    }
    if (solarResource && design && siteModel && lowResProductionSimulation) {
      return DesignGuidance.findShortStringModules(design, siteModel, lowResProductionSimulation, solarResource.siteTemperature)
    }
    return StaticReferences.EMPTY_SET
  }
)
