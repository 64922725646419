import {useMutation} from "react-query";
import {CommandType, DesignExportedToLightmile} from "@sunrun/design-tools-domain-model";
import {LoadingProcessGroups, LoadingProcessNames} from "../types/LoadingScreenProcess";
import {processManagerClient} from "@sunrun/design-tools-graphql-clients";
import * as FullStory from "@fullstory/browser";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {useWorkspace} from "./useWorkspace";
import {useSearchParams} from "react-router-dom";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

/**
 * EXPORT DESIGN: : This is used when iHD is hosted in Offer Experience since we need to separate
 * the simulation and export that are combined into a single operation in useLegacyFinalizeDesign
 *
 * Order of operations:
 *
 * exportDesignToLightmile
 * onError -> display error / reset
 * onSuccess -> Complete process
 */

export const useExportDesign = () => {
  const {state, dispatch} = useWorkspace();
  const {design} = state
  const [searchParams] = useSearchParams();
  const lightmileProjectId = searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;
  const {helpers: loadingScreenHelpers} = useLoadingScreen();

  const exportDesign = async () => {
    return exportMutation.mutateAsync()
  }

  const exportMutation = useMutation((): Promise<DesignExportedToLightmile> => {
    loadingScreenHelpers.addProcess({
      group: LoadingProcessGroups.PREPARE_TO_GO_TO_PRICING,
      name: LoadingProcessNames.EXPORT,
    });

    return processManagerClient.exportDesignToLightmile({
      designId: design!.id,
      lightmileProjectId: lightmileProjectId!,
      type: CommandType.ExportDesignToLightmile
    });
  }, {
    onSettled: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.EXPORT);
    },
    onError: (error: Error) => {
      dispatch({ type: "setErrorModal", payload: {error, message: "Failed to export design to Lightmile"}})
    },
    onSuccess: () => {
      FullStory.event("Exported Design To Lightmile", {})
    }
  });

  return {
    exportDesign
  }
}
