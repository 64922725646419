import React from "react";
import {useErrorHandler} from "react-error-boundary";
import {ImageOverlay, LayersControl} from "react-leaflet";
import {LatLngBoundsLiteral, LatLngTuple, LeafletEvent} from "leaflet";
import {GeoRaster} from "@sunrun/design-tools-domain-model"
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {RuntimeContext, TestType} from "@sunrun/design-tools-runtime-context";
import {LoadingProcessNames} from "src/types/LoadingScreenProcess";
import { useWorkspace } from "src/hooks/useWorkspace";

type MapImageLayerProps = {
  geoRaster: GeoRaster
}
export const MapImageLayer = ({geoRaster}: MapImageLayerProps) => {
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const { state } = useWorkspace()
  const { settings } = state
  
  const leafletEventHandlers = {
    load: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.AERIAL_IMAGERY);
    },
    error: (_: LeafletEvent) => {
      // Custom error since Leaflet event doesn't provide error details
      handleError(new Error(`Error Loading Aerial Image\nFailed uri: ${geoRaster.uri}\nPlease retry reloading iHD.`));
    }
  }

  // Leaflet Images do not work in node/jest. This simulates load
  React.useEffect(() => {
    if (RuntimeContext.testType !== TestType.None && geoRaster.uri) {
      new Promise(resolve => setTimeout(resolve, 100)).then( // Happens too fast for react testing library, add a brief pause
        () => loadingScreenHelpers.completeProcess(LoadingProcessNames.AERIAL_IMAGERY)
      )
    }
  })

  if (geoRaster.uri && settings.isMapImageLayerVisible)      // https://reactjs.org/docs/conditional-rendering.html#preventing-component-from-rendering
    return (
      <ImageOverlay
        url={geoRaster.uri}
        bounds={toLeafletLatLngBound(geoRaster)}
        eventHandlers={leafletEventHandlers}
      />
    )
  else return null
}

const toLeafletLatLngBound = (geoRaster: GeoRaster): LatLngBoundsLiteral => {
  if (geoRaster.metadata == null) {
    throw new Error('Cannot create MapImageLayer with missing GeoRaster metadata.')
  }
  return [
    [geoRaster.metadata.bboxUtm.southWest.northing,
      geoRaster.metadata.bboxUtm.southWest.easting
    ] as LatLngTuple,
    [geoRaster.metadata.bboxUtm.northEast.northing,
      geoRaster.metadata.bboxUtm.northEast.easting
    ] as LatLngTuple
  ]
}
