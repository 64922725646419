import {Module} from "@sunrun/design-tools-domain-model";
import {Feature, FeatureCollection, LineString, Position} from "geojson";
import {GeoJsonLayer} from "src/components/GeoJsonLayer";

export type ModuleProblemDecoratorProps = {
  module: Module
  isModuleSelected: boolean
  pane?: string | undefined
}

export const ModuleProblemDecorator = (props: ModuleProblemDecoratorProps) => {

  const moduleCoords: Position[] = props.module.geometry.coordinates[0]
  const xStroke1Feature = buildLineStringFeature([moduleCoords[0], moduleCoords[2]])
  const xStroke2Feature = buildLineStringFeature([moduleCoords[1], moduleCoords[3]])
  const borderFeature = buildLineStringFeature(moduleCoords)


  const xPathOptions = { color: '#c43232' };
  const borderColor = props.isModuleSelected ? '#39a2ff' : '#c43232'
  const borderPathOptions = { color: borderColor }
  return (
    <>
      <GeoJsonLayer
        data={xStroke1Feature}
        pathOptions={xPathOptions}
        interactive={false}
        pane={props.pane}
      />
      <GeoJsonLayer
        data={xStroke2Feature}
        pathOptions={xPathOptions}
        interactive={false}
        pane={props.pane}
      />
      <GeoJsonLayer
        data={borderFeature}
        pathOptions={borderPathOptions}
        interactive={false}
        pane={props.pane}
      />
    </>
  )
}

const buildLineStringFeature = (positions: Position[]): Feature<LineString, {}> => {
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: positions
    },
    properties:{}
  }
}
