import {SnackbarContainer} from "../components/SnackbarContainer";
import LoadingScreen from "@sunrun/design-tools-loading-screen";
import {ReactNode} from "react";
import DesignStatus from "@sunrun/design-tools-design-status";
import FinancialProduct from "@sunrun/design-tools-financial-product";
import TopToolbar from "@sunrun/design-tools-top-toolbar";
import {Box, useTheme} from "@mui/material";
import {FluxToggleButton} from "src/components/FluxToggleButton";
import {useWorkspace} from "src/hooks/useWorkspace";
import {FloatingActionButtonContainer} from "src/components/FloatingActionButtonContainer";
import DesignMenu from "@sunrun/design-tools-design-menu/";
import HotkeyDefinitions from "@sunrun/design-tools-hotkey-definitions";
import {DesignToolsTheme} from "@sunrun/design-tools-themes";
import WhatsNew from "@sunrun/design-tools-whats-new";
import {deriveLowResProductionSimulation} from "../features/lowResProductionSimulation/deriveLowResProductionSimulation";
import {deriveUnMetDesignStatusRequirements} from "../features/designGuidance/deriveDesignRequirements";
import {deriveDesignWarnings} from "src/features/designGuidance/deriveDesignWarnings";
import {deriveIsDesignFinalized} from "src/features/designGuidance/deriveIsDesignFinalized";
import { AutoSaveStatus } from "src/hooks/useDesign";

type LegacyWorkspaceProps = {
  children: ReactNode;
  disableFinalizeDesignButton: boolean;
  autoSaveStatus: AutoSaveStatus;
  isNotLightmileHost: boolean;
  legacyFinalizeDesign: () => void;
  disableRotateButton: boolean;
  disableFillRoofFaceAroundModuleButton: boolean;
  rotateSelectedModulesMsg: string;
};

const LegacyWorkspace = ({
  children,
  disableFinalizeDesignButton,
  autoSaveStatus,
  isNotLightmileHost,
  legacyFinalizeDesign,
  disableRotateButton,
  disableFillRoofFaceAroundModuleButton,
  rotateSelectedModulesMsg,
}: LegacyWorkspaceProps) => {
  const { state, dispatch } = useWorkspace();
  const {
    design,
    siteModel,
    geoRaster,
    solarResource,
    productionSimulation,
    customer,
    designConstraints,
  } = state;
  const theme = useTheme<DesignToolsTheme>();

  return (
    <div className="workspace">
      <LoadingScreen />
      {design && siteModel && geoRaster && (
        <>
          {children}
          <FluxToggleButton />
          {solarResource &&
            productionSimulation &&
            customer &&
            designConstraints && (
              <Box
                sx={{
                  position: "relative",
                  zIndex: theme.zIndex.appBar,
                }}
              >
                <TopToolbar
                  siteModel={siteModel}
                  design={design}
                  solarResource={solarResource}
                  customer={customer}
                  productionSimulation={productionSimulation}
                  lowResProductionSimulation={deriveLowResProductionSimulation(
                    state
                  )}
                  isDesignFinalized={deriveIsDesignFinalized(state)}
                  designConstraints={designConstraints}
                  autoSaveStatus={autoSaveStatus}
                  showFinalizeDesignButton={isNotLightmileHost}
                  disableFinalizeDesignButton={disableFinalizeDesignButton}
                  finalizeDesign={legacyFinalizeDesign}
                />
              </Box>
            )}
          <Box
            sx={{
              position: "relative",
              top: theme.spacing(2),
              left: theme.spacing(2),
              zIndex: theme.zIndex.floatingActionButton,
              display: "inline-flex",
            }}
          >
            <DesignMenu
              minimizeInterface={state.settings.isInterfaceMinimized}
              magneticSnap={state.settings.isMagneticSnapEnabled}
              magneticSlide={state.settings.isMagneticSlideEnabled}
              moduleOrientation={state.settings.moduleOrientation}
              designMenuOpen={state.settings.isDesignMenuOpen}
              setDesignMenuOpen={(isDesignMenuOpen) =>
                dispatch({
                  type: "setIsDesignMenuOpen",
                  payload: isDesignMenuOpen,
                })
              }
              toggleMinimizeInterface={() =>
                dispatch({ type: "toggleIsInterfaceMinimized" })
              }
              toggleModuleOrientation={() =>
                dispatch({ type: "toggleModuleOrientationSetting" })
              }
              toggleMagneticSnap={() =>
                dispatch({ type: "toggleIsMagneticSnapEnabled" })
              }
              toggleMagneticSlide={() =>
                dispatch({ type: "toggleIsMagneticSlideEnabled" })
              }
              openWhatsNew={() => dispatch({ type: "openWhatsNew" })}
              openHotkeyDescriptions={() =>
                dispatch({ type: "openHotKeyDescriptions" })
              }
              showSunHours={state.settings.isSunHoursVisible}
              toggleSunHours={() =>
                dispatch({ type: "toggleIsSunHoursVisible" })
              }
              fillRoof={() => {
                if (state.design && state.design.moduleCount > 0) {
                  dispatch({
                    type: "showSnackbarMessage",
                    payload:
                      "Can ONLY fill roof for empty design, please remove all modules first to continue.",
                  });
                } else {
                  const roofFaces = state.siteModel?.roofFaces!;
                  for (let roofFace of roofFaces) {
                    setTimeout(
                      () =>
                        dispatch({
                          type: "centerFillOneRoofFace",
                          payload: { roofFaceId: roofFace.id },
                        }),
                      10
                    );
                  }
                }
              }}
            />
            <HotkeyDefinitions
              showDefinitions={state.modal.isHotkeyDescriptionsOpen}
              setHotkeyDescriptions={(isHotkeyDescriptionsOpen: boolean) =>
                dispatch({
                  type: "setIsHotkeyDescriptionsOpen",
                  payload: isHotkeyDescriptionsOpen,
                })
              }
            />
            <WhatsNew
              showWhatsNew={state.modal.isWhatsNewOpen}
              setShowWhatsNew={(isWhatsNewOpen: boolean) =>
                dispatch({
                  type: "setIsWhatsNewOpen",
                  payload: isWhatsNewOpen,
                })
              }
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              float: "right",
              top: theme.spacing(2),
              right: theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              rowGap: theme.spacing(2),
              alignItems: "flex-end",
              zIndex: theme.zIndex.floatingActionButton,
            }}
          >
            <DesignStatus
              unmetRequirements={deriveUnMetDesignStatusRequirements(state)}
              designWarnings={deriveDesignWarnings(state)}
              minimizeInterface={state.settings.isInterfaceMinimized}
              setShowDesignStatusDialog={(isDesignStatusDialogueVisible) => {
                dispatch({
                  type: "setIsDesignStatusDialogueVisible",
                  payload: isDesignStatusDialogueVisible,
                });
                dispatch({ type: "clearSelectedModules" });
              }}
              showDesignStatusDialog={
                state.settings.isDesignStatusDialogueVisible
              }
            />
            <FinancialProduct
              design={design}
              productionSimulation={
                deriveIsDesignFinalized(state)
                  ? productionSimulation
                  : deriveLowResProductionSimulation(state)
              }
              customer={customer}
              designConstraints={designConstraints}
              minimizeInterface={state.settings.isInterfaceMinimized}
              onOpenDialog={() => {
                dispatch({ type: "clearSelectedModules" });
              }}
            />
          </Box>
          <FloatingActionButtonContainer
            rotateModules={() => dispatch({ type: "rotateModules" })}
            removeModules={() => {
              dispatch({ type: "removeModules" });
              dispatch({ type: "clearSelectedModules" }); // smell: that you must know to clear selection after dispatching "removeModules"
            }}
            fillRoofFaceAroundModule={() => {
              dispatch({ type: "fillRoofFaceAroundModule" });
              dispatch({ type: "clearSelectedModules" });
            }}
            isAnyModuleSelected={
              state.moduleSelection.selectedModuleIds.size > 0
            }
            isMinimized={state.settings.isInterfaceMinimized}
            isRotateDisabled={disableRotateButton}
            isFillRoofFaceAroundModuleDisabled={
              disableFillRoofFaceAroundModuleButton
            }
            rotateSelectedModulesMsg={rotateSelectedModulesMsg}
          />
          <SnackbarContainer
            bumpUpMessage={state.moduleSelection.selectedModuleIds.size > 0}
          />
        </>
      )}
    </div>
  );
};

export default LegacyWorkspace;