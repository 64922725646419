
import { Typography } from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";

type CancelContinueModalProps = {
    title?: string ;
    message?: string ;
    open: boolean;
    onClose: () => void;
    cancelText?: string ;
    continueText?: string;
    onContinue?: () => void;
}

export const CancelContinueModal = ({
    open,
    title,
    message,
    cancelText,
    continueText,
    onContinue,
    onClose
  }: CancelContinueModalProps) => {
  
    return(
        <ModalLayout 
          open={open}
          title={title}
          onClose={onClose}
          actionButtons
          cancelText={cancelText}
          continueText={continueText}
          onContinue={onContinue}
        >
          <Typography
            variant="body2"
          >
            {message}
          </Typography>
        </ModalLayout>
      )}
