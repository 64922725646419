import {createSelector} from "reselect";
import {selectCustomer} from "src/features/customer/customerSlice"
import {selectDesign, selectDesignIsUsingMicroInverter} from "src/features/design/designSlice"
import {selectDesignConstraints} from "src/features/designConstraints/designConstraintsSlice"
import {selectProductionSimulation} from "src/features/productionSimulation/productionSimulationSlice"
import {selectSettingsEnforceDesignRestrictionsForSales} from "src/features/settings/settingsSlice";
import {designRequirementsToValidate, DesignValidator} from "@sunrun/design-tools-domain-model";
import {deriveLowResProductionSimulation} from "../lowResProductionSimulation/deriveLowResProductionSimulation";
import {deriveCollidingModules} from "./deriveCollidingModules";
import {deriveIslandedModules} from "./deriveIslandedModules";
import {deriveShortStringModules} from "./deriveShortStringModules";

export const deriveDesignRequirementsForDesignStatus = createSelector(
  [selectSettingsEnforceDesignRestrictionsForSales],
  (enforceDesignRestrictionsForSales) => {
    return enforceDesignRestrictionsForSales ?
      designRequirementsToValidate.iHD.designStatus : designRequirementsToValidate.iHD.lightmileHost.designStatus
  }
)

export const deriveDesignRequirementsForFinalizeDesign = createSelector(
  [selectSettingsEnforceDesignRestrictionsForSales, selectDesignIsUsingMicroInverter],
  (enforceDesignRestrictionsForSales, designIsUsingMicroInverter) => {
    if (enforceDesignRestrictionsForSales) {
      return designIsUsingMicroInverter ?
        designRequirementsToValidate.iHD.finalizeDesign.microInverter :
        designRequirementsToValidate.iHD.finalizeDesign.stringInverter
    } else {
      return designIsUsingMicroInverter ?
        designRequirementsToValidate.iHD.lightmileHost.finalizeDesign.microInverter :
        designRequirementsToValidate.iHD.lightmileHost.finalizeDesign.stringInverter
    }
  }
)

export const deriveUnMetDesignStatusRequirements = createSelector(
  [
    deriveDesignRequirementsForDesignStatus,
    selectDesign,
    deriveLowResProductionSimulation,
    selectProductionSimulation,
    selectCustomer,
    selectDesignConstraints,
    deriveCollidingModules,
    deriveIslandedModules,
    deriveShortStringModules
  ],
  (
    designRequirementsToValidate,
    design,
    lowRes,
    productionSimulation,
    customer,
    designConstraints,
    collidingModules,
    islandedModules,
    shortStringModules
  ) => {
    return DesignValidator.determineUnMetDesignRequirements(
      designRequirementsToValidate,
      design,
      lowRes,
      productionSimulation,
      customer,
      designConstraints,
      collidingModules,
      islandedModules,
      shortStringModules
    );
  }
);

export const deriveUnMetFinalizeDesignRequirements = createSelector(
  [
    deriveDesignRequirementsForFinalizeDesign,
    selectDesign,
    deriveLowResProductionSimulation,
    selectProductionSimulation,
    selectCustomer,
    selectDesignConstraints,
    deriveCollidingModules,
    deriveIslandedModules,
    deriveShortStringModules,
  ],
  (
    designRequirementsToValidate,
    design,
    lowRes,
    productionSimulation,
    customer,
    designConstraints,
    collidingModules,
    islandedModules,
    shortStringModules
  ) => {
    return DesignValidator.determineUnMetDesignRequirements(
      designRequirementsToValidate,
      design,
      lowRes,
      productionSimulation,
      customer,
      designConstraints,
      collidingModules,
      islandedModules,
      shortStringModules,
    );
  }
);
