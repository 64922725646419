import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {Polygon, Position, rotatedBoundingBox} from "@sunrun/design-tools-geometry";
import {CollidableGeoJsonFeature, FeatureInit} from "@sunrun/design-tools-domain-model";
import {EmptyAction, PayloadAction, WorkspaceEvent} from "../../types/state-management/action";

export type MarqueeAction =
  | PayloadAction<InitMarqueePayload, 'initMarquee'>
  | PayloadAction<WorkspaceEvent, 'drawMarquee'>
  | EmptyAction<'resetMarquee'>

export type InitMarqueePayload = {
  roofFaceId: string
  pointerPosition: Position
  azimuthDegrees: number
}

export type Marquee = {
  isPointerDown: boolean
  isDragging: boolean
  startPosition?: Position
  dragPosition?: Position
  azimuthDegrees: number | undefined
  selectionPolygon: CollidableMarqueePolygon | undefined
  roofFaceId: string | undefined
}

export const marqueeInitialState = {
  isPointerDown: false,
  isDragging: false,
  startPosition: undefined,
  dragPosition: undefined,
  azimuthDegrees: undefined,
  selectionPolygon: undefined,
  roofFaceId: undefined
}

export const marqueeReducer = (state: WorkspaceState, action: WorkspaceAction): Marquee => {
  switch (action.type) {
    case "initMarquee": {
      return {
        isPointerDown: true,
        isDragging: false,
        startPosition: action.payload.pointerPosition,
        dragPosition: undefined,
        azimuthDegrees: action.payload.azimuthDegrees,
        selectionPolygon: undefined,
        roofFaceId: action.payload.roofFaceId
      }
    }
    case "drawMarquee": {
      const startPosition = state.marquee.startPosition!
      const endPosition = action.payload.position
      const azimuthDegrees = state.marquee.azimuthDegrees!
      const selectionPolygon = selectionAsPolygon(startPosition, endPosition, azimuthDegrees)
      return {
        isPointerDown: true,
        isDragging: true,
        startPosition: state.marquee.startPosition,
        dragPosition: action.payload.position,
        azimuthDegrees: state.marquee.azimuthDegrees,
        selectionPolygon: selectionPolygon,
        roofFaceId: state.marquee.roofFaceId
      }
    }
    case "resetMarquee": {
      return marqueeInitialState
    }
    default:
      return state.marquee
  }
}

const selectionAsPolygon = (startPosition: Position, endPosition: Position, azimuthDegrees: number): CollidableMarqueePolygon => {
  const bboxCoords = rotatedBoundingBox(startPosition, endPosition, azimuthDegrees)
  const feature: FeatureInit<Polygon, any> = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [bboxCoords]
    },
    properties: [],
  }
  return new CollidableMarqueePolygon(feature)
}

export class CollidableMarqueePolygon extends CollidableGeoJsonFeature<Polygon, any> {
  constructor(init: FeatureInit<Polygon, any>) {
    super(init);
  }
}

