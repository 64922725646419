import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {
  CommandType,
  Design,
  DesignSimulatedWithLightmileCore,
  getDesiredBatteryCountFromOffer,
  ProductionSimulation
} from "@sunrun/design-tools-domain-model";
import {processManagerClient, repository} from "@sunrun/design-tools-graphql-clients";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {useWorkspace} from "src/hooks/useWorkspace";
import {useSearchParams} from "react-router-dom";
import * as FullStory from "@fullstory/browser";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc)

type FinalizeDesignProps = Readonly<{
  saveDesignMutation: UseMutationResult<Design, unknown, Design, unknown>,
  designId?: string;
  designVersion?: number;
}>

export const useSimulateDesign = ({saveDesignMutation, designId, designVersion}: FinalizeDesignProps) => {
  const {state, dispatch} = useWorkspace();
  const {customer, designConstraints, design, offer, productionSimulation} = state;
  const [searchParams] = useSearchParams();
  const lightmileProjectId = searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const queryClient = useQueryClient();

  /***
   *
   * SIMULATE DESIGN: This is used when iHD is hosted in Offer Experience since we need to separate
   * the simulation and export that are combined into a single operation in useLegacyFinalizeDesign
   *
   * Order of Operations:
   *
   * simulateDesignWithLightmileCore
   *   onError -> display error / reset
   *   onSuccess -> trigger refetch of Design, ProductionSimulation
   * ReFetch Design, ProductionSimulation
   *   Re-validateDesignRequirementsForLightmileExport
   *     If Errors -> display DesignStatus Modal / reset
   *     If None -> enable Go To Pricing button
   *
   */
  const simulateDesign = async (): Promise<void> => {
    if (lightmileProjectId === undefined) {
      dispatch({ type: "setErrorModal", payload: {message: "Cannot Simulate Design without LightmileProjectId"}})
      return;
    }
    if (!customer || !designConstraints || !design || !productionSimulation) {
      dispatch({ type: "setErrorModal", payload: {message: "Cannot Simulate Design without missing aggregates"}})
      return;
    }
    if (design.version !== 0) {
      dispatch({ type: "setErrorModal", payload: {message: "Can only Simulate Design when using the current Design version (v0)"}})
      return;
    }
    if (!designId) {
      dispatch({ type: "setErrorModal", payload: {message: "Can only Simulate Design with a valid designId"}})
    }

    if (designId){
      const serverDesign = await repository.get(Design, designId, designVersion ?? 0)
      if (serverDesign._version !== design._version){ 
        dispatch({ type: "setCancelContinueModal", payload: 
          { title: `Design Conflict`, 
            message: `The most recent changes were created by ${serverDesign?.createdBy} at ${dayjs.utc(serverDesign?.updatedAt).local().format('h:mma on YYYY-MM-DD')}.
              \nOverwrite will discard all of ${serverDesign?.createdBy}'s changes and publish your changes.
              \nPlease click cancel and close this session if you do not want to overwrite exisitng changes.
              \nYou may also see this message if you are working on the same session in multiple tabs or windows.`,
            cancelText:'Cancel',
            continueText:'Overwrite',
            onContinue: async () => {
              dispatch({type: 'resolvePrepareDesignForOverride', payload: serverDesign})
              dispatch({type: 'simulateDesignInProgress', payload: true})
              FullStory.event("Simulating Design with Overwrite", {})
              await simulateMutation.mutateAsync();
              dispatch({ type: "dismissCancelContinueModal"})
            },
          }})
        }
      else{
        dispatch({type: 'simulateDesignInProgress', payload: true})
        FullStory.event("Simulating Design", {})
        await simulateMutation.mutateAsync();
      }
    }
  };

  const simulateMutation = useMutation(async (): Promise<DesignSimulatedWithLightmileCore> => {
    const signedRootId = state.mostRecentSignedDesign.projectHasSignedDesign ?
      state.mostRecentSignedDesign.signedRootId: null
    return await processManagerClient.simulateDesignWithLightmileCoreAsync({
      designId: design!.id,
      lightmileProjectId: lightmileProjectId!,
      productionSimulationId: productionSimulation!.id,
      signedRootId: signedRootId,
      constraintsFromOffer: {batteryCount: getDesiredBatteryCountFromOffer(offer!)},
      type: CommandType.SimulateDesignWithLightmileCore,
      effectiveDate: state.mostRecentSignedDesign.effectiveDate
    });
  }, {
    onMutate: async () => {
      loadingScreenHelpers.addProcess({
        group: LoadingProcessGroups.FINALIZE_DESIGN,
        name: LoadingProcessNames.DESIGN_SIMULATION,
      });
      // ensure design is up-to-date
      if (design!.hasUnsavedChanges) {
        await saveDesignMutation.mutateAsync(design!)
      }
    },
    onSettled: () => {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_SIMULATION);
    },
    onError: (error: Error) => {
      dispatch({type: 'simulateDesignInProgress', payload: false});
      dispatch({ type: "setErrorModal", payload: {error, message: "Failed to simulate design with Lightmile-Core"}})
    },
    onSuccess: async (designSimulatedEvent: DesignSimulatedWithLightmileCore) => {
      FullStory.event("Design Simulated With Lightmile Core", {})
      await reloadDesignAndProductionSimulation(designSimulatedEvent);
    }
  });

  const reloadDesignAndProductionSimulation = async (designSimulatedEvent: DesignSimulatedWithLightmileCore) => {
    loadingScreenHelpers.addProcess({
      group: LoadingProcessGroups.FINALIZE_DESIGN,
      name: LoadingProcessNames.RELOAD_DESIGN,
    });
    const {designId, productionSimulationId, productionSimulationVersion} = designSimulatedEvent;
    const newDesign = await repository.get(Design, designId, 0);
    const newProductionSimulation = await repository.get(ProductionSimulation, productionSimulationId, productionSimulationVersion);
    // We persist the design and production simulation in two ways: in the QueryClient (a global provider in the app
    // that maintains a cache) and in our useContext/useReducer-based workspace state.
    // TODO: this necessity of synchronizing two caches is begging to create bugs. How can we improve? https://sunrun.jira.com/browse/LS-1714
    queryClient.setQueryData(["getDesign", designId], newDesign);
    queryClient.setQueryData(["getProductionSimulation", productionSimulationId], newProductionSimulation);
    dispatch({type: 'setDesign', payload: newDesign});
    dispatch({type: 'setProductionSimulation', payload: {simulation:newProductionSimulation, design: newDesign}});
    dispatch({type: 'simulateDesignInProgress', payload: false});
    loadingScreenHelpers.completeProcess(LoadingProcessNames.RELOAD_DESIGN);
  }

  return {
    simulateDesign
  };
};
