import {PayloadAction, EmptyAction} from "src/types/state-management/action"
import {ModuleOrientation} from "@sunrun/design-tools-domain-model";
import {produce} from "immer";
import {WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";

// TODO: refactor all the state and actions pertaining to workflow state
//    (this is open/closed, this is displaying/not) to the workflowState slice
//    and keep only user settings here.

export type SettingsAction =
  | EmptyAction<'toggleIsSunHoursVisible'>
  | EmptyAction<'toggleIsMapImageLayerVisible'>
  | EmptyAction<'toggleIsModuleSpacingLayerVisible'>
  | EmptyAction<'toggleIsRoofFacesLayerVisible'>
  | EmptyAction<'toggleIsModulesLayerVisible'>
  | EmptyAction<'toggleIsSetbacksLayerVisible'>
  | EmptyAction<'toggleIsObstructionsLayerVisible'>
  | EmptyAction<'toggleIsTreesLayerVisible'>
  | EmptyAction<'toggleIsDesignMenuOpen'>
  | EmptyAction<'closeDesignMenu'>
  | EmptyAction<'openDesignMenu'>
  | EmptyAction<'toggleIsInterfaceMinimized'>
  | EmptyAction<'toggleIsMagneticSnapEnabled'>
  | EmptyAction<'toggleIsMagneticSlideEnabled'>
  | EmptyAction<'toggleModuleOrientationSetting'>
  | PayloadAction<ModuleOrientation, 'setModuleOrientationSetting'>
  | PayloadAction<boolean, 'setEnforceDesignRestrictionsForSales'>
  | PayloadAction<boolean, 'setIsHotkeyDescriptionsOpen'>
  | PayloadAction<boolean, 'setIsDesignMenuOpen'>
  | PayloadAction<boolean, 'setIsDesignStatusDialogueVisible'>

export type Settings = {
  isDesignMenuOpen: boolean
  isInterfaceMinimized: boolean
  isMagneticSnapEnabled: boolean
  isMagneticSlideEnabled: boolean
  isMapImageLayerVisible: boolean
  isModuleSpacingLayerVisible: boolean
  isRoofFacesLayerVisible: boolean
  isModulesLayerVisible: boolean
  isSetbacksLayerVisible: boolean
  isObstructionsLayerVisible: boolean
  isTreesLayerVisible: boolean
  isSunHoursVisible: boolean
  moduleOrientation: ModuleOrientation
  isDesignStatusDialogueVisible: boolean
  enforceDesignRestrictionsForSales: boolean
  temporarilyDisableMagneticSnap: boolean
}

export const settingsInitialState: Settings = {
  isDesignMenuOpen: false,
  isInterfaceMinimized: false,
  isMagneticSnapEnabled: true,
  isMagneticSlideEnabled: false,
  isMapImageLayerVisible: true,
  isModuleSpacingLayerVisible: true,
  isRoofFacesLayerVisible: true,
  isModulesLayerVisible: true,
  isSetbacksLayerVisible: true,
  isObstructionsLayerVisible: true,
  isTreesLayerVisible: true,
  isSunHoursVisible: true,
  moduleOrientation: ModuleOrientation.Portrait,
  isDesignStatusDialogueVisible: false,
  enforceDesignRestrictionsForSales: false,
  temporarilyDisableMagneticSnap: false,
}

export const selectSettingsEnforceDesignRestrictionsForSales = (state: WorkspaceState) => state.settings.enforceDesignRestrictionsForSales

export const settingsReducer = (state: WorkspaceState, action: WorkspaceAction): Settings => {
  switch (action.type) {
    case "closeDesignMenu": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = false
      })
    }
    case "openDesignMenu": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = true
      })
    }
    case "setIsDesignMenuOpen": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = action.payload
      })
    }
    case "setIsDesignStatusDialogueVisible": {
      return produce(state.settings, draft => {
        draft.isDesignStatusDialogueVisible = action.payload
      })
    }
    case 'setModuleOrientationSetting': {
      return produce(state.settings, draft => {
        draft.moduleOrientation = action.payload
      })
    }
    case "setEnforceDesignRestrictionsForSales": {
      return produce(state.settings, draft => {
        draft.enforceDesignRestrictionsForSales = action.payload
      })
    }
    //
    case "toggleIsDesignMenuOpen": {
      return produce(state.settings, draft => {
        draft.isDesignMenuOpen = !state.settings.isDesignMenuOpen
      })
    }
    case "toggleIsInterfaceMinimized": {
      return produce(state.settings, draft => {
        draft.isInterfaceMinimized = !state.settings.isInterfaceMinimized
      })
    }
    case "toggleIsMagneticSnapEnabled": {
      return produce(state.settings, draft => {
        draft.isMagneticSnapEnabled = !state.settings.isMagneticSnapEnabled
      })
    }
    case "toggleIsMagneticSlideEnabled": {
      return produce(state.settings, draft => {
        draft.isMagneticSlideEnabled = !state.settings.isMagneticSlideEnabled
      })
    }
    case 'toggleIsSunHoursVisible': {
      return produce(state.settings, draft => {
        draft.isSunHoursVisible = !state.settings.isSunHoursVisible
      })
    }
    case 'toggleIsMapImageLayerVisible': {
      return produce(state.settings, draft => {
        draft.isMapImageLayerVisible = !state.settings.isMapImageLayerVisible
      })
    }
    case 'toggleIsModuleSpacingLayerVisible': {
      return produce(state.settings, draft => {
        draft.isModuleSpacingLayerVisible = !state.settings.isModuleSpacingLayerVisible
      })
    }
    case 'toggleIsRoofFacesLayerVisible': {
      return produce(state.settings, draft => {
        draft.isRoofFacesLayerVisible = !state.settings.isRoofFacesLayerVisible
      })
    }
    case 'toggleIsModulesLayerVisible': {
      return produce(state.settings, draft => {
        draft.isModulesLayerVisible = !state.settings.isModulesLayerVisible
      })
    }
    case 'toggleIsSetbacksLayerVisible': {
      return produce(state.settings, draft => {
        draft.isSetbacksLayerVisible = !state.settings.isSetbacksLayerVisible
      })
    }
    case 'toggleIsObstructionsLayerVisible': {
      return produce(state.settings, draft => {
        draft.isObstructionsLayerVisible = !state.settings.isObstructionsLayerVisible
      })
    }
    case 'toggleIsTreesLayerVisible': {
      return produce(state.settings, draft => {
        draft.isTreesLayerVisible = !state.settings.isTreesLayerVisible
      })
    }
    case "toggleModuleOrientationSetting": {
      return produce(state.settings, draft => {
        if (state.settings.moduleOrientation === ModuleOrientation.Portrait) {
          draft.moduleOrientation = ModuleOrientation.Landscape
        } else {
          draft.moduleOrientation = ModuleOrientation.Portrait
        }
      })
    }
    default: {
      return state.settings
    }
  }
}
