import LoadingScreen, {
  useLoadingScreen,
} from "@sunrun/design-tools-loading-screen";
import { ReactNode, useEffect, useState } from "react";
import {
  ErrorModal,
  FinancialProductsModal,
  HotkeyDescriptionsModal,
  InfoModal,
  ModuleIssuesModal,
  OfferUpdateConfirmationModal,
  WhatsNewModal,
  CancelContinueModal
} from "./Modal";
import { useWorkspace } from "src/hooks/useWorkspace";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import Toolbar, { WorkflowButtonProps } from "./Toolbar";
import FloatingActionButtons from "./FloatingActionButtons";
import {
  LoadingProcessGroups,
  LoadingProcessNames,
} from "src/types/LoadingScreenProcess";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { ProductEquipmentMappingErrorModal } from "./Modal/ProductEquipmentMappingErrorModal";

type WorkspaceProps = {
  children: ReactNode;
  disableFinalizeDesignButton: boolean;
  simulateDesign: () => void;
  exportDesign: () => void;
};

const Workspace = ({
  children,
  disableFinalizeDesignButton,
  simulateDesign,
  exportDesign,
}: WorkspaceProps) => {
  const { state, dispatch } = useWorkspace();
  const {
    design,
    siteModel,
    geoRaster,
    solarResource,
    productionSimulation,
    customer,
    designConstraints,
    offer
  } = state;
  const theme = useTheme<DesignToolsTheme>()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  // TODO: wrap this inside workflowState
  const [hasDesignLoadedOnce, setHasDesignLoadedOnce] = useState(false);
  const { helpers: loadingScreenHelpers } = useLoadingScreen();
  useEffect(
    function initDesignInsideOfferBuilder() {
      // TODO for HMP1 pt 2: don't do this when it is a change order
      if (offer && design && design.latest && !hasDesignLoadedOnce) {
        // design has loaded, but other aggregates required for max fill have not
        if (!productionSimulation || !customer || !solarResource) {
          loadingScreenHelpers.addProcess({
            group: LoadingProcessGroups.INITIALIZE_IHD,
            name: LoadingProcessNames.MAXFILL,
          });
        }

        // both design & productionSimulation have loaded
        else {
          setHasDesignLoadedOnce(true);
          dispatch({type: "designInitiatedInOfferBuilder"});
          dispatch({type: "productToEquipmentMappingInProgress", payload: true},);
          loadingScreenHelpers.completeProcess(LoadingProcessNames.MAXFILL);
        }
      }
    },
    [offer, design?.latest, siteModel, productionSimulation, hasDesignLoadedOnce, customer, solarResource]
  );

  const workflowButtonProps: WorkflowButtonProps = {
    disableFinalizeDesignButton,
    simulateDesign,
    exportDesign
  }

  window.scrollTo(1, 0);

  return (
    <div className="workspace">
      <LoadingScreen offerExperience />
      <InfoModal
        title={state.modal?.infoModal?.title}
        message={state.modal?.infoModal?.message}
        open={!!state.modal?.infoModal}
        onClose={() => dispatch({ type: "dismissInfoModal" })}
      />
      <FinancialProductsModal
        open={state.modal.financialProductsOpen}
        onClose={() => dispatch({ type: "dismissFinancialProducts" })}
      />
      <OfferUpdateConfirmationModal
        open={state.modal.isOfferUpdateConfirmationModalOpen}
        onClose={() => dispatch({type:"dismissOfferUpdateConfirmationModal"})}
      />
      <ProductEquipmentMappingErrorModal
        open={state.modal.isProductEquipmentMappingErrorModalOpen}
      />
      <ErrorModal 
        title={state.modal.errorModal?.title}
        message={state.modal.errorModal?.message}
        error={state.modal.errorModal?.error}
        open={!!state.modal.errorModal}
        onClose={() => dispatch({ type: "dismissErrorModal" })}
      />
      <WhatsNewModal
        open={state.modal.isWhatsNewOpen}
        onClose={() => dispatch({ type: "closeWhatsNew" })}
      />
      <HotkeyDescriptionsModal
        open={state.modal.isHotkeyDescriptionsOpen}
        onClose={() => dispatch({ type: "closeHotKeyDescriptions" })}
      />
      <ModuleIssuesModal
        open={state.modal.isModuleIssuesOpen}
        onClose={() => dispatch({ type: "dismissModuleIssues" })}
      />
      <CancelContinueModal
       title={state.modal?.cancelContinueModal?.title}
       message={state.modal?.cancelContinueModal?.message}
       open={!!state.modal?.cancelContinueModal}
       cancelText={state.modal?.cancelContinueModal?.cancelText}
       continueText={state.modal?.cancelContinueModal?.continueText}
       onContinue={state.modal?.cancelContinueModal?.onContinue}
       onClose={() => dispatch({ type: "dismissCancelContinueModal" })}
      />
      {design && siteModel && geoRaster && (
        <>
          {children}
          {solarResource &&
            productionSimulation &&
            customer &&
            designConstraints && (
              <Container
                maxWidth="xl"
                disableGutters={isXs}
                sx={{
                  py: isXs ? 0 : 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100dvh",
                }}
              >
                <Toolbar {...workflowButtonProps} />
                <FloatingActionButtons {...workflowButtonProps} />
              </Container>
            )}
        </>
      )}
    </div>
  );
};

export default Workspace;
