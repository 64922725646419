import {createSelector} from "reselect";
import {ModuleIssue} from "@sunrun/design-tools-domain-model";
import {deriveCollidingModules} from "./deriveCollidingModules";
import {deriveIslandedModules} from "./deriveIslandedModules";
import {deriveShortStringModules} from "./deriveShortStringModules";
import { deriveLowSunHourModules } from "./deriveLowSunHourModules";
import { deriveOffRoofModules } from "./deriveOffRoofModules";
import { selectSettingsEnforceDesignRestrictionsForSales } from "src/features/settings/settingsSlice";

export const deriveModulesWithIssues = createSelector(
  [deriveCollidingModules, deriveOffRoofModules, deriveIslandedModules, deriveShortStringModules, deriveLowSunHourModules, selectSettingsEnforceDesignRestrictionsForSales],
  (collidingModules, offRoofModules, islandedModules, shortStringModules, lowSunHourModules, enforceDesignRestrictionsForSales): Map<string, Set<ModuleIssue>> => {
    const modulesWithIssues: Map<string, Set<ModuleIssue>> = new Map<string, Set<ModuleIssue>>();
    const addToModuleIssues = (modulesWithIssues: Map<string, Set<ModuleIssue>>, moduleId: string, moduleIssue: ModuleIssue) => {
      if (modulesWithIssues.has(moduleId)) {
        const existingIssues = modulesWithIssues.get(moduleId)!;
        modulesWithIssues.set(moduleId, existingIssues.add(moduleIssue));
      }
      else {
        modulesWithIssues.set(moduleId, new Set<ModuleIssue>([moduleIssue]));
      }
    }

    collidingModules.forEach(moduleId => addToModuleIssues(modulesWithIssues, moduleId, ModuleIssue.Colliding));
    offRoofModules.forEach(moduleId => addToModuleIssues(modulesWithIssues, moduleId, ModuleIssue.OffRoofFace));
    if (enforceDesignRestrictionsForSales) {
      islandedModules.forEach(moduleId => addToModuleIssues(modulesWithIssues, moduleId, ModuleIssue.Islanded));
    }
    shortStringModules.forEach(moduleId => addToModuleIssues(modulesWithIssues, moduleId, ModuleIssue.ShortString));
    lowSunHourModules.forEach(moduleId => addToModuleIssues(modulesWithIssues, moduleId, ModuleIssue.LowSunHours));

    return modulesWithIssues;
  }
);
