import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {processManagerClient} from "@sunrun/design-tools-graphql-clients";
import {CommandType, ImportLightmileProject, LightmileProjectImported, LightmileProjectSource} from "@sunrun/design-tools-domain-model";
import {RuntimeContext} from "@sunrun/design-tools-runtime-context";
import * as FullStory from "@fullstory/browser";
import { parseLambdaError } from "src/utils/lambdaErrorParser";
import { useErrorHandler } from "react-error-boundary";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey"

import type { WorkspaceURLSearchParameterKey } from "src/types/URLSearchParameterKey";

export const useImportLightmileProject = () => {
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const effectiveDate = searchParams.get(URLSearchParameterKey.EffectiveDate);
  const host = searchParams.get(URLSearchParameterKey.Host) || "";
  const copyFromDesignId = searchParams.get(URLSearchParameterKey.CopyFromDesignId) || "";
  const apiUrl = searchParams.get(URLSearchParameterKey.ApiUrl);
  const envOverride = searchParams.get(URLSearchParameterKey.EnvOverride);

  const [importParams, setImportParams] = React.useState<{
    activeProjectSource: LightmileProjectSource,
    prospectId?: string,
    signedRootId?: string,
    signedRootIdDashProduct?: string,
    interconnectionAppliedProposalId?: string,
    copyFromProposalId?: string,
    offerId?: string,
  }>();

  React.useEffect(function importProjectAndRerouteToWorkspace() {
    if (importParams) {
      loadingScreenHelpers.addProcess({ name: "Importing Lightmile Project" }) // TODO add process/group to "src/types/LoadingScreenProcess"
      const {activeProjectSource, prospectId, signedRootId, signedRootIdDashProduct, interconnectionAppliedProposalId, copyFromProposalId, offerId} = importParams;
      const importPromises = createImportPromises(activeProjectSource, prospectId, signedRootId, interconnectionAppliedProposalId, copyFromProposalId);

      Promise.allSettled(importPromises)
        .then((results) => {
          const [
            activeProjectImportResult,
            signedRootProjectImportResult,
            interconnectionAppliedProjectImportResult,
            copyFromProposalImportResult
          ] = results;
          loadingScreenHelpers.completeProcess("Importing Lightmile Project");

          if (activeProjectImportResult.status === "fulfilled") {
            const lightmileProjectImported = activeProjectImportResult.value as LightmileProjectImported;
            const signedRootProjectImported = signedRootProjectImportResult.status === "fulfilled" ? signedRootProjectImportResult.value as LightmileProjectImported: null;
            const interconnectionAppliedProjectImported = interconnectionAppliedProjectImportResult.status === "fulfilled" ? interconnectionAppliedProjectImportResult.value as LightmileProjectImported : null;
            const copyFromProposalImported = copyFromProposalImportResult.status === "fulfilled" ? copyFromProposalImportResult.value as LightmileProjectImported : null;
            RuntimeContext.prospectId = lightmileProjectImported.prospectId;

            const workspaceUrl = constructWorkspaceUrl({
              [URLSearchParameterKey.DesignId]: lightmileProjectImported.designId,
              [URLSearchParameterKey.LightmileProjectId]: lightmileProjectImported.lightmileProjectId,
              [URLSearchParameterKey.SignedRootId]: signedRootId || null,
              [URLSearchParameterKey.SignedRootIdDashProduct]: signedRootIdDashProduct || null,
              [URLSearchParameterKey.EffectiveDate]: effectiveDate || null,
              [URLSearchParameterKey.SignedRootDesignId]: signedRootProjectImported?.designId || null,
              [URLSearchParameterKey.InterconnectionAppliedDesignId]: interconnectionAppliedProjectImported?.designId || null,
              [URLSearchParameterKey.CopyFromDesignId]: copyFromDesignId || copyFromProposalImported?.designId || null,
              [URLSearchParameterKey.OfferId]: offerId || null,
              [URLSearchParameterKey.Host]: host,
              [URLSearchParameterKey.ApiUrl]: apiUrl,
              [URLSearchParameterKey.EnvOverride]: envOverride,
            });
            navigate(workspaceUrl);
            console.log("redirect to workspace:", workspaceUrl);
            FullStory.event(
              "Imported Lightmile Project",
              {
                lightmileProspectId: lightmileProjectImported.prospectId,
                lightmileProjectId: lightmileProjectImported.lightmileProjectId,
                lightmileDesignIndex: importParams.activeProjectSource.sourceByDesign?.lightmileDesignIndex,
                proposalId: importParams.activeProjectSource.sourceByProspect?.proposalId,
                parentProposalId: importParams.activeProjectSource.sourceByProspect?.parentProposalId,
                signedRootId: signedRootId,
              }
            );
          }
          else {
            const localError = new Error('Failed to import Lightmile Project.')
            const parsedError = parseLambdaError(activeProjectImportResult.reason, localError)
            throw parsedError;
          };
        })
        .catch((error) => {
          handleError(error)
        });
    }
  }, [importParams]);

  return {
    setImportParams
  }
}

const createImportPromises = (
  lightmileProjectSource: LightmileProjectSource,
  prospectId?: string,
  signedRootId?: string,
  interconnectionAppliedProposalId?: string,
  copyFromProposalId?: string,
): Promise<LightmileProjectImported | void>[] => {
  const lightmileProjectImportCommand: ImportLightmileProject = {
    type: CommandType.ImportLightmileProject,
    lightmileProjectSource,
  };
  const importPromises: Promise<LightmileProjectImported | void>[] = [
    processManagerClient.importLightmileProjectAsync(lightmileProjectImportCommand)
  ];

  if (prospectId && signedRootId) {
    const signedRootImportCommand: ImportLightmileProject = {
      type: CommandType.ImportLightmileProject,
      lightmileProjectSource: {
        sourceByProspect: {
          prospectId,
          proposalId: signedRootId,
        }
      }
    };
    importPromises.push(processManagerClient.importLightmileProjectAsync(signedRootImportCommand));
  }
  else {
    importPromises.push(Promise.resolve());
  }

  if (prospectId && interconnectionAppliedProposalId) {
    const interconnectionAppliedImportCommand: ImportLightmileProject = {
      type: CommandType.ImportLightmileProject,
      lightmileProjectSource: {
        sourceByProspect: {
          prospectId,
          proposalId: interconnectionAppliedProposalId,
        }
      }
    };
    importPromises.push(processManagerClient.importLightmileProjectAsync(interconnectionAppliedImportCommand));
  }
  else {
    importPromises.push(Promise.resolve());
  }

  if (prospectId && copyFromProposalId) {
    const copyFromProposalImportCommand: ImportLightmileProject = {
      type: CommandType.ImportLightmileProject,
      lightmileProjectSource: {
        sourceByProspect: {
          prospectId,
          proposalId: copyFromProposalId,
        }
      }
    };
    importPromises.push(processManagerClient.importLightmileProjectAsync(copyFromProposalImportCommand));
  }
  else {
    importPromises.push(Promise.resolve());
  }

  return importPromises;
};

export const constructWorkspaceUrl = ( params: Partial<Record<WorkspaceURLSearchParameterKey, string | number | null>>) => {
  const queryParams = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      queryParams.append(key, String(value));
    }
  }
  const workspaceUrl = `/workspace?${queryParams.toString()}`;
  console.log("redirect to workspace:", workspaceUrl);
  return workspaceUrl;
}
