import {useSearchParams} from "react-router-dom";
import React from "react";
import {RuntimeContext} from "@sunrun/design-tools-runtime-context";
import {repository} from "@sunrun/design-tools-graphql-clients";
import designTestFixture from "src/test/oldTestFixtures/designs/design"
import siteModelWithRoof from "src/test/oldTestFixtures/siteModels/siteModelWithRoof";
import {
  productionSimulation as productionSimulationTestFixture
} from "src/test/oldTestFixtures/productionSimulation/productionSimulation";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {useDomainModelByDesignId} from "src/hooks/useDomainModelByDesignId";
import {Design, link, ShadeSource, SolarResource} from "@sunrun/design-tools-domain-model";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

const getNumberFromSearchParams = (key: URLSearchParameterKey, searchParams: URLSearchParams): number | undefined => {
  const param = searchParams.get(key)
  return param ? parseInt(param) : undefined
}

export const useDomainModelWithSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {helpers: loadingScreenHelpers} = useLoadingScreen();

  React.useEffect(function setSearchParamsToTestFixturesInNonPrd(){
    const saveTestFixturesAndUpdateSearchParams = async () => {
      loadingScreenHelpers.addProcess({ name: "Saving Test Fixtures" }) // TODO add process/group to "src/types/LoadingScreenProcess"

      const testSolarResource = new SolarResource({id: "testFixture", version:0, siteModelId:"testFixture", siteModelVersion:0, source: ShadeSource.LightmileCore}) // hack to use the persistent test fixture in dev
      const linkedDesign = link(designTestFixture, siteModelWithRoof, productionSimulationTestFixture, testSolarResource)
      const [designMutation, siteModelMutation, productionSimulationMutation] = await Promise.all([
        repository.save(linkedDesign),
        repository.save(siteModelWithRoof),
        repository.save(productionSimulationTestFixture)
      ])
      setSearchParams({
        [URLSearchParameterKey.DesignId]: designMutation.v0.id,
      })
      loadingScreenHelpers.completeProcess("Saving Test Fixtures")
    }
    const designIdSearchParam = searchParams.get(URLSearchParameterKey.DesignId)
    if (designIdSearchParam) {
      // NOOP if search params are set
    } else {
      if (RuntimeContext.env.name === "prd") {
        throw new Error("DesignId must be set to load the workspace")
      }
      saveTestFixturesAndUpdateSearchParams().catch(e => console.log(`saving test fixtures failed: ${e.message}` ))
    }
  }, [searchParams, setSearchParams])

  return useDomainModelByDesignId({
    designId: searchParams.get(URLSearchParameterKey.DesignId) || undefined,
    designVersion: getNumberFromSearchParams(URLSearchParameterKey.DesignVersion, searchParams),
    signedRootDesignId: searchParams.get(URLSearchParameterKey.SignedRootDesignId) || undefined,
    interconnectionAppliedDesignId: searchParams.get(URLSearchParameterKey.InterconnectionAppliedDesignId) || undefined,
    copyFromDesignId: searchParams.get(URLSearchParameterKey.CopyFromDesignId) || undefined,
  })
}
