import { Paper, useTheme } from "@mui/material";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { WorkflowButton } from "src/components/Toolbar";
import { ToolbarProps } from "src/components/Toolbar/Toolbar";

export const Footer = (props: ToolbarProps) => {
  const theme = useTheme<DesignToolsTheme>()
  return (
    <Paper sx={{
      position: "relative",
      zIndex: theme.zIndex.floatingActionButton,
      padding: 2,
      mt: 1,
      display: "flex",
      justifyContent: "center"
    }}>
      <WorkflowButton
        {...props}
        buttonProps={{ width: "200px", size: "md" }}
      />
    </Paper>
  );
};
