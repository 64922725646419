import {FeatureGroup,  LayersControl, Pane, useMap} from "react-leaflet";
import {setbackStripes} from "src/styles/stylingPatterns";
import {SiteModel} from "@sunrun/design-tools-domain-model";
import {SetbackLayer} from "./SetbackLayer";
import {FireCodePathwayLayer} from "./FireCodePathwayLayer";
import {LeafletLayerZIndexes} from "src/constants";
import { useWorkspace } from "src/hooks/useWorkspace";

type SetbackLayerGroupProps = {
  siteModel: SiteModel
}

export const SetbackFeatureGroup = (props: SetbackLayerGroupProps) => {
  const map = useMap();
  setbackStripes.addTo(map);
  const { state } = useWorkspace()
  const { settings } = state
  if (!settings.isSetbacksLayerVisible) return null
  return (
    <Pane name="setbacks" style={{zIndex: LeafletLayerZIndexes.setbacks}}>
      <FeatureGroup>
        {props.siteModel.setbacks.map(it =>
          <SetbackLayer
            key={it.id}
            setback={it}
          />
        )}
        {props.siteModel.fireCodePathways.map(it =>
          <FireCodePathwayLayer
            key={it.id}
            fireCodePathway={it}
          />
        )}
      </FeatureGroup>
    </Pane>
  )
}
