import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {EmptyAction, PayloadAction} from "../../types/state-management/action";
import {LatLngBounds} from "leaflet";

export type HeatMap = {
  isVisible: boolean
  bounds?: LatLngBounds
}

export type HeatMapAction =
  | PayloadAction<LatLngBounds, 'setHeatMapBounds'>
  | PayloadAction<boolean, 'setHeatMapVisibility'>
  | EmptyAction<'toggleHeatMapVisibility'>

export const heatMapReducer = (state: WorkspaceState, action: WorkspaceAction): HeatMap => {
  switch (action.type) {
    case 'setHeatMapBounds': {
      return produce(state.heatMap, draft => {
        draft.bounds = action.payload
      })
    }
    case 'setHeatMapVisibility': {
      return produce(state.heatMap, draft => {
        draft.isVisible = action.payload
      })
    }
    case 'toggleHeatMapVisibility': {
      return produce(state.heatMap, draft => {
        draft.isVisible = !state.heatMap.isVisible
      })
    }
    default : {
      return state.heatMap
    }
  }
}
