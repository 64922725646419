import { useEffect, useState } from "react";
import { useWorkspace } from "./useWorkspace";
import { Design, ProductionSimulation } from "@sunrun/design-tools-domain-model";
import { repository } from "@sunrun/design-tools-graphql-clients";
import { useErrorHandler } from "react-error-boundary";
import { useQuery } from "react-query";
import { useLoadingScreen } from "@sunrun/design-tools-loading-screen";
import { LoadingProcessGroups, LoadingProcessNames } from "src/types/LoadingScreenProcess";

type UseReadOnlyDesignSetProps = {
  signedRootDesignId?: string;
  interconnectionAppliedDesignId?: string;
  copyFromDesignId?: string;
};

export const useReadOnlyDesignSet = ({
    signedRootDesignId,
    interconnectionAppliedDesignId,
    copyFromDesignId
  }: UseReadOnlyDesignSetProps
) => {
  const { dispatch } = useWorkspace();
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const [signedRootDesign, setSignedRootDesign] = useState<Design>();
  const [signedRootProductionSimulation, setSignedRootProductionSimulation] = useState<ProductionSimulation>();
  const [interconnectionAppliedDesign, setInterconnectionAppliedDesign] = useState<Design>();
  const [interconnectionAppliedProductionSimulation, setInterconnectionAppliedProductionSimulation] = useState<ProductionSimulation>();

  const getDesign = async (designId?: string | null | undefined) => {
    if (designId) return repository.get(Design, designId, 0);
    return undefined;
  };

  const getProductionSimulation = async (productionSimulationId?: string | null | undefined) => {
    if (productionSimulationId) return repository.get(ProductionSimulation, productionSimulationId, 0);
    return undefined;
  };

  const signedRootDesignQuery = useQuery(["getDesign", signedRootDesignId], () => getDesign(signedRootDesignId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!signedRootDesignId, 
    onSuccess: (design: Design) => {
      setSignedRootDesign(design)
    },
    onError: handleError,
  });

  const signedRootProductionSimulationQuery = useQuery(["getProductionSimulation", signedRootDesign], () => getProductionSimulation(signedRootDesign?.productionSimulationId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!signedRootDesign, 
    onSuccess: (productionSimulation: ProductionSimulation) => {
      setSignedRootProductionSimulation(productionSimulation)
    },
    onError: handleError,
  });

  const interconnectionAppliedDesignQuery = useQuery(["getDesign", interconnectionAppliedDesignId], () => getDesign(interconnectionAppliedDesignId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!interconnectionAppliedDesignId, 
    onSuccess: (design: Design) => {
      setInterconnectionAppliedDesign(design)
    },
    onError: handleError,
  });

  const interconnectionAppliedProductionSimulationQuery = useQuery(["getProductionSimulation", interconnectionAppliedDesign], () => getProductionSimulation(interconnectionAppliedDesign?.productionSimulationId), {
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    enabled: !!interconnectionAppliedDesign, 
    onSuccess: (productionSimulation: ProductionSimulation) => {
      setInterconnectionAppliedProductionSimulation(productionSimulation)
    },
    onError: handleError,
  });

  // When we copy from a design, we only care to copy the Design itself; no need to fetch the ProductionSimulation
  const copyFromDesignQuery = useQuery(["getDesign", copyFromDesignId], () => getDesign(copyFromDesignId), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!copyFromDesignId,
    onSuccess: (design: Design) => {
      dispatch({ type: "setCopyFromDesign", payload: design });
    },
    onError: handleError,
  });

  useEffect(() => {
    if(signedRootDesign && signedRootProductionSimulation){
      dispatch({ 
        type: "setSignedRootDesignSet", 
        payload: { design: signedRootDesign, productionSimulation: signedRootProductionSimulation } 
      });
    }
  },[signedRootDesign, signedRootProductionSimulation])

  useEffect(() => {
    if(interconnectionAppliedDesign && interconnectionAppliedProductionSimulation){
      dispatch({ 
        type: "setInterconnectionAppliedDesignSet", 
        payload: { design: interconnectionAppliedDesign, productionSimulation: interconnectionAppliedProductionSimulation } 
      });
    }
  },[interconnectionAppliedDesign, interconnectionAppliedProductionSimulation])

  useEffect(function addLoadingScreenProcess() {
    if (
      signedRootDesignQuery.isFetching ||
      interconnectionAppliedDesignQuery.isFetching ||
      copyFromDesignQuery.isFetching
    ) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.DESIGN,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN);
      };
    }
  }, 
  [
    signedRootDesignQuery.isFetching, 
    interconnectionAppliedDesignQuery.isFetching,
    copyFromDesignQuery.isFetching,
  ]);
  
  useEffect(function addLoadingScreenProcess() {
    if (signedRootProductionSimulationQuery.isFetching || interconnectionAppliedProductionSimulationQuery.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.DESIGN_SIMULATION,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_SIMULATION);
      };
    }
  }, 
  [
    signedRootProductionSimulationQuery.isFetching,
    interconnectionAppliedProductionSimulationQuery.isFetching
  ]);
};