import { Fab, IconButton, Paper, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";

export const ZoomControl = () => {
  const { state } =  useWorkspace();
  const {
    design,
    siteModel,
    geoRaster,
    solarResource,
    productionSimulation,
    customer,
    designConstraints,
  } = state;
  const isWorkspaceLoaded = 
    design &&
    siteModel &&
    geoRaster &&
    solarResource &&
    productionSimulation &&
    customer &&
    designConstraints
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const map = useMap();
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const currentZoom = map.getZoom();
  const maxZoom = map.getMaxZoom();
  const minZoom = map.getMinZoom();

  useEffect(() => {
    setZoomInDisabled(currentZoom >= maxZoom)
    setZoomOutDisabled(currentZoom <= minZoom);
  }, [currentZoom])
  
  const zoomIn = () => map.zoomIn();
  const zoomOut = () => map.zoomOut();
  if (!isWorkspaceLoaded) return null
  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "100px",
        maxWidth: "max-content",
        ml: "auto",
        mb: isXs ? "92px" : 0, // HACK because this component currently can't be used in the FloatingActionButtons component
        overflow: "hidden",
        position: "relative",
        zIndex: theme.zIndex.floatingActionButton,
      }}
    >
      <Fab
        color="secondary"
        onClick={zoomIn}
        disabled={zoomInDisabled}
        sx={{ borderRadius: "0", boxShadow: "none" }}
        >
        <AddIcon />
      </Fab>
      <Fab
        color="secondary"
        onClick={zoomOut}
        disabled={zoomOutDisabled}
        sx={{ borderRadius: "0", boxShadow: "none" }}
      >
        <RemoveIcon />
      </Fab>
    </Paper>
  );
};
