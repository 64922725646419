import {Link} from "react-router-dom";

export const Imports = () => {
  return (
    <div>
      <h1>Imports</h1>
      <p>Choose one of the following to import data into iHD:</p>
      <nav>
        <ul>
          <li>
            <Link to="lightmile">Lightmile</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
