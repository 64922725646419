import L from "leaflet";
import 'leaflet.pattern';

export const obstructionStripes = new L.StripePattern({
  weight: 2,
  height: 5,
  width: 5,
  color: '#ea3434',
  spaceColor: '#000',
  angle: 45,
  spaceOpacity: 1,
});

export const setbackStripes = new L.StripePattern({
  weight: 2,
  height: 5,
  width: 5,
  color: '#EA3434',
  angle: 135,
});

