import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { BatterySpecification, DesignConstraintType, InverterSpecification } from "@sunrun/design-tools-domain-model";
import StatDisplay, { StatDisplayProps } from "src/components/StatDisplay";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { useWorkspace } from "src/hooks/useWorkspace";


interface InverterSpec extends InverterSpecification {
  count: number;
}

type InverterSpecById = Record<string, InverterSpec>;

interface BatterySpec extends BatterySpecification {
  count: number;
}

type BatterySpecById = Record<string, BatterySpec>;

export const DesignDetails = () => {
  const {state} = useWorkspace();
  const {design, productionSimulation, customer, designConstraints, siteModel} = state;
  const theme =  useTheme();
  const isToolbarSize1 = useMediaQuery(theme.breakpoints.down('toolbar1'));
  const isToolbarSize2 = useMediaQuery(theme.breakpoints.down('toolbar2'));
  const isToolbarSize3 = useMediaQuery(theme.breakpoints.down('toolbar3'));
  const isToolbarSize4 = useMediaQuery(theme.breakpoints.down('toolbar4'));
  const designFinalized = deriveIsDesignFinalized(state)
  const simulationToUse = designFinalized ? productionSimulation : deriveLowResProductionSimulation(state);
  const hasMaxAcConstraint = designConstraints?.getConstraintValueRaw(DesignConstraintType.MaxAcSystemSizeKw) != undefined;
  const hasMaxCecAcConstraint = designConstraints?.getConstraintValueRaw(DesignConstraintType.MaxCecAcSystemSizeKw) != undefined;
  const sunHours = state.solarResource?.siteMaxSunHours.toFixed(0)

  let inverterSpecById: InverterSpecById = {};
  design?.inverters.forEach((inverter) => {
    const inverterId = inverter.properties.inverterSpecificationId;
    if (!inverterSpecById[inverterId]) {
      inverterSpecById[inverterId] = Object.assign(
        {},
        design.getInverterSpec(inverterId),
        { count: 1 }
      );
    } else {
      inverterSpecById[inverterId].count += 1;
    }
  });

  let batterySpecById: BatterySpecById = {};
  design?.batteries.forEach((battery) => {
    const batteryId = battery.properties.batterySpecificationId;
    if (!batterySpecById[batteryId]) {
      batterySpecById[batteryId] = Object.assign(
        {},
        design.getBatterySpec(batteryId),
        { count: 1 }
      );
    } else {
      batterySpecById[batteryId].count += 1;
    }
  });

  let equipmentList: StatDisplayProps[] = [
    {
      title: "Modules",
      stat: `${design?.moduleCount ?? 0} x ${design?.getModuleManufacturer()}: ${design?.getModuleModel()}`,
    },
    {
      title: "Inverter",
      stat: Object.values(inverterSpecById)
        .map((inverter) => `${inverter.count} x ${design?.getInverterManufacturer(inverter.id)}: ${design?.getInverterModel(inverter.id)}`)
        .join("\n"),
    },
  ];

  if (design?.batteryCount) {
    equipmentList.push({
      title: "Storage",
      stat: Object.values(batterySpecById)
        .map((battery) => `${battery.count} x ${design?.getBatteryManufacturer(battery.id)}: ${design?.getBatteryModel(battery.id)}`)
        .join("\n"),
    })
  }

  const designList: StatDisplayProps[] = [{
    title: "Total Roof Area",
    stat: siteModel?.roofAreaSquareFt() ?? 0,
    unit: "sq ft.",
    toDecimal: 0
  }]

  if (hasMaxAcConstraint) {
    designList.unshift({
      title: "AC System Size",
      stat: design?.acSystemSizeKw,
      unit: "kW",
      toDecimal: 3
    })
  }

  if (hasMaxCecAcConstraint) {
    designList.unshift({
      title: "CEC-AC System Size",
      stat: design?.cecAcSystemSizeKw ?? 0,
      unit: "kW",
      toDecimal: 3
    })
  }

  // Use Toolbar Breakpoints to decide when to show information in Design List
  if(isToolbarSize4){
    designList.unshift(
      {
        title: "DC System Size",
        stat: design?.dcSystemSizeKw,
        unit: "kW",
        toDecimal: 3
      },
    )
  }
  if(isToolbarSize3){
    designList.unshift(
      {
        title: "Consumption",
        stat: customer?.annualUsagekWh ?? 0,
        unit: "kWh",
      },
    )
  }
  if (isToolbarSize2) {
    designList.unshift(
      {
        title: "Production",
        stat: simulationToUse?.annualProductionKwh ?? 0,
        unit: "kWh",
      },
    )
  }
  if (isToolbarSize1) {
    designList.unshift(
      {
        title: "Sun Hours",
        stat: sunHours?? 0,
      },
    )
  }

  return (
    <DesignDetailsDisplay designList={designList} equipmentList={equipmentList}/>
  );
};

export const DesignDetailsDisplay = ({designList, equipmentList}:{designList:  StatDisplayProps[], equipmentList: StatDisplayProps[]}) =>{
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} data-testid="design-details-display-design-list">
        {designList.map((statDisplay: StatDisplayProps) => (
          <Stack mb={1} key={statDisplay.title}>
            <StatDisplay {...statDisplay} />
          </Stack>
        ))}
      </Grid>
      <Grid item xs={6} data-testid="design-details-display-equipment-list">
        {equipmentList.map((statDisplay: StatDisplayProps) => (
          <Stack mb={1}  key={statDisplay.title}>
            <StatDisplay {...statDisplay} />
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
}
