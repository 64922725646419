import {ModalLayout} from "./components/ModalLayout";
import {useWorkspace} from "../../hooks/useWorkspace";
import { postIFrameMessage } from "src/hooks/useIFrameHost";
import { IFrameEventType } from "src/types/IFrame";
import { Offers } from "@sunrun/design-tools-domain-model";
import React from "react";

type ProductEquipmentMappingErrorModalProps = {
  open: boolean;
}

export const ProductEquipmentMappingErrorModal = ({ open }: ProductEquipmentMappingErrorModalProps) => {
  const {state} = useWorkspace()
  const {design, offer} = state;
  const [showErrorDetails, setShowErrorDetails] = React.useState(false);
  if (design && offer) {
    const bundleTitle = Offers.getBundleTitle(offer);
    const {selectedInverterSpecificationIds, selectedBatterySpecificationIds} = design.selectedEquipmentSpecificationIds!;
    const {inverterSpecifications: inverterSpecs, batterySpecifications: batterySpecs} = design.equipmentSpecifications;
    const {selectedBatterySpecifications} = design;
    const missingInverters = selectedInverterSpecificationIds.length === 0;
    const shiftOfferLine = Offers.getShiftOfferLine(offer);
    const backupOfferLine = Offers.getBackupOfferLine(offer);
    const offerHasBatteryLine = !!shiftOfferLine || !!backupOfferLine;
    const offerHasPowerManagementLine = !!Offers.getPowerManagementOfferLine(offer);
    const missingBatteries = offerHasBatteryLine && selectedBatterySpecificationIds.length === 0;
    const {manufacturer: batteryMfctr, model: batteryModel} = selectedBatterySpecifications[0] || {};
    const selectedBatteryTitle = selectedBatterySpecifications[0] ? `${batteryMfctr}: ${batteryModel}` : ''
    const inverterMsg = `Could not find compatible Inverters to support ${selectedBatteryTitle} batteries${offerHasPowerManagementLine ? ' / SPAN' : ''}.\nAvailable inverters: \n${inverterSpecs.length === 0 ? '  (empty)' : ''}${inverterSpecs.map(spec => `  ${spec.manufacturer} - ${spec.model}`).join(`\n`)}\n`;
    const batteryOfferLineName = shiftOfferLine ? 'Shift' : backupOfferLine ? 'Backup' : '';
    const batteryOfferLineTitle = shiftOfferLine ? shiftOfferLine.variant?.title : backupOfferLine ? backupOfferLine.variant?.title : '';
    const batteryMsg = `Could not find Batteries to support ${batteryOfferLineName}.\nBattery OfferLine: ${batteryOfferLineTitle}.\nAvailable batteries: \n${batterySpecs.length === 0 ? '  (empty)' : ''}${batterySpecs.map(spec  => `  ${spec.manufacturer} - ${spec.model}`).join(`\n`)}\n`;
    const errorMsg = (missingInverters ? inverterMsg : '') + (missingBatteries ? batteryMsg : '');
    const error = new Error(errorMsg);

    const onClose = () => {
      // Return to OE product selection page
      // TODO: LS-1835 return to LM settings if hosted in LM
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
        designId: design?.id,
        offerId: offer?.id,
      });
    };
    const onclick = () => {
      setShowErrorDetails(!showErrorDetails)
    }
    return (
      <ModalLayout
        open={open}
        title="Could not find the right equipment"
        onClose={onClose}
        actionButtons
        cancelText="Update Products"
        fullscreen={true}
        error={error}
        displayCloseButton={false}
      >
        <>
          iHD could not find the right equipment to satisfy the Bundle option chosen ({bundleTitle}).<br/>
          <br/>
          It is likely that Lightmile is not configured properly.<br/>
          Please try updating the equipment settings tab in Lightmile and clicking Generate Designs.<br/>
          <br/>
          Alternatively, you can return to product selection and try again with a different Bundle.<br/>
        </>
      </ModalLayout>
    )
  }
  return null
}
