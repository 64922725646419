import * as React from "react";
import {useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {Design, ProductionSimulation} from "@sunrun/design-tools-domain-model";
import {repository} from "@sunrun/design-tools-graphql-clients";
import {useWorkspace} from "src/hooks/useWorkspace";

type UseProductionSimulationProps = {
  productionSimulationId?: string,
  productionSimulationVersion?: number,
}

export const useProductionSimulation = ({
  productionSimulationId,
  productionSimulationVersion,
}: UseProductionSimulationProps) => {
  const {dispatch} = useWorkspace()
  const handleError = useErrorHandler();


  const getProductionSimulation = async () => {
    if (productionSimulationId)
      return repository.get(ProductionSimulation, productionSimulationId, productionSimulationVersion ?? 0)
  }

  const query = useQuery(['getProductionSimulation', productionSimulationId], getProductionSimulation, {
    refetchOnWindowFocus: false,                // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!productionSimulationId,           // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: async (newProductionSimulation: ProductionSimulation) => {
      // TODO bug here -- we can load a productionSimulation whose designVersion doesn't match current Design
      // This can happen when a design is auto-saved (with a lowRes ProdSim, which isn't saved), and the site is refreshed,
      // the ProductionSimulation will match the previously imported/finalized design, not the current (most recently auto-saved) design.
      // Potentially low impact? (only on refresh(?) - is that a use case we need to support?)
      // now that we're doing a fudge-factor based on this stale sim, we need the matching version of the design
      const {designId,designVersion} = newProductionSimulation;
      // we could technically avoid the repository.get iff the version matches the current design, but we dont actually have that info available to us...
      // deal with this issue later
      if (designId == null || designVersion == null) {
        throw new Error('DesignID or DesignVersion is null in ProductionSimulation.')
      }
      const matchingDesign = await repository.get(Design,designId, designVersion);
      dispatch({type: "setProductionSimulation", payload: {simulation: newProductionSimulation, design: matchingDesign}})
    },
    onError: handleError,
  })
}
