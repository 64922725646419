import { zip } from "lodash";

/**
 * Parses a lambda error. Additionally, you can provide a local error
 * to be included in the resulting error (useful to get the local path where
 * lambda error ocurred)
 * @param error coming from lambda exception
 * @param localError new error to catch the local path
 * @returns the result of parsing error.message, specifically the stack trace and file location info. 
 * will return the raw error if parsing fails
 */
export function parseLambdaError(error: unknown, localError?: Error): Error {
  try {
  if(error instanceof Error){
    let message = ''
    let stack = ''
    const name = error.name;
    const parsedError: unknown = JSON.parse(error.message);
    if(isParsedError(parsedError)){
      // inside this if because of the type-guard isParsedError(), TS is convinced that parsedError is the type we expect
      message = parsedError.message || '' // message seems optional
      // zip gives us pairs from the parallel arrays path and location - hope they match!
      stack = zip(parsedError.path,parsedError.locations).map((x)=>{
        const [path,loc]=x;
        // zip fills in undefined for either of tha pair if the source array was too short
        const locString = loc ? `${loc.sourceName}:${loc.line}:${loc.column})`: ''
        return `at ${path} ${locString}`
      }).join('\n') // I like the join better than the reduce I suggested
      if (localError) {
        message = `${localError.message}\n${message}`;
        stack = `${localError.stack}\n${stack}`;
      }
    }else {
      // the object in that json message didn't match our expectations
      // it seems best to just use the message inside, right?
      message = error.message
    }
    return {name, message, stack};
  }else {
    // error wasn't even of type Error!
    return new Error(`Failed to parse lambda error:\n${error}`);
  }
} catch(errorHandlerDiedWhileHandlingError){
  // should be much more impossible now...
  // anyway, try our best to forward the error from lambda, even though parsing failed
  return error instanceof Error ? error : new Error(`Failed to parse lambda error:\n${error}`);
}
}


// some private helper stuff, so that ts can make sure we're not adding bugs
type Location = { 
  sourceName:string;
   line:number;
   column:number;
  }
type ParsedError = {
   message?: string
   path : Array<string>;
   locations: Array<Location>
}
function isLocation(obj: any): obj is Location {
  return obj.sourceName !== undefined && obj.line !== undefined && obj.column!==undefined &&
  typeof(obj.sourceName) === 'string' && typeof(obj.line)==='number' && typeof(obj.column)==='number'
}
function isParsedError(obj: any): obj is ParsedError {
  if(obj.path!==undefined && obj.path instanceof Array){
    for(let x of obj.path){
      if(typeof(x) !=='string') return false;
    }
  }else{
    return false;
  }
  if(obj.locations!==undefined && obj.locations instanceof Array){
    for(let x of obj.locations){
      if(!isLocation(x)) return false;
    }
  }
  return true;
}