import { useMediaQuery, useTheme } from "@mui/material";
import { ModalLayout } from "../components/ModalLayout";
import { FinancialProductsTable } from "./components/FinancialProductsTable";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";


type FinancialProductsModalProps = {
  open: boolean;
  onClose: () => void;
}

export const FinancialProductsModal = ({ open, onClose }: FinancialProductsModalProps) => {
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return(
    <ModalLayout 
      title="Financial Product Eligibility"
      open={open}
      onClose={onClose}
      fullscreen={isXs}
    >
      <FinancialProductsTable fullscreen={isXs}/>
    </ModalLayout>
  )
}