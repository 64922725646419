import {useQuery} from "react-query";
import {useSearchParams} from "react-router-dom";

import {storefrontClient} from "@sunrun/design-tools-graphql-clients";

import { useWorkspace } from "./useWorkspace";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey"

import type {Promotion, PromotionAvailability} from "@sunrun/design-tools-domain-model";

export const usePromotions = () => {
  const {dispatch} = useWorkspace();
  const [searchParams] = useSearchParams();

  const offerId = searchParams.get(URLSearchParameterKey.OfferId);

  const getPromotionAvailability = async (): Promise<PromotionAvailability> => {
    if (offerId) {
      return storefrontClient.getPromotionAvailability(offerId);
    }
    throw Error("useQuery should be disabled when offerId is undefined.")
  }

  return useQuery(['getPromotionAvailability', offerId], getPromotionAvailability, {
    enabled: !!offerId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (promotions: PromotionAvailability) => {
      let payload: Promotion[] = [];
      if (promotions.promotions?.length) {
        payload = [...promotions.promotions];
      }
      dispatch({type: 'setPromotions', payload })
    },
    onError: (error: any) => {
      let userError = (error instanceof Error) ? error : new Error("Unable to fetch available promotions");
      dispatch({ type: "setErrorModal", payload: {error: userError, message: "Unable to fetch available promotions"}})
      console.log('Error fetching promotions', error);
    },
  });
}
