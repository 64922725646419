import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";
import {DesignConstraints} from "@sunrun/design-tools-domain-model";

export type DesignConstraintsAction = PayloadAction<DesignConstraints, 'setDesignConstraints'>

export const selectDesignConstraints = (state: WorkspaceState) => state.designConstraints

export const designConstraintsReducer = (state: WorkspaceState, action: WorkspaceAction): DesignConstraints | undefined => {
  switch (action.type) {
    case 'setDesignConstraints': {
      return action.payload
    }
    default: {
      return state.designConstraints
    }
  }
}
