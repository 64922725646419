import { Badge, IconButton, List, Menu } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useWorkspace } from "src/hooks/useWorkspace";
import { deriveUnMetDesignStatusRequirements } from "src/features/designGuidance/deriveDesignRequirements";
import { deriveDesignWarnings } from "src/features/designGuidance/deriveDesignWarnings";
import { useEffect, useState } from "react";
import DesignStatusSection from "./DesignStatusSection";
import { DesignWarning } from "@sunrun/design-tools-domain-model";

export const DesignStatus = () => {
  const { state } = useWorkspace();
  const [badgeCount, setBadgeCount] = useState(0);
  const designRequirements = deriveUnMetDesignStatusRequirements(state);
  const designWarnings = deriveDesignWarnings(state);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let count = 0;
    count += designRequirements.length;
    designWarnings.forEach((designWarning: DesignWarning) => {
      count += designWarning?.details?.length ?? 0;
    });
    setBadgeCount(count);
  }, [designRequirements, designWarnings]);
  return (
    <>
      <IconButton
        id="design-status-button"
        color="primary"
        size="large"
        sx={{ borderRadius: "inherit", paddingX: 2 }}
        onClick={handleClick}
      >
        <Badge badgeContent={badgeCount} color="primary" overlap="circular">
          <HomeOutlinedIcon fontSize="inherit" />
        </Badge>
      </IconButton>
      <Menu
        id="design-status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <List>
          <DesignStatusSection
            title="Design Status"
            status={designRequirements.length ? "Resolve Issues" : "No Issues"}
            items={designRequirements}
          />
          {designWarnings.map((designWarning: DesignWarning) => (
            <DesignStatusSection
              key={designWarning.name}
              title={designWarning.name}
              items={designWarning.details}
            />
          ))}
        </List>
      </Menu>
    </>
  );
};
