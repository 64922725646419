import React, { useState } from "react";
import {useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {storefrontClient} from "@sunrun/design-tools-graphql-clients";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {useWorkspace} from "src/hooks/useWorkspace";
import {useSearchParams} from "react-router-dom";
import { Offer, Offers } from "@sunrun/design-tools-domain-model";
import { deriveOfferProductChanges } from "src/features/designGuidance/deriveOfferProductChanges";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey"

export const useOffer = () => {
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const {state, dispatch} = useWorkspace();
  const [searchParams] = useSearchParams();
  const offerId = searchParams.get(URLSearchParameterKey.OfferId);
  const [hasOfferLoadedOnce, setHasOfferLoadedOnce] = useState(false);

  const getOffer = async (): Promise<Offer> => {
    if (offerId) {
      return storefrontClient.getOffer(offerId);
    }
    throw Error("useQuery should be disabled when offerId is undefined.")
  }

  const query = useQuery(['getOffer', offerId], getOffer, {
    refetchOnWindowFocus: false,              // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!offerId,                   // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newOffer: Offer) => {
      dispatch({type: 'setOffer', payload: newOffer})
    },
    onError: handleError,
  });

  React.useEffect(function addLoadingScreenProcess() {
    if (query.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.OFFER,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      });
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.OFFER);
      };
    }
  }, [query.isFetching]);

  React.useEffect(function updateDesignToMatchOffer() {
    if (state.offer) {
      dispatch({type: "productToEquipmentMappingInProgress", payload: true},);
      if (hasOfferLoadedOnce) {
        dispatch({type: "updateDesignToMatchOffer"});
      }
      else {
        // only update on a reload. first update will happen on design init with maxfill.
        setHasOfferLoadedOnce(true);
      }
    }
  }, [state.offer]);

  // handle isProductToEquipmentMappingInProgress change
  React.useEffect(() => {
    // on SET, show loadingScreen
    if (state.workflowState.isProductToEquipmentMappingInProgress) {
      loadingScreenHelpers.addProcess({
        group: LoadingProcessGroups.INITIALIZE_IHD,
        name: LoadingProcessNames.PRODUCT_TO_EQUIPMENT_MAPPING,
      });
    }
    // on RESET:
    //   - clear loadingScreen
    //   - check for productToEquipmentMapping "failures" and show relevant modal
    //     - on productChange, show OfferUpdateConfirmationModal
    //     - on mapping failure (missing equipment), show errorDialog
    else {
      loadingScreenHelpers.completeProcess(LoadingProcessNames.PRODUCT_TO_EQUIPMENT_MAPPING)
      if (hasOfferLoadedOnce && state.offer && state.design && state.design.selectedEquipmentSpecificationIds) {
        // close previously open modals
        dispatch({type: "dismissOfferUpdateConfirmationModal"});
        dispatch({type: "dismissProductEquipmentMappingErrorModal"});

        const {selectedInverterSpecificationIds, selectedBatterySpecificationIds} = state.design.selectedEquipmentSpecificationIds;
        const missingInverters = selectedInverterSpecificationIds.length === 0;
        const shiftOfferLine = Offers.getShiftOfferLine(state.offer);
        const backupOfferLine = Offers.getBackupOfferLine(state.offer);
        const offerHasBatteryLine = !!shiftOfferLine || !!backupOfferLine;
        const missingBatteries = offerHasBatteryLine && selectedBatterySpecificationIds.length === 0;
        const productChanges = deriveOfferProductChanges(state);
        if (missingInverters || missingBatteries) {
          dispatch({type: "displayProductEquipmentMappingErrorModal"});
        }
        else if (productChanges.solar.newVariantId || productChanges.backup.newVariantId) {
          dispatch({type: "displayOfferUpdateConfirmationModal"});
        }
      }
    }
  }, [state.workflowState.isProductToEquipmentMappingInProgress])

  // reset productToEquipmentMappingInProgress when selectedEquipmentSpecs are received
  React.useEffect(() => {
    if (hasOfferLoadedOnce && state.offer && state.design && state.design.selectedEquipmentSpecificationIds) {
      dispatch({type: "productToEquipmentMappingInProgress", payload: false});
    }
  }, [hasOfferLoadedOnce, state.design?.selectedEquipmentSpecificationIds])

  const refetchOffer = async () => {
    await query.refetch();
  };

  return {
    refetchOffer,
  }
}
