import {Offer} from "@sunrun/design-tools-domain-model";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";

export type OfferAction = PayloadAction<Offer, 'setOffer'>;

export const selectOffer = (state: WorkspaceState) => state.offer

export const offerReducer = (state: WorkspaceState, action: WorkspaceAction): Offer | undefined => {
  switch (action.type) {
    case 'setOffer': {
      console.log(`Offer added to state: `, action.payload); // TODO for confirmation; remove once we add implementation.
      return action.payload;
    }
    default: {
      return state.offer;
    }
  }
};
