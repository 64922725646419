import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FinancialProductsRow } from "./FinancialProductsRow";
import {DesignConstraintType, PvProduct} from "@sunrun/design-tools-domain-model";

const products = [
  PvProduct.Monthly,
  PvProduct.Prepaid,
  PvProduct.Cash,
  PvProduct.Loan
];

const designConstraintTypes = [
  DesignConstraintType.MinSunHours,
  DesignConstraintType.MaxOffsetPercent,
  DesignConstraintType.MinDcSystemSizeKw,
  DesignConstraintType.MaxDcSystemSizeKw,
  DesignConstraintType.MaxAcSystemSizeKw,
  DesignConstraintType.MaxCecAcSystemSizeKw
];

export const FinancialProductsTable = ({fullscreen}:{fullscreen: boolean | undefined}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {products.map((product) => (
              <TableCell key={product} sx={{borderLeft: "1px solid rgba(224, 224, 224, 1)", px: 0}}>
                <Box display="flex" justifyContent="center">
                  <Typography color="primary" variant="body2">
                    {product}
                  </Typography>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {designConstraintTypes.map((designConstraint) => (
            <FinancialProductsRow key={designConstraint} designConstraint={designConstraint} fullscreen={fullscreen}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
