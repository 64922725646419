import { Box, Grid } from "@mui/material";
import { Offers } from "@sunrun/design-tools-domain-model";
import { SrButton } from "@sunrun/experience-ui-components";
import StatDisplay, { StatDisplayProps } from "src/components/StatDisplay";
import { postIFrameMessage } from "src/hooks/useIFrameHost";
import { useWorkspace } from "src/hooks/useWorkspace";
import { IFrameEventType, SourceApp } from "src/types/IFrame";
import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import { EvChargerPromotion } from "./EvChargerPromotion";
import { isEvChargerPromotionApplied, isEvChargerPromotionAvailable, isEligibleForEvChargerPromotion } from "src/features/promotions/evCharger";
import { useAddEvCharger } from "src/hooks/useAddEvCharger";

export const ProductsDetails = () => {
  const { state } = useWorkspace();
  const { addEvCharger } = useAddEvCharger();
  const { customer, design, offer, productionSimulation } = state;

  if (!offer) return null;

  // TODO: Extract logic?, copy-pasted similarly in a few places
  const designFinalized = deriveIsDesignFinalized(state)
  const simulationToUse = designFinalized ? productionSimulation : deriveLowResProductionSimulation(state);

  const onUpdateProducts = () => {
    postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, {
      designId: design!.id,
      offerId: offer.id,
    });
  };

  const productsList: StatDisplayProps[] = [
    {
      title: "Bundle",
      stat: Offers.getBundleTitle(offer),
    },
    {
      title: "Module Type",
      stat: Offers.getModuleType(offer),
    },
    {
      title: "Inverter Type",
      stat: Offers.getInverterType(offer),
    },
    {
      title: "Shift",
      stat: Offers.getShiftOfferLine(offer) ? "Included" : undefined,
    },
    {
      title: "Backup Type",
      stat: Offers.getBatteryType(offer),
    },
    {
      title: "Backup Tier",
      stat: Offers.getBackupTier(offer),
    },
    {
      title: "EV Charging",
      stat: Offers.getEvChargingOfferLine(offer)?.quantity,
    },
    {
      title: "Power Management",
      stat: Offers.getPowerManagementOfferLine(offer)?.variant?.title,
    },
  ];

  const filteredProductsList = productsList.filter(
    (statDisplay) => statDisplay.stat !== undefined
  );

  return (
    <>
      <Grid container spacing={2}>
        {filteredProductsList.map((statDisplay) => (
          <Grid item xs={6} key={statDisplay.title}>
            <StatDisplay {...statDisplay} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 2, maxWidth: "300px" }}>
        <SrButton type="secondary" size="sm" onPress={onUpdateProducts}>
          Update Products
        </SrButton>
      </Box>
      {isEvChargerPromotionAvailable(state) &&
        <Box mt={2}>
          <EvChargerPromotion
            isEligible={isEligibleForEvChargerPromotion(state)}
            isApplied = {isEvChargerPromotionApplied(state)}
            onCallToActionClick={async ( event: React.MouseEvent<HTMLButtonElement> ) => {
              const {success} = await addEvCharger()
              if (success) {
                postIFrameMessage(IFrameEventType.OFFER_UPDATED, {offerId: offer.id, source: SourceApp.IHD})
              }
            }}
            annualProduction={simulationToUse?.annualProductionKwh ?? 0}
            annualUsage={customer?.annualUsagekWh ?? 0}
          />
        </Box>
      }
    </>
  );
};
