import { Box, styled } from "@mui/material";

type ProgressBarProps = {
  value: number;
  maxValue: number;
  minValue?: number;
  targetValue?: number | undefined;
}

const ProgressBar = ({value, maxValue, minValue = 0, targetValue}: ProgressBarProps) => {
  const adjustedMaxValue = maxValue - minValue;
  const adjustedValue = value - minValue;
  const adjustedTargetValue = targetValue !== undefined ? targetValue - minValue : undefined;

  const progressPercent = adjustedValue / adjustedMaxValue;
  const targetPercent = adjustedTargetValue !== undefined ? adjustedTargetValue / adjustedMaxValue : undefined;

  const ProgressBarContainer = styled(Box)({
    width: "100%",
    maxWidth: "74px",
    minWidth: "30px",
    height: "36px",
    position: "relative"
  });
  const ProgressBar = styled(Box)({
    width: "100%",
    height: "90%",
    background: "#D4D5DA",
    borderRadius: "4px",
    position: "absolute",
    overflow: "hidden",
    margin: "auto",
    top: 0,
    bottom: 0
  });
  const Progress = styled(Box)({
    background: "linear-gradient(270deg, #BBD6FF 0.93%, #A1C7FF 52.27%, #83B5FF 100%)",
    width: `${progressPercent * 100}%`,
    maxWidth: "100%",
    height: "100%",
    position: "absolute",
  });
  const ProgressTargetLine = styled(Box)({
    width: targetPercent !== undefined ? `${targetPercent * 100}%` : 0,
    maxWidth: "100%",
    height: "100%",
    borderRight: "1.6px dashed #49517D",
    position: "absolute"
  })

  return(
    <ProgressBarContainer>
      <ProgressBar>
        <Progress/>
      </ProgressBar>
      {targetPercent !== undefined && <ProgressTargetLine />}
    </ProgressBarContainer>
  )
}

export default ProgressBar;