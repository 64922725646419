"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteParam = exports.getParam = exports.putParam = exports.ServiceType = void 0;
const client_ssm_1 = require("@aws-sdk/client-ssm");
const ssmClient = new client_ssm_1.SSMClient({ region: 'us-west-2' });
var ServiceType;
(function (ServiceType) {
    ServiceType["AwsSqsQueueUrl"] = "awsSqsQueueUrl";
    ServiceType["AwsLambdaFunctionArn"] = "awsLambdaFunctionArn";
    ServiceType["AwsAppSyncGraphQlApiUrl"] = "awsAppSyncGraphQlApiUrl";
    ServiceType["AwsAppSyncGraphQlApiKey"] = "awsAppSyncGraphQlApiKey";
    ServiceType["AwsSnsTopicArn"] = "awsSnsTopicArn";
    ServiceType["AwsS3BucketName"] = "awsS3BucketName";
})(ServiceType = exports.ServiceType || (exports.ServiceType = {}));
const putParam = (key, value, encrypted = false) => __awaiter(void 0, void 0, void 0, function* () {
    console.log(`Storing parameter for key "${getKeyAsStr(key)}"`);
    yield ssmClient.send(new client_ssm_1.PutParameterCommand({
        Name: getKeyAsStr(key),
        Value: value,
        DataType: 'text',
        Type: encrypted ? 'SecureString' : 'String',
        Overwrite: true
    }));
});
exports.putParam = putParam;
const getParam = (key) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    // TODO: support bulk key lookups
    try {
        console.log(`Retrieving parameter with key: ${getKeyAsStr(key)}`);
        const resp = yield ssmClient.send(new client_ssm_1.GetParameterCommand({
            Name: getKeyAsStr(key),
            WithDecryption: true
        }));
        return (_a = resp === null || resp === void 0 ? void 0 : resp.Parameter) === null || _a === void 0 ? void 0 : _a.Value;
    }
    catch (err) {
        return undefined;
    }
});
exports.getParam = getParam;
/**
 * This is idempotent.
 */
const deleteParam = (key) => __awaiter(void 0, void 0, void 0, function* () {
    if (!(yield (0, exports.getParam)(key))) {
        console.log(`Parameter does not exist for the given inputs: ${JSON.stringify(key)}. Skipping deletion...`);
        return;
    }
    yield ssmClient.send(new client_ssm_1.DeleteParameterCommand({
        Name: getKeyAsStr(key)
    }));
});
exports.deleteParam = deleteParam;
// TODO: do we like this hierarchy?
const getKeyAsStr = (key) => {
    return `/${key.platformName}/${key.appName}/${key.serviceType}/${key.serviceName}/${key.envName}`;
};
