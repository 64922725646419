import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {PayloadAction} from "../../types/state-management/action";

export type WorkflowState = {
  isProductToEquipmentMappingInProgress: boolean
  isSimulateDesignInProgress: boolean
  isLegacyFinalizeDesignInProgress: boolean
}

export type WorkflowStateAction =
  | PayloadAction<boolean, 'productToEquipmentMappingInProgress'>
  | PayloadAction<boolean, 'simulateDesignInProgress'>
  | PayloadAction<boolean, 'legacyFinalizeDesignInProgress'>

export const workflowStateReducer = (state: WorkspaceState, action: WorkspaceAction): WorkflowState => {
  switch (action.type) {
    case 'productToEquipmentMappingInProgress': {
      if (state.workflowState.isProductToEquipmentMappingInProgress === action.payload) {
        return state.workflowState
      }
      return produce(state.workflowState, draft => {
        draft.isProductToEquipmentMappingInProgress = action.payload
      })
    }
    case 'simulateDesignInProgress': {
      if (state.workflowState.isSimulateDesignInProgress === action.payload) {
        return state.workflowState
      }
      return produce(state.workflowState, draft => {
        draft.isSimulateDesignInProgress = action.payload
      })
    }
    case "legacyFinalizeDesignInProgress": {
      if (state.workflowState.isLegacyFinalizeDesignInProgress === action.payload) {
        return state.workflowState
      }
      return produce(state.workflowState, draft => {
        draft.isLegacyFinalizeDesignInProgress = action.payload
      })
    }
    default: {
      return state.workflowState
    }
  }
}
