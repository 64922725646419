import {PayloadAction} from "../../types/state-management/action";
import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {MostRecentSignedDesign} from "@sunrun/design-tools-domain-model";


export type MostRecentSignedDesignAction =
  | PayloadAction<string, 'setSignedRootId'>
  | PayloadAction<string, 'setEffectiveDate'>
  | PayloadAction<string, 'setSignedRootProductType'>

export const mostRecentSignedDesignInitialState: MostRecentSignedDesign = {
  projectHasSignedDesign: false,
  signedRootId: undefined,
  effectiveDate: undefined,
  productType: undefined,
}

export const mostRecentSignedDesignReducer = (state: WorkspaceState, action: WorkspaceAction): MostRecentSignedDesign => {
  switch (action.type) {
    case "setSignedRootId": {
      if (!action.payload) {
        return mostRecentSignedDesignInitialState
      }
      return {
        ...state.mostRecentSignedDesign,
        projectHasSignedDesign: true,
        signedRootId: action.payload,
      }
    }

    case "setEffectiveDate": {
      return {
        ...state.mostRecentSignedDesign,
        effectiveDate: action.payload,
      }
    }

    case "setSignedRootProductType": {
      return {
        ...state.mostRecentSignedDesign,
        productType: action.payload,
      }
    }

    default: {
      return state.mostRecentSignedDesign
    }
  }
}
