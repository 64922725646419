import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";
import {GeoRaster} from "@sunrun/design-tools-domain-model";

export type GeoRasterAction = PayloadAction<GeoRaster, 'setGeoRaster'>

export const geoRasterReducer = (state: WorkspaceState, action: WorkspaceAction): GeoRaster | undefined => {
  switch (action.type) {
    case 'setGeoRaster': {
      return action.payload
    }
    default: {
      return state.geoRaster
    }
  }
}
