"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceRegistry = exports.ServiceRegistryClass = void 0;
const fs_1 = require("fs");
const awsAppSync_1 = require("./awsAppSync");
const design_tools_resource_names_1 = require("@sunrun/design-tools-resource-names");
const ParameterStore = __importStar(require("./parameterStore"));
const parameterStore_1 = require("./parameterStore");
const awsSns_1 = require("./awsSns");
const awsSqs_1 = require("./awsSqs");
const awsLambda_1 = require("./awsLambda");
const awsS3_1 = require("./awsS3");
/**
 * A Service Registry, backed by AWS AppConfig, that can be used by a variety clients:
 *  - Spokes (i.e. Lightmile-Adapter)
 *  - Apps (i.e. iHD)
 *  - Hub (i.e. Process Manager)
 *  - cloud test harness
 *  - scripts in a local or CI context (i.e. deploy)
 */
class ServiceRegistryClass {
    constructor(platformName) {
        this.platformName = platformName;
    }
    registerServices(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const application = yield parseApplication(options);
            console.log(`Registering services in the the following application: ${JSON.stringify(application)}`);
            yield this.registerService(application.name, parameterStore_1.ServiceType.AwsLambdaFunctionArn, application.services.functions);
            yield this.registerService(application.name, parameterStore_1.ServiceType.AwsAppSyncGraphQlApiUrl, application.services.appSyncApis);
            yield this.registerService(application.name, parameterStore_1.ServiceType.AwsAppSyncGraphQlApiKey, application.services.appSyncApis);
            yield this.registerService(application.name, parameterStore_1.ServiceType.AwsSnsTopicArn, application.services.snsTopics);
            yield this.registerService(application.name, parameterStore_1.ServiceType.AwsSqsQueueUrl, application.services.sqsQueues);
            yield this.registerService(application.name, parameterStore_1.ServiceType.AwsS3BucketName, application.services.s3Buckets);
        });
    }
    deregisterServices(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const application = yield parseApplication(options);
            console.log(`Deregistering services in the following application: ${JSON.stringify(application)}`);
            yield this.deregisterService(application.name, parameterStore_1.ServiceType.AwsLambdaFunctionArn, application.services.functions);
            yield this.deregisterService(application.name, parameterStore_1.ServiceType.AwsAppSyncGraphQlApiUrl, application.services.appSyncApis);
            yield this.deregisterService(application.name, parameterStore_1.ServiceType.AwsAppSyncGraphQlApiKey, application.services.appSyncApis);
            yield this.deregisterService(application.name, parameterStore_1.ServiceType.AwsSnsTopicArn, application.services.snsTopics);
            yield this.deregisterService(application.name, parameterStore_1.ServiceType.AwsSqsQueueUrl, application.services.sqsQueues);
            yield this.deregisterService(application.name, parameterStore_1.ServiceType.AwsS3BucketName, application.services.s3Buckets);
        });
    }
    resolveSqsQueueUrl(appName, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.resolveServiceValue(appName, parameterStore_1.ServiceType.AwsSqsQueueUrl, serviceName, envOverride);
        });
    }
    resolveLambdaFunctionArn(appName, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.resolveServiceValue(appName, parameterStore_1.ServiceType.AwsLambdaFunctionArn, serviceName, envOverride);
        });
    }
    resolveAppSyncGraphQlUrl(appName, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.resolveServiceValue(appName, parameterStore_1.ServiceType.AwsAppSyncGraphQlApiUrl, serviceName, envOverride);
        });
    }
    resolveAppSyncGraphQlApiKey(appName, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.resolveServiceValue(appName, parameterStore_1.ServiceType.AwsAppSyncGraphQlApiKey, serviceName, envOverride);
        });
    }
    resolveSnsTopicArn(appName, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.resolveServiceValue(appName, parameterStore_1.ServiceType.AwsSnsTopicArn, serviceName, envOverride);
        });
    }
    resolveS3BucketName(appName, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.resolveServiceValue(appName, parameterStore_1.ServiceType.AwsS3BucketName, serviceName, envOverride);
        });
    }
    resolveServiceValue(appName, serviceType, serviceName, envOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`Resolving service value for the following inputs: ${appName}, ${serviceType}, ${serviceName}, ${envOverride}`);
            if (envOverride) {
                const foundIt = yield ParameterStore.getParam({
                    platformName: this.platformName,
                    appName,
                    serviceType,
                    serviceName,
                    envName: envOverride
                });
                if (foundIt) {
                    console.log(`Found env override: ${foundIt}`);
                    return foundIt;
                }
                console.log(`Could not find service with envName "${envOverride}". Defaulting to the "default" env.`);
            }
            const defaultEnv = yield ParameterStore.getParam({
                platformName: this.platformName,
                appName,
                serviceType,
                serviceName,
                envName: 'default'
            });
            if (!defaultEnv) {
                throw new Error(`Could not find the default environment for the following resource: ${appName}-${serviceName}`);
            }
            return defaultEnv;
        });
    }
    registerService(appName, type, service) {
        return __awaiter(this, void 0, void 0, function* () {
            if (service) {
                for (const entry of Object.entries(service)) {
                    const serviceName = entry[1];
                    console.log(`Registering service: ${serviceName}`);
                    yield this.registerTheEnvironmentsOfTheService(serviceName, type, appName);
                }
            }
        });
    }
    resolveValue(serviceName, env, type, appName) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (type) {
                case parameterStore_1.ServiceType.AwsSqsQueueUrl:
                    const queueResourceName = (0, design_tools_resource_names_1.constructAwsSqsQueueResourceName)({ envName: env.name, appName, serviceName });
                    return yield (0, awsSqs_1.findSqsQueueUrlByName)(queueResourceName);
                case parameterStore_1.ServiceType.AwsLambdaFunctionArn:
                    const lambdaResourceName = (0, design_tools_resource_names_1.constructAwsLambdaFunctionResourceName)({ envName: env.name, appName, serviceName });
                    return yield (0, awsLambda_1.findFunctionArnByName)(lambdaResourceName);
                case parameterStore_1.ServiceType.AwsAppSyncGraphQlApiUrl:
                    return yield (0, awsAppSync_1.getAppSyncApiGraphqlUrl)(`${serviceName}-${env.getAmplifyFriendlyBackendName()}`);
                case parameterStore_1.ServiceType.AwsAppSyncGraphQlApiKey:
                    return yield (0, awsAppSync_1.getAppSyncApiGraphqlApiKey)(`${serviceName}-${env.getAmplifyFriendlyBackendName()}`);
                case parameterStore_1.ServiceType.AwsSnsTopicArn:
                    return yield (0, awsSns_1.findSnsTopicArnByName)((0, design_tools_resource_names_1.constructAwsSnsTopicResourceName)({ envName: env.name, appName, serviceName }));
                case parameterStore_1.ServiceType.AwsS3BucketName:
                    // CDK also puts a bunch of random characters at the end of the bucket name, hence the prefix.
                    // TODO: maybe we should not use the CDK generated name and instead append the AWS account ID to the end of the bucket name to make it globally unique (solves PR preview issue in multiple accounts)
                    const cdkGenereatedS3BucketNamePrefix = `${env.name}-${serviceName}-${env.name}${serviceName}`;
                    return yield (0, awsS3_1.findBucketNameByPrefix)(cdkGenereatedS3BucketNamePrefix);
                default:
                    throw new Error(`Unhandled service type: ${type}`);
            }
        });
    }
    registerTheEnvironmentsOfTheService(serviceName, serviceType, appName) {
        return __awaiter(this, void 0, void 0, function* () {
            const inferredEnv = design_tools_resource_names_1.Env.nodejs();
            yield ParameterStore.putParam({
                platformName: this.platformName,
                appName,
                serviceType,
                serviceName,
                envName: inferredEnv.type === design_tools_resource_names_1.EnvType.LongLived ? 'default' : inferredEnv.name
            }, yield this.resolveValue(serviceName, inferredEnv, serviceType, appName), serviceType === parameterStore_1.ServiceType.AwsAppSyncGraphQlApiKey);
        });
    }
    deregisterTheEnvironmentsOfTheService(appName, serviceType, serviceName) {
        return __awaiter(this, void 0, void 0, function* () {
            const inferredEnv = design_tools_resource_names_1.Env.nodejs();
            yield ParameterStore.deleteParam({
                platformName: this.platformName,
                appName,
                serviceType,
                serviceName,
                envName: inferredEnv.type === design_tools_resource_names_1.EnvType.LongLived ? 'default' : inferredEnv.name
            });
        });
    }
    deregisterService(appName, serviceType, service) {
        return __awaiter(this, void 0, void 0, function* () {
            if (service) {
                for (const entry of Object.entries(service)) {
                    const name = entry[1];
                    console.log(`Deregistering service: ${name}`);
                    yield this.deregisterTheEnvironmentsOfTheService(appName, serviceType, name);
                }
            }
        });
    }
}
exports.ServiceRegistryClass = ServiceRegistryClass;
const requireField = (app, fieldName) => {
    if (!app.hasOwnProperty(fieldName)) {
        throw new Error(`Application object does not have expected field "${fieldName}". Here is the appication object: ${JSON.stringify(app)}.`);
    }
};
const parseApplication = (options) => __awaiter(void 0, void 0, void 0, function* () {
    if (options.application) {
        return options.application;
    }
    else if (options.filePath) {
        const file = (0, fs_1.readFileSync)(options.filePath, 'utf-8');
        if (!file) {
            throw new Error(`Could not file with name: ${options.filePath}`);
        }
        const obj = JSON.parse(file);
        // TODO: more declarative way of doing this?
        requireField(obj, 'name');
        requireField(obj, 'services');
        requireField(obj, 'dependencies');
        return obj;
    }
    else {
        throw new Error('Pass in an option to register your application.');
    }
});
exports.ServiceRegistry = new ServiceRegistryClass('designTools');
