import {
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

export const DetailsSection = ({ title, children }: { title: string, children: ReactNode }) => {
  return (
    <>
      <Card
        sx={{ backgroundColor: "rgba(31, 38, 71, 0.05)", height: "100%" }}
        elevation={0}
      >
        <CardContent>
          <Typography color="primary">{title}</Typography>
        </CardContent>
        <Divider variant="middle" />
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </>
  );
};
