import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";
import { isMacOs } from "react-device-detect";

type HotkeyDescriptionsModalProps = {
  open: boolean;
  onClose: () => void;
};

export const HotkeyDescriptionsModal = ({
  open,
  onClose,
}: HotkeyDescriptionsModalProps) => {
  const getModifierHotkey = (hotkey: string) => {
    const modifierString = isMacOs ? "⌘" : "Ctrl+";
    return modifierString + hotkey;
  };

  const hotkeys = [
    { label: "Change Default Module Orientation", key: getModifierHotkey("R") },
    { label: "Delete Selected", key: "Delete" },
    { label: "Rotate Selected Module(s)", key: "R" },
    { label: "Toggle Magnetic Snap", key: getModifierHotkey("M") },
    { label: "Toggle Magnetic Slide", key: getModifierHotkey("L") },
    { label: "Cancel Selection / Close Dialog", key: "Esc" },
    { label: "Fill All Roof Faces", key: getModifierHotkey("F") },
  ];
  return (
    <ModalLayout open={open} title="Hotkey Descriptions" onClose={onClose}>
      <TableContainer>
        <Table>
          <TableBody>
            {hotkeys.map((hotkey) => (
              <TableRow key={hotkey.label}>
                <TableCell>
                  <Typography color="primary" variant="body2">{hotkey.label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{hotkey.key}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalLayout>
  );
};
