import {createSelector} from "reselect";
import { selectSiteModel } from "../siteModel/siteModelSlice";
import {selectDesign} from "src/features/design/designSlice"
import {selectIsLeafletDragging} from "src/features/moduleDrag/moduleDragSlice";
import {StaticReferences} from "@sunrun/design-tools-domain-model";
import {selectIsModuleNudging} from "../moduleNudge/moduleNudgeSlice";

export const deriveIslandedModules = createSelector(
  [selectSiteModel, selectDesign, selectIsLeafletDragging, selectIsModuleNudging],
  (siteModel, design, isLeafletDragging, isModuleNudging): Set<string> => {
    if (isLeafletDragging || isModuleNudging) {
      // Only derive when drag/nudge completes
      return deriveIslandedModules.lastResult()
    }
    if (siteModel && design) {
      return design.findIslandedModules(siteModel);
    }
    return StaticReferences.EMPTY_SET;
  }
);