import type { ProductAvailability } from "@sunrun/design-tools-domain-model";
import type { PayloadAction } from "src/types/state-management/action";
import type { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";

export type ProductAvailabilityAction = PayloadAction<ProductAvailability, "setProductAvailability">;

export const productAvailabilityReducer = (state: WorkspaceState, action: WorkspaceAction): ProductAvailability | undefined => {
  switch (action.type) {
    case "setProductAvailability": {
      return action.payload;
    }
    default: {
      return state.productAvailability;
    }
  }
};
