import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";
import {SiteModel} from "@sunrun/design-tools-domain-model";

export type SiteModelAction = PayloadAction<SiteModel, 'setSiteModel'>

export const selectSiteModel = (state: WorkspaceState) => state.siteModel

export const siteModelReducer = (state: WorkspaceState, action: WorkspaceAction): SiteModel | undefined => {
  switch (action.type) {
    case 'setSiteModel': {
      return action.payload
    }
    default: {
      return state.siteModel
    }
  }
}
