import React from "react";
import {useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {
  GeoRaster,
  GeoRasterFormat,
  GeoRasterOrientation,
  GeoRasterType,
  ProviderName
} from "@sunrun/design-tools-domain-model"
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {mapGatewayClient} from "@sunrun/design-tools-graphql-clients";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {useWorkspace} from "src/hooks/useWorkspace";


type UseGeoRasterProps = {
  geoRasterId?: string,
}

export const useGeoRaster = ({geoRasterId}: UseGeoRasterProps) => {
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const {dispatch} = useWorkspace()

  const getGeoRaster = async (): Promise<GeoRaster> => {
    if (geoRasterId) {
      if (geoRasterId === "testFixture") { // GeoRasters come from outside of Design Tools, so we need a test fixture here
        return geoRasterFixture
      } else {
        return mapGatewayClient.get(geoRasterId)
      }
    }
    throw Error("useQuery should be disabled when geoRasterId is undefined.")
  }

  useQuery(['getGeoRaster', geoRasterId], getGeoRaster, {
    refetchOnWindowFocus: false,              // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!geoRasterId,                   // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newGeoRaster: GeoRaster) => {
      dispatch({type: 'setGeoRaster', payload: newGeoRaster})
    },
    onError: handleError,
  })

  React.useEffect(function addLoadingScreenProcess() {
    if (geoRasterId) {  // begin load when id is set and let leaflet event handlers end load
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.AERIAL_IMAGERY,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      })
    }
  }, [geoRasterId])
}

const geoRasterFixture = {
  id: 'testFixture',
  metadata: {
    type: GeoRasterType.RGB,
    format: GeoRasterFormat.PNG,
    bbox: {
      southWest: {
        latitude: 38.99140033990283,
        longitude: -104.72677853779714,
      },
      northEast: {
        latitude: 38.99229966009717,
        longitude: -104.72562146220287,
      },
    },
    bboxUtm: {
      southWest: {
        easting: 523661.6079711637,
        northing: 4315857.767774578,
      }, northEast: {
        easting: 523761.5128317635,
        northing: 4315957.867703204,
      },
      epsgCode: 32613,
    },
    centroid: {
      latitude: 38.99185,
      longitude: -104.7262,
    },
    providerName: ProviderName.GoogleSunroof,
    orientation: GeoRasterOrientation.Orthogonal,
    captureDate: '2014-06-16',
    retrievalDate: '2021-06-10',
    width: 997,
    height: 999,
  },
  uri: 'https://design-tools-public.s3.us-west-2.amazonaws.com/map-images/GoogleSunroof-2014-06-16-RGB-Orthogonal-60c2a100fe9a5f27b1ff2b0d.png',
  siblings: [
    {
      siblingId: '60c2a100fe9a5f27b1ff2b0e',
      type: GeoRasterType.DSM,
    }
  ],
}
