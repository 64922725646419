import { Box, Fab, Stack, useMediaQuery, useTheme } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useWorkspace } from "src/hooks/useWorkspace";
import { LayerControl } from "../MapControl/LayerControl";
import { ModuleAdjustmentActionBar } from "./components/ModuleAdjustmentActionBar";
import { Footer } from "./components/Footer";
import { WorkflowButtonProps } from "../Toolbar";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";

export const FloatingActionButtons = (WorkflowButtonProps: WorkflowButtonProps) => {
  const theme = useTheme<DesignToolsTheme>()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useWorkspace();
  return (
    <Box
      height={0}
      overflow="visible"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          pb: 1,
          px: isXs ? 2 : 0
        }}
      >
        <Box width="56px"/>
        <ModuleAdjustmentActionBar />
        <Stack spacing={2}>
          <Fab
            color="secondary"
            aria-label="edit"
            onClick={() => dispatch({ type: "setIsFinancialProductsOpen", payload: true })}
          >
            <TaskAltIcon />
          </Fab>
          <LayerControl />
          <Box height="112px"></Box> {/* TODO: Fix this placeholder hack for the ZoomControl component. */}
        </Stack>
        
      </Box>
      {isXs && <Footer {...WorkflowButtonProps}/>}
    </Box>
  );
};
