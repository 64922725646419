import {LoadedWorkspaceState, WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";
import {polygonCentroid, positionToVector2D, Vector2D} from "@sunrun/design-tools-geometry";
import {Design, Module, ModuleCollisionChecker, Offer, SiteModel} from "@sunrun/design-tools-domain-model";
import {EmptyAction, PayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {produce} from "immer";
import * as FullStory from "@fullstory/browser";
import {ModuleDrag} from "../moduleDrag/moduleDragSlice";

export type DesignAction =
  | PayloadAction<Design, 'setDesign'>
  | PayloadAction<Design, 'addModule'>
  | PayloadAction<WorkspaceEvent, 'translateModules'>
  | PayloadAction<WorkspaceEvent, 'translateModulesConstrainedByRoofEdge'>
  | PayloadAction<WorkspaceEvent, 'commitModuleTranslation'>
  | PayloadAction<{module: Module, delta: Vector2D}, 'nudgeModules'>
  | PayloadAction<WorkspaceEvent, 'undoModuleNudge'>
  | PayloadAction<RoofFaceId, 'centerFillOneRoofFace'>
  | EmptyAction<'designInitiatedInOfferBuilder'>
  | EmptyAction<'centerFillAllRoofFaces'>
  | EmptyAction<'undoModuleTranslation'>
  | EmptyAction<'removeModules'>
  | EmptyAction<'rotateModules'>
  | EmptyAction<'fillRoofFaceAroundModule'>
  | EmptyAction<'updateDesignToMatchOffer'>
  | PayloadAction<Design, 'resolveAutosave'>
  | PayloadAction<Design, 'resolvePrepareDesignForOverride'>


export type RoofFaceId = {
  roofFaceId: string
}

export const selectDesign = (state: WorkspaceState) => state.design
export const selectDesignIsUsingMicroInverter = (state: WorkspaceState) => state.design?.usingMicroInverter

export const designReducer = (state: WorkspaceState, action: WorkspaceAction): Design | undefined => {

  switch (action.type) {
    case "setDesign": {
      return action.payload
    }

    case "removeModules": {
      if (state.design && state.moduleSelection.selectedModuleIds.size > 0) {
        return state.design!.deleteModules(state.moduleSelection.selectedModuleIds)
        // We'd like to clear selection automatically when we delete selected modules
        // but we've modeled selection as a separate slice of state from Design so we can't
        // do it from here or (better) from inside design.deleteModules. This is a leak
        // in our encapsulation. TODO: make module selection ephemeral design state
      }
      return state.design
    }

    case "rotateModules": {
      const {design, siteModel, moduleSelection} = state as LoadedWorkspaceState;
      if (design && siteModel && moduleSelection.selectedModuleIds.size > 0) {
        return design.rotateModules(state.moduleSelection.selectedModuleIds, state.siteModel!)
      }
      return state.design
    }

    case "translateModules": {
      const {design, siteModel, moduleSelection, moduleDrag, settings} = state;
      if (design && siteModel && moduleDrag && moduleDrag.draggedModuleId) {
        const module = design.getModuleById(moduleDrag.draggedModuleId)
        if (!module) return state.design
        const deltaDrag = calculateDragDelta(module, moduleDrag);
        const roofFace = siteModel.getRoofFaceById(module.properties.roofFaceId)
        return design.translateModules(
          moduleSelection.selectedModuleIds,
          roofFace!,
          module.id,
          deltaDrag,
          settings.isMagneticSnapEnabled
        )
      }
      return state.design
    }

    case "translateModulesConstrainedByRoofEdge": {
      const {design, siteModel, moduleSelection, moduleDrag, settings} = state;
      if (design && siteModel && moduleDrag && moduleDrag.draggedModuleId) {
        const module = design.getModuleById(moduleDrag.draggedModuleId)
        if (!module) return state.design
        const deltaDrag = calculateDragDelta(module, moduleDrag);
        return design.attemptToTranslateModules(
          moduleSelection.selectedModuleIds,
          siteModel,
          moduleDrag.draggedModuleId,
          deltaDrag,
          settings.isMagneticSnapEnabled)
      }
      return state.design
    }

    case "commitModuleTranslation": {
      const {design, siteModel} = state as LoadedWorkspaceState;
      if (design && siteModel) {
        return design.finishTranslation()
      }
      return state.design // bail
    }

    case "undoModuleTranslation": {
      const {design, siteModel, moduleDrag, moduleSelection} = state as LoadedWorkspaceState;
      if (design && siteModel && moduleDrag) {
        return design.undoTranslation(
          moduleSelection.selectedModuleIds,
          siteModel,
          moduleDrag.draggedModuleId!,
          moduleDrag.originalDraggedModuleCenter!
        )
      }
      return state.design // bail
    }

    case "nudgeModules": {
      const {design, siteModel, moduleSelection} = state as LoadedWorkspaceState;
      if (design && siteModel) {
        const {module, delta} = action.payload
        const roofFace = siteModel.getRoofFaceById(module.properties.roofFaceId)
        let selectedModules = Array.from(moduleSelection.selectedModuleIds).map(id => design.getModuleById(id)!)
        const initiallyCollidingModules = ModuleCollisionChecker.checkModulesAreWithinValidRoofSpace(selectedModules, siteModel, true);
        const newDesign = design.translateModules(
          moduleSelection.selectedModuleIds,
          roofFace!,
          module.id,
          delta,
          false).finishTranslation();
        if (initiallyCollidingModules.size > 0) {
          // if the selected modules were in a collided state we want the nudge to go ahead so the user can fix that
          return newDesign
        } else {
          // If they weren't colliding initially we want to prevent them from nudging into a collided state, at least
          // from going off the roof. This logic does allow module-to-module collision so the user has the freedom to
          // nudge modules through tight spots on their way to the desired position
          selectedModules = Array.from(moduleSelection.selectedModuleIds).map(id => newDesign.getModuleById(id)!)
          const siteModelCollisions = ModuleCollisionChecker.checkModulesAreWithinValidRoofSpace(selectedModules, siteModel, true)
          if (siteModelCollisions.size === 0) {
            return newDesign
          }
        }
      }
      return design // bail
    }

    case "undoModuleNudge": {
      const {design, siteModel, moduleNudge, moduleSelection} = state as LoadedWorkspaceState;
      if (design && siteModel && moduleNudge && moduleNudge.originalNudgedModuleCenter) {
        const originalModuleCenter = positionToVector2D(moduleNudge.originalNudgedModuleCenter!)
        return design.undoTranslation(
          moduleSelection.selectedModuleIds,
          siteModel,
          moduleNudge.nudgedModuleId!,
          originalModuleCenter
        ).finishTranslation()
      }
      return state.design // bail
    }

    case "centerFillAllRoofFaces": {
      // forces centerfill (removes existing modules! use at discretion!)
      const {design, siteModel, customer, solarResource, designConstraints} = state;
      return produce(design, draft => {
        if (design && siteModel && customer && solarResource) {
          const allModules = new Set<string>(design.modules.map(m => m.id))
          const emptyDesign = design.deleteModules(allModules);
          const customerUsageKwh = customer.annualUsagekWh || 10000
          const maxOffsetPercent = designConstraints?.defaultConstraints?.maxOffsetPercentConstraint?.value || 0
          return emptyDesign.attemptToFillAllRoofFacesFromCenter(
            siteModel,
            solarResource,
            state.settings.moduleOrientation,
            customerUsageKwh,
            maxOffsetPercent,
            state.settings.isMagneticSnapEnabled
          );
        }
      });
    }

    case "centerFillOneRoofFace": {
      return produce(state.design, draft => {
        const roofFace = state.siteModel!.getRoofFaceById(action.payload.roofFaceId)!
        if (state.design && state.siteModel && (state.design.getModulesOnRoofFace(roofFace.id).length == 0) && roofFace.properties.usable) {
          return state.design.attemptToFillRoofFaceFromCenter(
            roofFace.id,
            state.siteModel,
            state.settings.moduleOrientation,
            state.settings.isMagneticSnapEnabled
          )
        }
      })
    }

    case "fillRoofFaceAroundModule": {
      const {design, siteModel, moduleSelection} = state as LoadedWorkspaceState;
      if (!design || !siteModel || !moduleSelection) {
        return state.design // bail
      }
      const selectedModuleIds = moduleSelection.selectedModuleIds
      if (selectedModuleIds.size === 0) {
        return state.design
      }
      //just fill around the first selected module
      const fillAroundModuleId = [...selectedModuleIds][0]
      const fillAroundModule = design!.getModuleById(fillAroundModuleId)!
      const fillAroundModuleOrientation = fillAroundModule.properties.orientation
      const roofFace = siteModel!.getRoofFaceById(fillAroundModule!.properties.roofFaceId);
      if (roofFace!.properties.usable) {
        FullStory.event("Fill Roof Face Around Module", {roofFaceId: fillAroundModule!.properties.roofFaceId})
        return design!.fillRoofFaceAroundModule(
          fillAroundModule!.id,
          siteModel!,
          fillAroundModuleOrientation!,
          state.settings.isMagneticSnapEnabled
        )
      }
      return state.design // bail
    }

    case "updateDesignToMatchOffer": {
      const {design, offer, siteModel} = state;
      if (!design || !offer || !siteModel) {
        console.warn(`
          Cannot updateDesignToMatchOffer as not all aggregates have loaded.
          ${design?'':'Missing Design'}
          ${offer?'':'Missing Offer'}
          ${siteModel?'':'Missing SiteModel'}
        `);
        return design;
      }
      return design.updateEquipmentToMatchOffer(offer, siteModel);
    }

    case "designInitiatedInOfferBuilder": {
      // combines updateDesignToMatchOffer, centerFillAllRoofFaces
      const {design, offer, siteModel, customer, solarResource, designConstraints, copyFromDesign} = state;
      if (!design || !offer || !siteModel || !customer || !solarResource) {
        console.warn('Cannot updateDesignToMatchOffer as not all aggregates have loaded.');
        return design;
      }

      if (copyFromDesign) {
        // copyDesign before updating to match offer
        const copiedDesign = design.copyDesign(copyFromDesign, siteModel);
        // sync with offer
        return copiedDesign.updateEquipmentToMatchOffer(offer, siteModel);
      }
      else {
        // sync with offer first
        const designWithUpdatedEquipment = design.updateEquipmentToMatchOffer(offer, siteModel);
        // remove all modules pre-maxfill
        const allModules = new Set<string>(designWithUpdatedEquipment.modules.map(m => m.id))
        const emptyDesign = designWithUpdatedEquipment.deleteModules(allModules);
        const customerUsageKwh = customer.annualUsagekWh || 10000 // better to guess here than fail completely
        const maxOffsetPercent = designConstraints?.defaultConstraints?.maxOffsetPercentConstraint?.value || 0
        const maxfilledDesign = emptyDesign.attemptToFillAllRoofFacesFromCenter(
          siteModel,
          solarResource,
          state.settings.moduleOrientation,
          customerUsageKwh,
          maxOffsetPercent,
          state.settings.isMagneticSnapEnabled
        );
        return maxfilledDesign;
      }
    }

    case "resolveAutosave": {
      if (state.design){
        return state.design.resolveAutosave(action.payload)
      }
      return state.design
    }

    case "resolvePrepareDesignForOverride":{
      if (state.design){
        return state.design.resolvePrepareDesignForOverride(action.payload)
      }
      return state.design
    }

    default: {
      return state.design
    }
  }
}

const calculateDragDelta = (module: Module, moduleDrag: ModuleDrag): any => {
  const moduleCenter = polygonCentroid(module.geometry)
  const deltaDrag: Vector2D = [
    moduleDrag.dragPosition![0] - moduleCenter[0],
    moduleDrag.dragPosition![1] - moduleCenter[1]
  ];
  return deltaDrag;
}
