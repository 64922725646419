import {FeatureGroup, LayersControl, Pane} from "react-leaflet";
import {SiteModel} from "@sunrun/design-tools-domain-model";
import {TreeLayer} from "./TreeLayer";
import {LeafletLayerZIndexes} from "src/constants";
import { useWorkspace } from "src/hooks/useWorkspace";

type TreeLayerGroupProps = {
  siteModel: SiteModel
}

export const TreeFeatureGroup = (props: TreeLayerGroupProps) => {
  const { state } = useWorkspace()
  const { settings } = state
  if (!settings.isTreesLayerVisible) return null
  return (
    <Pane name="trees" style={{zIndex: LeafletLayerZIndexes.trees}}>
      <FeatureGroup>
        {props.siteModel.trees.map(it =>
          <TreeLayer
            key={it.id}
            tree={it}
          />
        )}
      </FeatureGroup>
    </Pane>
  )
}
