import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {EmptyAction, PayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {Position} from "@sunrun/design-tools-geometry";
import {Module} from "@sunrun/design-tools-domain-model";

export type ModuleNudge = {
  isModuleNudging: boolean
  nudgedModuleId: string | undefined
  originalNudgedModuleCenter: Position | undefined
}

export const moduleNudgeInitialState = {
  isModuleNudging: false,
  nudgedModuleId: undefined,
  originalNudgedModuleCenter: undefined
}

export const selectIsModuleNudging = (state: WorkspaceState) => state.moduleNudge.isModuleNudging

export type ModuleNudgeAction =
  | PayloadAction<WorkspaceEvent,'initNudgingModules'>
  | EmptyAction<'finishNudgingModules'>

export const nudgeReducer = (state: WorkspaceState, action: WorkspaceAction): ModuleNudge => {
  switch (action.type) {
    case "initNudgingModules": {
      const layer = action.payload.propagatedFrom.layer as Module
      return {
        isModuleNudging: true,
        nudgedModuleId: layer.id,
        originalNudgedModuleCenter: action.payload.position
      }
    }
    case "finishNudgingModules": {
      return {
        isModuleNudging: false,
        nudgedModuleId: undefined,
        originalNudgedModuleCenter: undefined
      }
    }
    default:
      return state.moduleNudge
  }

}
