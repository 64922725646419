import {Tree} from "@sunrun/design-tools-domain-model";
import {Circle, PathOptions} from "leaflet";
import {GeoJsonLayer} from "src/components/GeoJsonLayer";
import React from "react";

export type TreeProps = {
  tree: Tree
}

export const TreeLayer = (props: TreeProps) => {
  const [pathOptions,] = React.useState<PathOptions>({color: "#49A428", opacity: 0.5})

  const pointToLayer = (_:any, latlng: any) => {
    return new Circle(latlng, {radius: props.tree.properties.radiusMeters}) as any;
  }

  return (
    <GeoJsonLayer
      data={props.tree}
      pointToLayer={pointToLayer}
      pathOptions={pathOptions}
      key={props.tree.id}
    />
  )
}
