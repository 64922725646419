import {ProductionSimulation, ProductionSimulationType} from "@sunrun/design-tools-domain-model";

export const productionSimulation = new ProductionSimulation({
  designId: "",
  designVersion: 0,
  solarResourceId: "TODO",
  solarResourceVersion: 0, // TODO
  annualProductionKwh: 2000,
  type: ProductionSimulationType.HighResolution
});
