import {Offers, ProductChanges} from "@sunrun/design-tools-domain-model";
import {storefrontClient} from "@sunrun/design-tools-graphql-clients";
import {useWorkspace} from "./useWorkspace";
import {useSearchParams} from "react-router-dom";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"
import React from "react";

export const useOfferLineUpdates = () => {
  const {state, dispatch} = useWorkspace()
  const {customer, design, offer, productionSimulation} = state
  const [searchParams] = useSearchParams();
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const lightmileProjectId = searchParams.get(URLSearchParameterKey.LightmileProjectId) || undefined;

  const updateOfferLines = React.useCallback(async (productChanges: ProductChanges): Promise<{success: boolean}> => {
    let success = false
    if (offer && customer && design && productionSimulation) {
      try {
        loadingScreenHelpers.addProcess({
          group: LoadingProcessGroups.PREPARE_TO_GO_TO_PRICING,
          name: LoadingProcessNames.UPDATING_OFFER,
        });
        const offerLineUpdates = Offers.buildOfferLineUpdates(offer, design, productionSimulation, customer, productChanges)
        console.log(`updating ${offerLineUpdates.length} offer lines:`)
        console.log(JSON.stringify(offerLineUpdates, null, 2))
        const updatedOffer = await storefrontClient.updateOfferLines(
          offer.id,
          design.id,
          design._version|| undefined,
          lightmileProjectId,
          offer.lockVersion,
          offerLineUpdates
        )
        dispatch({type: 'setOffer', payload: updatedOffer})
        success = true
        loadingScreenHelpers.completeProcess(LoadingProcessNames.UPDATING_OFFER);
      } catch(e: any) {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.UPDATING_OFFER);
        const error = (e instanceof Error) ? e : new Error("Failed to update offer");
        dispatch({ type: "setErrorModal", payload: {error, message: "Failed to update offer"}})
      }
    }
    return {success}
  }, [offer, customer, design, productionSimulation]);

  return {
    updateOfferLines
  }
}
