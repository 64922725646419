import {useQuery} from "react-query";
import {useSearchParams} from "react-router-dom";

import {storefrontClient} from "@sunrun/design-tools-graphql-clients";

import { useWorkspace } from "./useWorkspace";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey"

import type { ProductAvailability } from "@sunrun/design-tools-domain-model";

export const useProductAvailability = () => {
  const {state, dispatch} = useWorkspace();
  const [searchParams] = useSearchParams();

  const offerId = searchParams.get(URLSearchParameterKey.OfferId);

  const getProductAvailability = async (): Promise<ProductAvailability> => {
    if (offerId) {
      return storefrontClient.getProductAvailability(offerId);
    }
    throw Error("useQuery should be disabled when offerId is undefined.")
  }

  return useQuery(['getProductAvailability', offerId], getProductAvailability, {
    enabled: !!offerId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (productAvailability: ProductAvailability) => {
      dispatch({type: 'setProductAvailability', payload: productAvailability })
    },
    onError: (error: any) => {
      let userError = (error instanceof Error) ? error : new Error("Unable to fetch available products");
      dispatch({ type: "setErrorModal", payload: {error: userError, message: "Unable to fetch available products"}})
      console.log('Error fetching product availability', error);
    },
  });
}
