import { Divider, Typography, Stack } from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";
import { releaseNotes, ReleaseNote } from "@sunrun/design-tools-whats-new";

type WhatsNewModalProps = {
  open: boolean;
  onClose: () => void;
};

export const WhatsNewModal = ({ open, onClose }: WhatsNewModalProps) => {
  const sortedReleaseNotes = releaseNotes.sort(
    (note1, note2) => note2.date.getTime() - note1.date.getTime()
  );

  return (
    <ModalLayout open={open} title="What's New" onClose={onClose}>
      <>
        {sortedReleaseNotes.map((releaseNote) => (
          <WhatsNewItem key={releaseNote.title} releaseNote={releaseNote} />
        ))}
      </>
    </ModalLayout>
  );
};

const WhatsNewItem = ({ releaseNote }: { releaseNote: ReleaseNote }) => (
  <Stack mb={2} spacing={1}>
    <Typography variant="subtitle1" color="primary">
      {releaseNote.title}
    </Typography>
    <Typography variant="body2">
      {releaseNote.body}
    </Typography>
    <Divider />
  </Stack>
);
