import * as React from "react";
import {useQuery} from "react-query";
import {repository} from "@sunrun/design-tools-graphql-clients";
import {SolarResource, TestFixtures} from "@sunrun/design-tools-domain-model";
import {WorkspaceDispatch, useWorkspace} from "src/hooks/useWorkspace";
import {useErrorHandler} from "react-error-boundary";

type UseSolarResourceProps = {
  solarResourceId?: string,
  solarResourceVersion?: number,
}

export const useSolarResource = ({solarResourceId, solarResourceVersion}: UseSolarResourceProps) => {
  const {state, dispatch} = useWorkspace()
  const handleError = useErrorHandler();

  const getSolarResource = async () => {
    if (solarResourceId)
      return repository.get(SolarResource, solarResourceId, solarResourceVersion ?? 0)
  }

  const query = useQuery(['getSolarResource', solarResourceId], getSolarResource, {
    refetchOnWindowFocus: false,                // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!solarResourceId,           // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newSolarResource: SolarResource) => {
      dispatch({type: 'setSolarResource', payload: newSolarResource})
    },
    onError: handleError
  })
}
