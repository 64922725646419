import {Styles, createUseStyles} from "react-jss";

// THIS FILE WAS COPIED OVER FROM IHD-WORKSPACE-V1
// https://github.com/SunRun/design-tools/blob/ihd-workspace-v1/apps/lightstone/src/components/Workspace/styles.ts
// UNCOMMENT ONLY AS REQUIRED
// TODO: CLEAN UP ANY UNUSED STYLES
export default createUseStyles({
  // Prevents the browser from trying to select the entire interface while clicking and dragging things.
  // if certain fields need to be selectable (like for copying), will probably need to bypass this on
  // an individual basis with `user-select: unset` or `user-select: text`.
  '@global': {
    body: {
      '@media (hover: none) and (pointer: coarse)': {
        '-webkit-touch-callout' : 'none',
        '-webkit-user-select': 'none',
        'touch-callout': 'none',
        'user-select': 'none',
      }
    },

    '.map-container': {
      '@media (hover: hover) and (pointer: fine)': {
        '-webkit-touch-callout' : 'none',
        '-webkit-user-select': 'none',
        'touch-callout': 'none',
        'user-select': 'none',
      }
    }
  }

} as Styles);
