import {
  EmptyAction,
  InputDevice,
  InteractiveLeafletLayer, KeyboardEventType,
  MouseButtonOrKeyboardKey, PointerEventType,
  WorkspaceEvent,
  WorkspaceEventAction
} from "../types/state-management/action";
import {WorkspaceAction, WorkspaceState} from "../hooks/useWorkspace";
import {distanceXY, Vector2D,} from "@sunrun/design-tools-geometry";
import {Constants} from "../constants";
import {Module, ModuleCollisionChecker, RoofFace} from "@sunrun/design-tools-domain-model";
import {getRelativeDirectionNudgeDelta, RelativeDirection} from "@sunrun/design-tools-math3d";
import {makeWorkspaceEvent} from "./workspaceEvent";
import {InitMarqueePayload} from "../features/marquee/marqueeSlice";

/**
 * This "Feature Action Resolver" is for converting low-level Leaflet mouse event actions into semantic feature actions.
 * The intent is the following:
 * 1.  Enable our feature code to work only with higher-level semantic events so it is easier to understand
 * 2.  Ensure that the mapping of pointer events on Leaflet layers to these semantic events happens in one place in
 *     the code rather than being scattered around in, for example, different layer components.
 * 3.  Since a single pointer event can affect multiple slices of state in iHD (see the module drag feature, e.g.)
 *     provide a way to compose multiple semantic actions that will run in correct sequence in response to a single
 *     UI event
 * 4.  Encapsulate this logic in a way that's super-easy to write tests for because it's a pure function with very
 *     simple inputs and outputs
 * 5.  Create an abstraction layer that isolates Leaflet-specific behavior from the rest of the app, making it easier
 *     to substitute some other map library besides Leaflet without triggering code changes throughout the app.
 * 6.  Follow the `Flux Standard Action` type convention: https://redux.js.org/tutorials/fundamentals/part-7-standard-patterns#flux-standard-actions * */

/**
 * The LeafletPointerEventAction type is a discriminated union (https://www.lucaspaganini.com/academy/discriminated-unions-types-typescript-narrowing-4)
 * of all the low-level Leaflet "event" action types. These types cover the permutations of the current interactive layers and
 * the pointer (mouse or touch) events that can occur on them. Note that we group related mouse and touch events such as
 * `mousedown` and `touchstart` together as pointer events like `pointerDown`. This is for convenience since in most
 * cases we want to trigger the same behavior and don't want to care whether the event was from a mouse or touchscreen.
 * But in uncommon cases where we need to distinguish behavior we can discriminate pointer events by checking the
 * `meta.isTouchEvent` property.
  */

export type LeafletPointerEventAction =
  WorkspaceEventAction<PointerEventType, {layer: InteractiveLeafletLayer, device: InputDevice.Mouse | InputDevice.Touch}, WorkspaceEvent>
export type KeyboardEventAction =
  WorkspaceEventAction<KeyboardEventType, {layer: InteractiveLeafletLayer.module, device: InputDevice.Keyboard}, WorkspaceEvent>
export type NoOpAction = EmptyAction<'noop'>

/**
 * Checks for low-level Leaflet event actions (e.g. "mouse click on roof") and resolves them into semantic
 * actions (e.g. "add a module to the design") so the rest of the app can operate purely with semantic actions.
 * Enable you to compose from a single Leaflet event a series of semantic actions that will update different slices
 * of state so you have the assurance these will be dispatched and handled in the order you designate.
 * @param state
 * @param action
 */
export const resolveFeatureAction = (state: WorkspaceState, action: WorkspaceAction): WorkspaceAction[] => {
  // absence of optional `meta` property means it's already a semantic action. Nothing to resolve so return it
  if (!('meta' in action)) return [action];

  // Else we have a low-level Leaflet event action that we need to resolve into a semantic feature action
  const eventAction: WorkspaceEventAction = action;

  // Sanity check that the event resolver index is complete: has the supplied layer and event type mapped to actions
  if (!(eventAction.meta.layer in eventResolverIndex)) {
    throw Error(`Leaflet layer ${eventAction.meta.layer} does not have an event resolver. If you've added a new
    interactive Leaflet layer you will want to update the eventResolverIndex to include it.`)
  }
  if (!(eventAction.type in eventResolverIndex[eventAction.meta.layer])) {
    throw Error(`Leaflet layer ${eventAction.meta.layer} does not have an resolver for the ${eventAction.type} event.
    If you're capturing a new type of Leaflet event you'll need to add the missing event type key and resolver to the
    eventResolverIndex.`)
  }

  // uncomment to log all the low-level event actions
  // console.log(`event: ${eventAction.meta.layer} ${eventAction.type}`)

  // instead of a huge switch case to find the right resolver we use an index for readability and best performance
  return eventResolverIndex[eventAction.meta.layer][eventAction.type](state, eventAction)
}

const noop = (state?: WorkspaceState, eventAction?: WorkspaceEventAction): WorkspaceAction[] => {
  return [{type: "noop"}]
}

const mapClick = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  if (!state.design || !state.siteModel) {
    return noop()
  }
  if (state.moduleSelection.selectedModuleIds.size > 0) {
    return [{type: "clearSelectedModules"}]
  }
  return noop()
}

const roofFacePointerDown = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  if (!state.design || !state.siteModel) {
    return noop()
  }
  const pointerPosition = eventAction.payload.position
  const roofFace = eventAction.payload.propagatedFrom.layer as RoofFace
  const isModulePosition = state.design.isModuleAtPosition(roofFace.id, pointerPosition)
  if (!isModulePosition) { // only init the marquee if the pointer is on a part of the roof unoccupied by a module
    eventAction.meta.map!.dragging.disable()
    eventAction.meta.map!.scrollWheelZoom.disable()
    const initMarqueePayload: InitMarqueePayload = {
      roofFaceId: roofFace.id,
      pointerPosition: eventAction.payload.position,
      azimuthDegrees: roofFace.properties.azimuthDegrees
    }
    return [{type: "initMarquee", payload: initMarqueePayload}]
  }
  return noop()
}

const roofFaceClick = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  if (!state.design || !state.siteModel) {
    return noop()
  }
  if (state.moduleSelection.selectedModuleIds.size > 0) {
    return [{type: "clearSelectedModules"}]
  }
  // attempt to add a module
  // ignore unusable roofs
  const position = eventAction.payload.position
  const roofFace = eventAction.payload.propagatedFrom.layer as RoofFace
  if (!roofFace.properties.usable) {
    return noop()
  }
  let newDesign = state.design.attemptToAddModule(
    position,
    roofFace.id,
    state.siteModel,
    state.settings.moduleOrientation,
    state.settings.isMagneticSnapEnabled,
  )
  if (state.design !== newDesign) { // successfully added new module
    return [{type: "setDesign", payload: newDesign}]
  } else { // attempt failed
    return [{type: "showSnackbarMessage", payload: "Cannot add a module that would hang off the roof."}]
  }
}

const roofFacePointerUp = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  eventAction.meta.map!.dragging.enable()
  eventAction.meta.map!.scrollWheelZoom.enable()
  eventAction.payload.stopEventPropagation()
  if (state.moduleDrag.isDragging) {
    return [
      {type: "commitModuleTranslation", payload: eventAction.payload}, // design slice
      {type: "resetModuleDrag"}, // moduleDrag slice
      {type: "clearSelectedModules"}
    ]
  } else if (state.marquee.isDragging) {
    return [
      {type: "selectModulesByMarquee"},
      {type: "resetMarquee"}
    ]
  } else {
    return noop()
  }
}

const modulePointerDown = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  // ignore right clicks
  const event = eventAction.payload
  if (event.button === MouseButtonOrKeyboardKey.right) {
    return noop()
  }
  eventAction.meta.map!.dragging.disable()
  eventAction.meta.map!.scrollWheelZoom.disable()
  return [
    {type: "selectOneModule", payload: eventAction.payload}, // moduleSelection slice
    {type: "initModuleDrag", payload: eventAction.payload} // moduleDrag slice
  ]
}

const pointerMove = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  if (state.moduleDrag.isPointerDown) {
    return resolveModuleDragEventAction(state, eventAction);
  } else if (state.marquee.isPointerDown) {
    return resolveMarqueeDragEventAction(state, eventAction)
  } else { // pointer isn't down
    return noop()
  }
}

function resolveModuleDragEventAction(state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] {
  const translateModulesStrategy = state.settings.isMagneticSlideEnabled ?
    "translateModulesConstrainedByRoofEdge" : "translateModules"
  if (state.moduleDrag.isDragging) {
    return [
      {type: "dragModules", payload: eventAction.payload}, // moduleDrag slice
      {type: translateModulesStrategy, payload: eventAction.payload} // design slice (no longer moduleDrag)
    ]
  } else { // might be dragging but haven't hit the drag distance threshold yet
    const startingPosition = state.moduleDrag.startPosition!
    const dragPosition = eventAction.payload.position
    const dragDistance = distanceXY(startingPosition, dragPosition);
    if (dragDistance > Constants.LeafletDragThresholdInMeters) {
      return [
        {type: "dragModules", payload: eventAction.payload}, // moduleDrag slice
        {type: translateModulesStrategy, payload: eventAction.payload} // design slice
      ]
    } else { // ignore: it still hasn't hit the drag distance threshold
      return noop()
    }
  }
}

function resolveMarqueeDragEventAction(state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] {
  if (state.marquee.isDragging) {
    return [
      {type: "drawMarquee", payload: eventAction.payload}, // marquee slice
    ]
  } else { // might be dragging but haven't hit the drag distance threshold yet
    const startingPosition = state.marquee.startPosition!
    const dragPosition = eventAction.payload.position
    const dragDistance = distanceXY(startingPosition, dragPosition);
    if (dragDistance > Constants.LeafletDragThresholdInMeters) {
      return [
        {type: "drawMarquee", payload: eventAction.payload}, // marquee slice
      ]
    } else { // ignore: it still hasn't hit the drag distance threshold
      return noop()
    }
  }
}

const pointerUp = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  eventAction.meta.map!.dragging.enable()
  eventAction.meta.map!.scrollWheelZoom.enable()
  eventAction.payload.stopEventPropagation()
  const {design, siteModel, moduleDrag, moduleSelection, marquee} = state
  if (moduleDrag.isDragging) {
    const offRoofModules = ModuleCollisionChecker.checkForModulesOffRoofFace(design!.modules, siteModel!);
    if (offRoofModules.size > 0) {
      return [
        {type: "undoModuleTranslation"}, // design slice
        {type: "resetModuleDrag"}, // moduleDrag slice
        {type: "clearSelectedModules"}, // modulesSelection slice
        {type: "showSnackbarMessage", payload: "Do not move modules off their original roof, even partially"}
      ]
    } else {
      return [
        {type: "commitModuleTranslation", payload: eventAction.payload}, // design slice
        {type: "resetModuleDrag"}, // moduleDrag slice
        {type: "clearSelectedModules"} // modulesSelection slice
      ]
    }
  } else if (marquee.isDragging) {
    return [
      {type: "selectModulesByMarquee"},
      {type: "resetMarquee"}
    ] // moduleSelection slice
  } else { // if nothing else happens, we ensure these get reset
    return [
      {type: "resetModuleDrag"},
      {type: "resetMarquee"}
    ]
  }
}

const modulePointerDoubleClick = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  return [{type: "selectAllModulesOnRoofFace", payload: eventAction.payload}] // moduleSelection slice
}

const arrowKeyWhileModulesSelected = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  if (eventAction.payload.button === MouseButtonOrKeyboardKey.keyDown) {
    return arrowKeyDownWhileModulesSelected(state, eventAction)
  } else { // keyUp
    return arrowKeyUpWhileModulesSelected(state, eventAction)
  }
}

const arrowKeyDownWhileModulesSelected = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  if (state.moduleSelection.selectedModuleIds.size > 0) {
    const map = eventAction.meta.map!
    const module = eventAction.payload.propagatedFrom.layer as Module
    const direction = getRelativeDirectionFromArrowKey(eventAction.type)
    const nudgeScale = (map.getMaxZoom() + 1) - map.getZoom()
    const delta = calculateDelta(state, module, direction, nudgeScale)
    if (state.moduleNudge.isModuleNudging) {
      return [
        {type: "nudgeModules", payload: {module: module, delta: delta}} // design slice
      ]
    }
    // else start the nudging
    const event = makeWorkspaceEvent(module.centroid(), module, MouseButtonOrKeyboardKey.keyDown)
    return [
      {type: "initNudgingModules", payload: event}, // moduleNudge slice
      {type: "nudgeModules", payload: {module: module, delta: delta}} // design slice
    ]
  } else {
    return noop()
  }
}

const arrowKeyUpWhileModulesSelected = (state: WorkspaceState, eventAction: WorkspaceEventAction): WorkspaceAction[] => {
  // at the end of a nudge operation check if the modules are in valid positions
  const { design, siteModel } = state
  if (!design || !siteModel) {
    return noop()
  }
  const isModuleLayoutOk = ModuleCollisionChecker.checkForModulesOffRoofFace(design.modules, siteModel).size == 0
  if (isModuleLayoutOk) {
    return [
      {type: "finishNudgingModules"}
    ]
  } else { // layout not ok, so undo the nudge
    return [
      {type: "undoModuleNudge", payload: eventAction.payload},
      {type: "finishNudgingModules"}
    ]
  }
}

const arrowKeyRelativeDirection:{[key:string]: RelativeDirection} = {
  "ArrowLeft": RelativeDirection.left,
  "ArrowRight": RelativeDirection.right,
  "ArrowUp": RelativeDirection.up,
  "ArrowDown": RelativeDirection.down
};

export const getRelativeDirectionFromArrowKey = (eventType: string): RelativeDirection => {
  return arrowKeyRelativeDirection[eventType];
}

const calculateDelta = (state: WorkspaceState, module: Module, direction: RelativeDirection, nudgeScale: number): Vector2D => {
  const distance =  Constants.nudgeDistanceInMeters * nudgeScale;
  const roofFace = state.siteModel?.getRoofFaceById(module?.properties?.roofFaceId||"");
  const delta: Vector2D = getRelativeDirectionNudgeDelta(roofFace?.properties?.azimuthDegrees||0, direction, distance);
  return delta
}

/**
 * Index to map Leaflet and keyboard events to resolver functions without scanning down a switch statement
 */



type WorkspaceResolverFunction = {
  [index: string]: (state: WorkspaceState, eventAction: WorkspaceEventAction) => WorkspaceAction[]
}

const eventResolverIndex: {[index: string]: WorkspaceResolverFunction} = {
  map: {
    click: mapClick,
    pointerDown: noop,
    pointerMove: pointerMove,
    pointerUp: pointerUp,
    dblclick: noop,
    contextmenu: noop,
    ArrowUp: noop,
    ArrowDown: noop,
    ArrowLeft: noop,
    ArrowRight: noop,
  },
  roofFace: {
    click: roofFaceClick,
    pointerDown: roofFacePointerDown,
    pointerMove: pointerMove,
    pointerUp: roofFacePointerUp,
    dblclick: noop,
    contextmenu: noop,
    ArrowUp: noop,
    ArrowDown: noop,
    ArrowLeft: noop,
    ArrowRight: noop,
  },
  module: {
    click: noop,
    pointerDown: modulePointerDown,
    pointerMove: pointerMove,
    pointerUp: pointerUp,
    dblclick: modulePointerDoubleClick,
    contextmenu: modulePointerDoubleClick,
    ArrowUp: arrowKeyWhileModulesSelected,
    ArrowDown: arrowKeyWhileModulesSelected,
    ArrowLeft: arrowKeyWhileModulesSelected,
    ArrowRight: arrowKeyWhileModulesSelected,
  }
}
