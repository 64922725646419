import {CRS, LatLngTuple, Map} from "leaflet";
import * as React from "react";
import {MapContainer} from "react-leaflet";
import {RuntimeContext, TestType} from "@sunrun/design-tools-runtime-context";
import {SiteModel} from "@sunrun/design-tools-domain-model";
import {Position} from "geojson";
import {Hotkeys} from "./Hotkeys";

export type LeafletMapContainerProps = {
  children: React.ReactNode
  siteModel: SiteModel
}

export const LeafletMapContainer = (props: LeafletMapContainerProps) => {
  if (props.siteModel.pin == null) {
    throw new Error('Cannot load leaflet map container because SiteModel is missing pin.')
  }
  return (
    <MapContainer
      crs={CRS.Simple}
      center={toLeafletLatLng(props.siteModel.pin.coordinates)}
      zoom={4.25}
      maxZoom={8}
      zoomControl={false}
      zoomSnap={.25} // enable fractional zoom https://leafletjs.com/examples/zoom-levels/
      scrollWheelZoom={true}
      preferCanvas={RuntimeContext.testType !== TestType.None}    // SVG renderer does not work in node/jest
      doubleClickZoom={false}
      keyboard={false}
      className='map-container'
    >
      <Hotkeys />
      {props.children}
    </MapContainer>
  )
}

export const toLeafletLatLng = (position: Position): LatLngTuple => {
  return [position[1], position[0]]
}

export const enableMapTouchEvents = (map: Map): void => {
  map.dragging.enable()
  map.scrollWheelZoom.enable()
  map.touchZoom.enable()
}

export const disableMapTouchEvents = (map: Map): void => {
  map.dragging.disable()
  map.scrollWheelZoom.disable()
  map.touchZoom.disable()
}

export const enableMapMouseEvents = (map: Map): void => {
  map.dragging.enable()
  map.scrollWheelZoom.enable()
}

export const disableMapMouseEvents = (map: Map): void => {
  map.dragging.disable()
  map.scrollWheelZoom.disable()
}
