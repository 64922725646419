import { Stack, Typography } from "@mui/material";
import { formatNumber } from "@sunrun/design-tools-themes";

export type StatDisplayProps = {
  title: string;
  stat: string | number | undefined;
  unit?: string | undefined;
  toDecimal?: number;
  noWrap?: boolean;
};

const StatDisplay = ({
  title,
  stat = "N/A",
  unit,
  toDecimal = 0,
  noWrap = false,
}: StatDisplayProps) => {
  stat = isNaN(Number(stat))
    ? stat
    : parseFloat(Number(stat).toFixed(toDecimal));
  const formattedStat = isNaN(Number(stat))
    ? stat
    : formatNumber({
        value: Number(stat),
        maximumFractionDigits: toDecimal,
        replaceZeroWithDefault: true,
      });
  return (
    <Stack>
      <Typography variant="body2" color="primary" noWrap={noWrap}>
        {title}
      </Typography>
      <Typography variant="caption" whiteSpace="pre-line">
        {formattedStat} {unit}
      </Typography>
    </Stack>
  );
};

export default StatDisplay;