import {
  Box,
  Button,
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useState } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import satellitePng from "src/assets/satelliteDefault.png";
import sunShadePng from "src/assets/sunShadeDefault.png";

export const LayerControl = () => {
  const { state, dispatch } = useWorkspace();
  const { settings, heatMap } = state;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const layers = [
    {
      label: "Map Image",
      checked: settings.isMapImageLayerVisible,
      action: () => dispatch({ type: "toggleIsMapImageLayerVisible" }),
    },
    {
      label: "Module Spacing",
      checked: settings.isModuleSpacingLayerVisible,
      action: () => dispatch({ type: "toggleIsModuleSpacingLayerVisible" }),
    },
    {
      label: "Roof Faces",
      checked: settings.isRoofFacesLayerVisible,
      action: () => dispatch({ type: "toggleIsRoofFacesLayerVisible" }),
    },
    {
      label: "Modules",
      checked: settings.isModulesLayerVisible,
      action: () => dispatch({ type: "toggleIsModulesLayerVisible" }),
    },
    {
      label: "Setbacks",
      checked: settings.isSetbacksLayerVisible,
      action: () => dispatch({ type: "toggleIsSetbacksLayerVisible" }),
    },
    {
      label: "Sun Hours",
      checked: settings.isSunHoursVisible,
      action: () => dispatch({ type: "toggleIsSunHoursVisible" }),
    },
    {
      label: "Obstructions",
      checked: settings.isObstructionsLayerVisible,
      action: () => dispatch({ type: "toggleIsObstructionsLayerVisible" }),
    },
    {
      label: "Trees",
      checked: settings.isTreesLayerVisible,
      action: () => dispatch({ type: "toggleIsTreesLayerVisible" }),
    },
  ];
  return (
    <>
      <Fab color="secondary" aria-label="edit" onClick={handleClick}>
        <MapOutlinedIcon />
      </Fab>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            padding: 2,
            maxWidth: "440px",
          }}
        >
          <Stack spacing={2}>
            <Box>
              <Typography color="primary">Map Details</Typography>
              <Grid container>
                {layers.map((layer) => (
                  <Grid key={layer.label} item xs={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={layer.checked}
                            onChange={layer.action}
                          />
                        }
                        label={layer.label}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider />
            <Box>
              <Typography color="primary"> Map Views</Typography>
              <Stack direction="row" spacing={2} mt={2}>
                <Button
                  sx={{
                    borderRadius: "4px"
                  }}
                  onClick={() =>
                    dispatch({ type: "setHeatMapVisibility", payload: false })
                  }
                >
                  <MapView label={MapViewLabel.satellite} selected={!heatMap.isVisible} />
                </Button>
                <Button
                  sx={{
                    borderRadius: "4px"
                  }}
                  onClick={() =>
                    dispatch({ type: "setHeatMapVisibility", payload: true })
                  }
                >
                  <MapView label={MapViewLabel.sunShade} selected={heatMap.isVisible} />
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
enum MapViewLabel {
  satellite = "Satellite",
  sunShade = "Sun / Shade"
}
type MapViewProps = {
  label: MapViewLabel;
  selected: boolean;
};

const MapView = ({ label, selected }: MapViewProps) => {
  return (
    <Stack spacing={1} justifyContent="space-between" alignItems="center">
      <Box
        sx={{
          width: "72px",
          height: "72px",
          overflow: "hidden",
          borderRadius: "100%",
          boxSizing: "border-box",
          border: selected ? `2px solid #FFFEFC` : "none",
          boxShadow: selected ? "0px 5px 5px 0px rgba(0, 0, 0, 0.12)" : "none",
        }}
      >
        <img src={label === MapViewLabel.sunShade ? sunShadePng : satellitePng} />
      </Box>
      <Typography
        color="primary"
        textAlign="center"
        fontWeight={selected ? "bold" : "normal"}
      >
        {label}
      </Typography>
    </Stack>
  );
};