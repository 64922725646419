import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useWorkspace} from "../hooks/useWorkspace";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import CloseIcon from '@mui/icons-material/Close';
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeselectIcon from '@mui/icons-material/Deselect';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import Cable from '@mui/icons-material/Cable'
import {ModuleIssue} from "@sunrun/design-tools-domain-model";
import useModuleIssues from "src/hooks/useModuleIssues";

const IssueIcon = ({moduleIssue}: {moduleIssue: ModuleIssue}) => {
  switch (moduleIssue){
    case ModuleIssue.Islanded: {
      return <SplitscreenIcon />;
    }
    case ModuleIssue.Colliding: {
      return <DeselectIcon />;
    }
    case ModuleIssue.LowSunHours: {
      return <WbSunnyIcon />;
    }
    case ModuleIssue.OffRoofFace: {
      return <FitScreenIcon />;
    }
    case ModuleIssue.ShortString: {
      return <Cable />
    }
  }
}

export const ModuleIssuesDialog = ({
  closeDialog
}: { closeDialog: () => void }) => {
  const { uniqueIssuesOfSelectedModules, getIssueDescription } = useModuleIssues();

  React.useEffect(function () {
    window.addEventListener('mouseup', closeDialog);
    window.addEventListener('touchend', closeDialog);
    return () => {
      window.removeEventListener('mouseup', closeDialog);
      window.removeEventListener('touchend', closeDialog);
    }
  }, []);

  const issueListItems: React.ReactNode[] = [...uniqueIssuesOfSelectedModules].map(i => {
    return <ListItem>
      <IssueIcon moduleIssue={i} />
      <ListItemText primary={getIssueDescription(i)} />
    </ListItem>
  });

  return (
    <Dialog open={true} style={{position: "relative"}} data-component="ModuleIssuesDialog">
      <DialogTitle onMouseUp={e => e.stopPropagation()} onTouchEnd={e => e.stopPropagation()} style={{borderBottom: "1px solid #D3D3D3"}}>
        <DoNotDisturbIcon style={{marginRight: '10px'}} />
        Selected module(s) has the following issue(s)
        <CloseIcon onClick={closeDialog} style={{position: "absolute", top:0, right: 0, padding: "10px 5px 0 0"}}  />
      </DialogTitle>
      <List onTouchEnd={e => e.stopPropagation()} onMouseUp={e => e.stopPropagation()} sx={{ pt: 0 }}>
        { issueListItems.length > 0 && issueListItems }
      </List>
    </Dialog>
  );
}
