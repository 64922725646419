import { Snackbar } from "@mui/material";
import * as React from "react"
import {useWorkspace} from "../hooks/useWorkspace";

type SnackbarContainerProps = {
  bumpUpMessage: boolean
}
export const SnackbarContainer = ({
  bumpUpMessage
}: SnackbarContainerProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState<string>();
  const {state, dispatch} = useWorkspace();

  // https://mui.com/material-ui/react-snackbar/#transitions
  React.useEffect(function openSnackbarOnEachNewMessageToEnablePrettyTransitions(){
    if (message) setOpen(true)
  }, [message])

  React.useEffect(function clearMessageWhenSnackbarClose(){
    if (!open) {
      setMessage(undefined)
      dispatch({type: 'dismissSnackbarMessage'});
    }
  }, [open])

  React.useEffect(function getMessageFromState() {
    if (state.notification.snackbarMessage) setMessage(state.notification.snackbarMessage)
  }, [state.design])

  return (
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      autoHideDuration={5000}
      open={open}
      message={message}
      key={"bottomcenter"}
      onClose={() => setOpen(false)}
      // scoot this up if floating context buttons are open
      style={{marginBottom: bumpUpMessage ? '50px' : 'default'}}
      data-component="Snackbar"
    />
  )
}
