import React from "react";
import _ from "lodash"
import {
  MostRecentSignedDesign,
  Customer,
  Design,
  DesignConstraints,
  GeoRaster,
  ProductionSimulation,
  ReadOnlyDesignSet,
  SiteModel,
  SolarResource
} from "@sunrun/design-tools-domain-model"
import {Mode} from "src/modes/Mode";
import {Settings, SettingsAction, settingsInitialState, settingsReducer} from "src/features/settings/settingsSlice"
import {ModuleDrag, ModuleDragAction, dragInitialState, dragReducer} from "../features/moduleDrag/moduleDragSlice";
import {DesignAction, designReducer} from "../features/design/designSlice";
import {ModuleSelection, ModuleSelectionAction, moduleSelectionReducer} from "../features/moduleSelection/moduleSelectionSlice";
import {marqueeInitialState, Marquee, MarqueeAction} from "../features/marquee/marqueeSlice";
import {SiteModelAction, siteModelReducer} from "../features/siteModel/siteModelSlice";
import {
  LeafletPointerEventAction,
  KeyboardEventAction,
  NoOpAction,
  resolveFeatureAction
} from "../actionResolvers/featureActionResolver";
import {CustomerAction, customerReducer} from "../features/customer/customerSlice";
import {DesignConstraintsAction, designConstraintsReducer} from "../features/designConstraints/designConstraintsSlice";
import {GeoRasterAction, geoRasterReducer} from "../features/geoRaster/geoRasterSlice";
import {SolarResourceAction, solarResourceReducer} from "../features/solarResource/solarResourceSlice";
import {ProductionSimulationAction, productionSimulationReducer} from "../features/productionSimulation/productionSimulationSlice";
import {WorkflowState, WorkflowStateAction, workflowStateReducer} from "../features/workflowState/workflowStateSlice";
import {HeatMap, HeatMapAction, heatMapReducer} from "../features/heatMap/heatMapSlice";
import {HackAction, hacksReducer} from "../features/hacks/hacksReducerSlice";
import {HostAction, hostReducer} from "../features/host/hostSlice";
import {HotkeyAction} from "./useHotkeys";
import {notificationInitialState, notificationReducer, Notification, NotificationAction} from "../features/notifications/notificationSlice";
import {
  moduleNudgeInitialState,
  ModuleNudge,
  nudgeReducer,
  ModuleNudgeAction
} from "../features/moduleNudge/moduleNudgeSlice";
import {marqueeReducer} from "../features/marquee/marqueeSlice";
import {
  MostRecentSignedDesignAction,
  mostRecentSignedDesignReducer,
  mostRecentSignedDesignInitialState
} from "../features/mostRecentSignedDesign/mostRecentSignedDesign";
import {IFrameHostType} from "../types/IFrame";
import {
  interconnectionAppliedDesignSetReducer,
  signedRootDesignSetReducer,
  ReadOnlyDesignAction,
  copyFromDesignReducer
} from "src/features/readOnlyDesignSet/readOnlyDesignSetSlice";
import {OfferAction, offerReducer} from "src/features/offer/offerSlice";
import { Modal, ModalAction, modalInitialState, modalReducer } from "src/features/modal/modalSlice";
import { promotionsReducer } from "src/features/promotions/promotionsSlice";
import { productAvailabilityReducer } from "src/features/productAvailability/productAvailabilitySlice";

import type {Offer, ProductAvailability, Promotion} from "@sunrun/design-tools-domain-model";
import type { PromotionAction } from "src/features/promotions/promotionsSlice";
import type { ProductAvailabilityAction } from "src/features/productAvailability/productAvailabilitySlice";

/**
 * General approach here is taken from https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

export type WorkspaceState = {
  // micro-frontend host
  host?: IFrameHostType

  // Aggregate State
  customer?: Customer
  design?: Design
  designConstraints?: DesignConstraints
  geoRaster?: GeoRaster
  productionSimulation?: ProductionSimulation // note: this production sim is EITHER the high res sim, or the lowRes sim with FUDGE PRE-APPLIED!
  signedRootDesignSet?: ReadOnlyDesignSet,
  interconnectionAppliedDesignSet?: ReadOnlyDesignSet,
  copyFromDesign?: Design,
  HACKS: {
    // there is currently an issue in which high-res  simulations are
    // quite different than low-res  simulation estimates.
    // these HACKS are here to power the fudge-factor that we use to improve the
    // iHD user experience, while in parallel we attempt to understand the discrepancy
    // more deeply. so - once we understand, we will remove this slice of the state.
    designAtSimulationTime?: Design,
  }
  siteModel?: SiteModel
  solarResource?: SolarResource
  offer?: Offer

  // Process Manager State
  workflowState: WorkflowState

  // iHD Model
  mostRecentSignedDesign: MostRecentSignedDesign
  heatMap: HeatMap
  mode: Mode
  moduleDrag: ModuleDrag
  moduleNudge: ModuleNudge
  moduleSelection: ModuleSelection
  marquee: Marquee
  promotions?: Promotion[]
  productAvailability?: ProductAvailability
  settings: Settings
  notification: Notification
  modal: Modal
  actionHistory: WorkspaceAction[]
}

// a type we can use that guarantees the workspace has been initialized
export type LoadedWorkspaceState = WorkspaceState & {
  design: Design
  siteModel: SiteModel
}

export type WorkspaceAction =
  | HostAction
  | LeafletPointerEventAction
  | KeyboardEventAction
  | MostRecentSignedDesignAction
  | ModuleDragAction
  | ModuleNudgeAction
  | CustomerAction
  | GeoRasterAction
  | DesignConstraintsAction
  | SiteModelAction
  | DesignAction
  | ReadOnlyDesignAction
  | ModuleSelectionAction
  | MarqueeAction
  | SolarResourceAction
  | ProductionSimulationAction
  | WorkflowStateAction
  | OfferAction
  | HeatMapAction
  | SettingsAction
  | HackAction
  | HotkeyAction
  | NotificationAction
  | ModalAction
  | NoOpAction
  | PromotionAction
  | ProductAvailabilityAction

/**
 * Modes are implemented by swapping out the reducer function implementation, but the reducer function state / action
 * API will remain constant.
 */
const workspaceReducer = (state: WorkspaceState, action: WorkspaceAction) => {
  switch (state.mode) {
    case Mode.ModuleAdjustmentMode:
      return moduleAdjustmentReducer(state, action)
    default:
      throw new Error("Workspace mode was not set")
  }
}

const moduleAdjustmentReducer = (state: WorkspaceState, action: WorkspaceAction): WorkspaceState => {
  // Convert any low-level Leaflet actions to semantic actions. Leaflet event actions can resolve to multiple semantic
  // feature actions, so this returns an array of 1 or more semantic actions
  let actions: WorkspaceAction[] = resolveFeatureAction(state, action)
  actions = actions.filter(action => action.type !== 'noop')
  if (actions.length === 0) return state; // nothing to do return state unmodified
  // avoid spamming the logs with lots of repetitive actions (esp from noop or drag events)
  if (!actionsWereRecentlyAddedToHistory(state.actionHistory, actions)) {
    for (action of actions) {
      console.log(`action: ${action.type}`)
    }
  }
  let currState = state
  let nextState = state // this will always be reassigned below but initializing here to placate the compiler
  for (action of actions) {
    nextState = {
      mode: state.mode,
      host: hostReducer(currState, action),
      mostRecentSignedDesign: mostRecentSignedDesignReducer(currState, action),
      customer: customerReducer(currState, action),
      siteModel: siteModelReducer(currState, action),
      design: designReducer(currState, action),
      moduleDrag: dragReducer(currState, action),
      moduleNudge: nudgeReducer(currState, action),
      moduleSelection: moduleSelectionReducer(currState, action),
      marquee: marqueeReducer(currState, action),
      designConstraints: designConstraintsReducer(currState, action),
      geoRaster: geoRasterReducer(currState, action),
      solarResource: solarResourceReducer(currState, action),
      productionSimulation: productionSimulationReducer(currState, action),
      signedRootDesignSet: signedRootDesignSetReducer(currState, action),
      interconnectionAppliedDesignSet: interconnectionAppliedDesignSetReducer(currState, action),
      copyFromDesign: copyFromDesignReducer(currState, action),
      workflowState: workflowStateReducer(currState, action),
      offer: offerReducer(currState, action),
      heatMap: heatMapReducer(currState, action),
      settings: settingsReducer(currState, action),
      HACKS: hacksReducer(currState, action),
      notification: notificationReducer(currState, action),
      modal: modalReducer(currState, action),
      actionHistory: state.actionHistory,
      promotions: promotionsReducer(currState, action),
      productAvailability: productAvailabilityReducer(currState, action),
    }
    currState = nextState
  }
  nextState.actionHistory = updateActionHistory(currState.actionHistory, actions)
  return nextState
}

export function updateActionHistory(actionHistory: WorkspaceAction[], actions: WorkspaceAction[]) {
  const newHistory = [...actions.reverse(), ...actionHistory]
  return newHistory.slice(0, 10)
}

export function actionsWereRecentlyAddedToHistory(actionHistory: WorkspaceAction[], actions: WorkspaceAction[]): boolean {
  const recentHistory = _.take(actionHistory, actions.length)
  const reversedActions = [...actions].reverse()
  return recentHistory.every((historic: WorkspaceAction, index) => historic.type === reversedActions[index].type)
}

export type WorkspaceProps = { children: React.ReactNode, initialState?: WorkspaceState }

const WorkspaceContext = React.createContext<{state: WorkspaceState; dispatch: WorkspaceDispatch } | undefined>(undefined)

export const defaultInitialState: WorkspaceState = {
  host: undefined,
  mostRecentSignedDesign: mostRecentSignedDesignInitialState,
  customer: undefined,
  siteModel: undefined,
  design: undefined,
  designConstraints: undefined,
  geoRaster: undefined,
  solarResource: undefined,
  productionSimulation: undefined,
  signedRootDesignSet: undefined,
  interconnectionAppliedDesignSet: undefined,
  copyFromDesign: undefined,
  workflowState: {
    isProductToEquipmentMappingInProgress: false,
    isSimulateDesignInProgress: false,
    isLegacyFinalizeDesignInProgress: false,
  },
  offer: undefined,
  HACKS:{},
  heatMap: {
    isVisible: false,
    bounds: undefined
  },
  mode: Mode.ModuleAdjustmentMode,
  moduleDrag: dragInitialState,
  moduleNudge: moduleNudgeInitialState,
  moduleSelection: {
    selectedModuleIds: new Set<string>()
  },
  marquee: marqueeInitialState,
  promotions: undefined,
  settings: settingsInitialState,
  notification: notificationInitialState,
  modal: modalInitialState,
  actionHistory: []
}

export const WorkspaceProvider = ({ children, initialState }: WorkspaceProps) => {
  const [state, dispatch] = React.useReducer(workspaceReducer, {
      ...defaultInitialState,
      ...(initialState || {}),
    }
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }
  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}

export const useWorkspace = () => {
  const context = React.useContext(WorkspaceContext)
  if (context === undefined) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider')
  }
  return context
}

export type WorkspaceDispatch = React.Dispatch<WorkspaceAction>
