/**
 * To increase clarity, we call "Output Selectors" written with the reselect utility, derivations.
 * The term selector is reserved for "input selectors".
 *
 * https://redux.js.org/usage/deriving-data-selectors#writing-memoized-selectors-with-reselect
 *
 *
 * https://redux.js.org/faq/code-structure#what-should-my-file-structure-look-like-how-should-i-group-my-action-creators-and-reducers-in-my-project-where-should-my-selectors-go
 */
import {createSelector} from "reselect";
import {selectDesign} from "../design/designSlice";
import {selectDesignAtSimulationTime} from "src/features/hacks/hacksReducerSlice"
import {selectProductionSimulation} from "src/features/productionSimulation/productionSimulationSlice"
import {selectSolarResource} from "src/features/solarResource/solarResourceSlice";
import {selectIsLeafletDragging} from "src/features/moduleDrag/moduleDragSlice"
import {LowResProductionSimulation} from "@sunrun/design-tools-domain-model";
import {selectIsModuleNudging} from "../moduleNudge/moduleNudgeSlice";

/**
 * Stop gap hack as discussed in https://sunrun.jira.com/browse/LS-849
 */
const deriveSunHoursPerModuleOffset = createSelector(
  [selectSolarResource, selectProductionSimulation, selectDesignAtSimulationTime],
  (solarResource, productionSimulation, design) => {
    if (solarResource && productionSimulation && design && design.version === productionSimulation.designVersion) {
      return LowResProductionSimulation.computeFudgeFactor(solarResource, productionSimulation, design);
    }
    if (design && productionSimulation && design.version !== productionSimulation.designVersion) {
      console.log("Can't compute fudge factor: design versions don't match")
      console.log("current design version:", design.version)
      console.log("production design version", productionSimulation.designVersion)
    }
    return undefined
  }
)

// TODO typing this guy with the .lastResult() below is tricky business
export const deriveLowResProductionSimulation : any = createSelector(
  [
    selectProductionSimulation,
    selectSolarResource,
    selectDesign,
    deriveSunHoursPerModuleOffset,
    selectIsLeafletDragging,
    selectIsModuleNudging
  ],
  (productionSimulation, solarResource, design, sunHourPerModuleOffset, isLeafletDragging, isModuleNudging) => {
    // If dragging or nudging return the previously calculated lowResProductionSimulation
    if (isLeafletDragging || isModuleNudging){
      return deriveLowResProductionSimulation.lastResult()
    }
    if (productionSimulation && solarResource && design && sunHourPerModuleOffset) {
      return LowResProductionSimulation.from(solarResource, design, sunHourPerModuleOffset)
    }
    const missingResources = []
    if (!productionSimulation) missingResources.push("productionSimulation")
    if (!solarResource) missingResources.push("solarResource")
    if (!design) missingResources.push("design")
    if (!sunHourPerModuleOffset) missingResources.push("sunHourPerModuleOffset")

    console.log("could not derive low res production simulation. Missing:", missingResources)
    return undefined
  }
)

