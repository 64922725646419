import React from "react";
import {useParams, useSearchParams} from "react-router-dom";
import LoadingScreen from "@sunrun/design-tools-loading-screen";
import {LightmileProjectSource} from "@sunrun/design-tools-domain-model";
import {useImportLightmileProject} from "../../../../useImportLightmileProject";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

export const ImportLightmileProjectByProspectRoute = () => {
  const {setImportParams} = useImportLightmileProject();

  const {prospectId, offerId} = useParams();
  const [searchParams] = useSearchParams();
  const signedRootId = searchParams.get(URLSearchParameterKey.SignedRootId) || undefined;
  const signedRootIdDashProduct = searchParams.get(URLSearchParameterKey.SignedRootIdDashProduct) || undefined;
  const interconnectionAppliedProposalId = searchParams.get(URLSearchParameterKey.InterconnectionAppliedProposalId) || undefined;
  const copyFromProposalId = searchParams.get(URLSearchParameterKey.CopyFromProposalId) || undefined;
  const copyFromDesignId = searchParams.get(URLSearchParameterKey.CopyFromDesignId) || undefined;

  console.log("prospectId:", prospectId);
  if (signedRootId) console.log("signedRootId:", signedRootId);
  if (interconnectionAppliedProposalId) console.log("interconnectionAppliedProposalId:", interconnectionAppliedProposalId);
  if (copyFromProposalId) console.log("copyFromProposalId:", copyFromProposalId);
  if (copyFromDesignId) console.log("copyFromDesignId:", copyFromDesignId);

  React.useEffect(() => {
    if (prospectId && offerId) {
      const activeProjectSource: LightmileProjectSource = {
        sourceByProspect: {
          prospectId,
        }
      };
      setImportParams({
        activeProjectSource,
        prospectId,
        signedRootId,
        signedRootIdDashProduct,
        interconnectionAppliedProposalId,
        copyFromProposalId,
        offerId
      });
    }
  }, [prospectId, offerId]);

  return (
    <LoadingScreen offerExperience/>
  );
};
