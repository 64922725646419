import React from "react";
import {Button, Grid, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const ImportLightmileProjectForm = () => {
  const [lightmileProjectId, setLightmileProjectId] = React.useState<string>()
  const [lightmileDesignIndex, setLightmileDesignIndex] = React.useState<string>()
  const [formButtonEnabled, setFormButtonEnabled] = React.useState(false)
  const navigate = useNavigate()

  const onClick = () => {
    if (lightmileProjectId && lightmileDesignIndex)
      navigate(`/imports/lightmile/projects/${lightmileProjectId}/designs/${lightmileDesignIndex}`)
  }

  React.useEffect(() => {
    if (lightmileProjectId && lightmileDesignIndex) setFormButtonEnabled(true)
    else setFormButtonEnabled(false)
  }, [lightmileProjectId, lightmileDesignIndex])

  return (
    <div>
      <h2>Import Lightmile Projects</h2>
      <Grid item container direction={"row"} spacing={0}>
        <Grid item>
          <TextField
            label="lightmileProjectId"
            type="text"
            variant="outlined"
            required
            onChange={(event) => {
              setLightmileProjectId(event.target.value)
            }}
          />
          <TextField
            label="lightmileDesignIndex"
            type="text"
            variant="outlined"
            required
            onChange={(event) => {
              setLightmileDesignIndex(event.target.value)
            }}
          />
        </Grid>
        <Grid item/>
        <Grid item>
          <Button
            variant="contained"
            onClick={onClick}
            disabled={!formButtonEnabled}
          >
            Import
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
