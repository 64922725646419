import {FireCodePathway as FireCodePathwayEntity} from "@sunrun/design-tools-domain-model";
import {PathOptions} from "leaflet";
import React from "react";
import {GeoJsonLayer} from "src/components/GeoJsonLayer";
import {setbackStripes} from "src/styles/stylingPatterns";

export type FireCodePathwayProps = {
  fireCodePathway: FireCodePathwayEntity
}

export const FireCodePathwayLayer = (props: FireCodePathwayProps) => {
  const [pathOptions,] = React.useState<PathOptions>({
    color: "#EA3434",
    opacity: 0.5,
    fillOpacity: 0.7,
    fillPattern: setbackStripes
  })

  return (
    <GeoJsonLayer
      data={props.fireCodePathway}
      pathOptions={pathOptions}
      key={props.fireCodePathway.id}
    />
  )
}
