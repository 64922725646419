import { MenuItem, Typography } from "@mui/material";
import { DesignMenuItem } from "./DesignMenu";

type DesignMenuItemProps = {
  menuItem: DesignMenuItem;
  isLast: boolean;
};

export const DesignMenuItemComponent = ({
  menuItem,
  isLast,
}: DesignMenuItemProps) => (
  <MenuItem
    divider={!isLast}
    onClick={menuItem.action}
    sx={{
      py: 2,
      minWidth: "300px",
    }}
  >
    <Typography>{menuItem.title}</Typography>
    {menuItem?.value && (
      <Typography color="primary" ml={1}>
        {menuItem.value}
      </Typography>
    )}
    {menuItem?.hotkey && (
      <Typography ml="auto">
        {menuItem.hotkey}
      </Typography>
    )}
  </MenuItem>
);
