import {createSelector} from "reselect";
import {deriveIsDesignFinalized} from "./deriveIsDesignFinalized";
import {selectDesign} from "../design/designSlice";
import {selectOffer} from "../offer/offerSlice";
import {NO_PRODUCT_CHANGE, Offers, ProductChanges} from "@sunrun/design-tools-domain-model";

export const deriveOfferProductChanges = createSelector(
  [selectDesign, selectOffer, deriveIsDesignFinalized],
  (design, offer): ProductChanges => {
    if (design && offer) {
      return Offers.checkForProductChanges(offer, design);
    }
    return {
      solar: NO_PRODUCT_CHANGE,
      backup: NO_PRODUCT_CHANGE
    };
  }
);
