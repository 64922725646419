// This enum is a list of the post message types that iHD sends
export enum IFrameEventType {
  APP_MOUNTED = "APP_MOUNTED",
  READY = "READY",
  DESIGN_EDIT_START = "DESIGN_EDIT_START",
  DESIGN_EDIT_CANCEL = "DESIGN_EDIT_CANCEL",
  DESIGN_EDIT_COMPLETE = "DESIGN_EDIT_COMPLETE",
  OFFER_UPDATED = "OFFER_UPDATED",
  GENERATE_DESIGNS = "COMMAND:GENERATE_DESIGNS",
  ENABLE_CANCEL_BUTTON = "ENABLE_CANCEL_BUTTON",
  DISABLE_CANCEL_BUTTON = "DISABLE_CANCEL_BUTTON",
  ENABLE_FINALIZE_DESIGN_BUTTON = "ENABLE_FINALIZE_DESIGN_BUTTON",
  DISABLE_FINALIZE_DESIGN_BUTTON = "DISABLE_FINALIZE_DESIGN_BUTTON",
  UNRECOVERABLE_ERROR = "UNRECOVERABLE_ERROR",
}

// This enum is a list of the post message types that iHD receives
export enum IFrameCommandType {
  FINALIZE_DESIGN = "FINALIZE_DESIGN",
  CANCEL_DESIGN = "CANCEL_DESIGN",
  OFFER_UPDATED = "OFFER_UPDATED",
  GENERATE_DESIGNS = "EVENT:GENERATE_DESIGNS",
  IHD_HACK_GO_TO_DESIGN = "IHD_HACK_GO_TO_DESIGN", // TODO replace with window event handling so we don't have to rely on pm's
}

export enum IFrameHostType {
  SPLAT = "SPLAT",
  LIGHTMILE = "LIGHTMILE",
  OFFER_BUILDER = "OFFER_BUILDER",
  LIGHTMILE_OFFER_BUILDER = "LIGHTMILE_OFFER_BUILDER",
  UNDEFINED = "undefined"
}

export enum SourceApp {
  IHD = "IHD",
  OFFER_BUILDER = "OFFER_BUILDER",
  LEGACY_SPLAT = "Legacy Splat"
}

export interface IFrameMessage {
  type: IFrameEventType | IFrameCommandType;
  source?: SourceApp;
  payload: {
    designId?: string;
    offerId?: string;
    error?: string;
    source?: SourceApp;
    isSuccess?: boolean;
    message?: string;
  };
}
