import * as React from "react";
import {DomEvent, LatLng, LeafletEventHandlerFnMap, LeafletPointerEvent, Map as LeafletMap} from "leaflet";
import {WorkspaceAction, WorkspaceDispatch} from "src/hooks/useWorkspace";
import {isNonWindowsTouchDevice} from "src/utils/isTouchScreen";
import {
  InputDevice,
  InteractiveLeafletLayer,
  MouseButtonOrKeyboardKey,
  WorkspaceEventType
} from "../types/state-management/action";
import {createWorkspaceAction, makeWorkspaceEvent} from "../actionResolvers/workspaceEvent";
import {distanceXY, latLngToPosition} from "@sunrun/design-tools-geometry";
import * as _ from "lodash"

/**
 * This creates a handler function map that can be used by the Leaflet map and all interactive leaflet layers
 */

export const createEventHandlers = (
  mapLayerKey: InteractiveLeafletLayer,
  dispatch: WorkspaceDispatch,
  map: LeafletMap
) : LeafletEventHandlerFnMap => {

  if (isNonWindowsTouchDevice){
    return {
      click: event => {
        if (treatAsClickEvent(event)) {
          dispatch(createWorkspaceActionForLeafletEvent("click", mapLayerKey, InputDevice.Touch, event, map))
        }
        dragStartedAt = null
      },
      touchstart: event => {
        dragStartedAt = event.latlng
        dispatch(createWorkspaceActionForLeafletEvent("pointerDown", mapLayerKey, InputDevice.Touch, event, map))
      },
      touchmove: event => {
        dispatch(createWorkspaceActionForLeafletEvent("pointerMove", mapLayerKey, InputDevice.Touch, event, map))
      },
      touchend: event => {
        dispatch(createWorkspaceActionForLeafletEvent("pointerUp", mapLayerKey, InputDevice.Touch, event, map))
      },
      contextmenu: event => {
        dispatch(createWorkspaceActionForLeafletEvent("contextmenu", mapLayerKey, InputDevice.Touch, event, map))
      }
    }
  }
  return {
    click: event => {
      if (treatAsClickEvent(event)){
        dispatch(createWorkspaceActionForLeafletEvent("click", mapLayerKey, InputDevice.Mouse, event, map))
      }
      dragStartedAt = null
    },
    mousedown: event => {
      isMouseDown = true
      dragStartedAt = event.latlng
      dispatch(createWorkspaceActionForLeafletEvent("pointerDown", mapLayerKey, InputDevice.Mouse, event, map))
    },
    mousemove: event => {
      // ignore mousemove events when the mouse isn't down
      if (isMouseDown) {
        dispatch(createWorkspaceActionForLeafletEvent("pointerMove", mapLayerKey, InputDevice.Mouse, event, map))
      }
    },
    mouseup: event => {
      isMouseDown = false
      dispatch(createWorkspaceActionForLeafletEvent("pointerUp", mapLayerKey, InputDevice.Mouse, event, map))
    },
    dblclick: event => {
      dispatch(createWorkspaceActionForLeafletEvent("dblclick", mapLayerKey, InputDevice.Mouse, event, map))
    }
  }
}

export const createWorkspaceActionForLeafletEvent = (
  eventType: WorkspaceEventType,
  mapLayerKey: InteractiveLeafletLayer,
  device: InputDevice,
  event: LeafletPointerEvent,
  map: LeafletMap
): WorkspaceAction => {
  const feature = event.propagatedFrom ? event.propagatedFrom.feature : {id: "map"}
  const button = resolveMouseButton(event)
  const stopPropagation = (event: LeafletPointerEvent) => {
    DomEvent.stopPropagation(event)
  }
  const stopEventPropagation = _.partial(stopPropagation, event)
  const workspaceEvent = makeWorkspaceEvent(latLngToPosition(event.latlng), feature, button, stopEventPropagation)
  return createWorkspaceAction(eventType, mapLayerKey,device, workspaceEvent, map)
}

const resolveMouseButton = (event: LeafletPointerEvent): MouseButtonOrKeyboardKey => {
  if ("button" in event.originalEvent) {
    return event.originalEvent.button == 0 ? MouseButtonOrKeyboardKey.left : MouseButtonOrKeyboardKey.right
  }
  return MouseButtonOrKeyboardKey.none // touch event
}

const MAX_POINTER_MOVE_BEFORE_CLICK = 1
let isMouseDown = false
let dragStartedAt: LatLng | null = null

// If the prior mousedown and mouseup events occurred more than a meter apart ignore the resulting click event: this
// indicates that the user is drawing a marquee
const treatAsClickEvent = (event: LeafletPointerEvent): boolean => {
  return dragStartedAt !== null
    && distanceXY(latLngToPosition(event.latlng), latLngToPosition(dragStartedAt)) < MAX_POINTER_MOVE_BEFORE_CLICK
}
