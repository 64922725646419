import React, {useRef} from "react";
import {GeoJSONProps,} from "react-leaflet";
import {Feature, LineString, MultiLineString, Point, Polygon} from "geojson";
import {useLeafletContext, withPane} from "@react-leaflet/core"
import L from "leaflet";


/**
 * This component required because the out of the box GeoJson support from react-leaflet is insufficient for our needs.
 * Specifically, we need a react-leaflet component that allows for efficient, non-destructive updates of Leaflet layer
 * geometry. Such an approach is well covered by in the documentation https://react-leaflet.js.org/docs/core-architecture/#improved-update-logic.
 * In react-leaflet v3, there is a bug that prevents this behavior when using higher order abstractions from
 * react-leaflet, but the lower level API does work and provides a familiar experience for those that know leaflet.
 * See https://github.com/PaulLeCam/react-leaflet/pull/1014
 *
 * For reference, the out of the box react-leaflet GeoJSON components does not support updates (See https://github.com/PaulLeCam/react-leaflet/issues/332),
 * and the suggested workaround (https://github.com/PaulLeCam/react-leaflet/issues/332#issuecomment-849679887) destroys
 * layers, which is problematic for various event handling behavior, and also very slow to rerender.
 *
 */

export interface GeoJsonLayerProps extends Omit<GeoJSONProps, 'data'> {
  data: Feature<Polygon | Point | LineString | MultiLineString, any>
}

// see https://github.com/Leaflet/Leaflet/blob/01f54796fea49232bce135ad7ea840f080273dce/src/layer/GeoJSON.js#L204-L204
const coordinateArrayNestingDepthForPolygons = 1
// see https://github.com/Leaflet/Leaflet/blob/01f54796fea49232bce135ad7ea840f080273dce/src/layer/GeoJSON.js#L199-L199
const coordinateArrayNestingDepthForLineStrings = 0
const coordinateArrayNestingDepthForMultiLineStrings = 1

export const GeoJsonLayer = ({ data, ...options }: GeoJsonLayerProps) => {
  const context = useLeafletContext()
  const layerRef = useRef<L.GeoJSON>()
  React.useEffect(
    function onMountAndUnmount() {
      layerRef.current = new L.GeoJSON(data, withPane(options, context))
      context.layerContainer?.addLayer(layerRef.current)

      return () => {
        if (layerRef.current) context.layerContainer?.removeLayer(layerRef.current)
      }
    },
    []
  )

  React.useEffect(
    function onGeometryChange() {
      // Feature Collections / Geometry Collections not supported, so there will only be a single layer
      const layer = layerRef.current?.getLayers()[0]
      if (layer instanceof L.Polygon) {
        layer.setLatLngs(L.GeoJSON.coordsToLatLngs(data.geometry.coordinates, coordinateArrayNestingDepthForPolygons))
      } else if (layer instanceof L.Circle) {
        layer.setLatLng(L.GeoJSON.coordsToLatLng(data.geometry.coordinates as [number, number] | [number, number, number]))
      } else if (layer instanceof  L.Polyline) {
        if (data.geometry.type === "LineString"){
          layer.setLatLngs(L.GeoJSON.coordsToLatLngs(data.geometry.coordinates, coordinateArrayNestingDepthForLineStrings))
        } else if (data.geometry.type === "MultiLineString"){
          layer.setLatLngs(L.GeoJSON.coordsToLatLngs(data.geometry.coordinates, coordinateArrayNestingDepthForMultiLineStrings))
        }
      }
    },
    [data.geometry.coordinates]
  )

  React.useEffect(
    function onStyleChange() {
      if (options.pathOptions)
        layerRef.current?.setStyle(options.pathOptions)
      else
        layerRef.current?.resetStyle()
    },
    [options.pathOptions]
  )

  React.useEffect(
    function onEventHandlersChange() {
      layerRef.current?.off()
      if (options.eventHandlers) layerRef.current?.on(options.eventHandlers)
    },
    [options.eventHandlers],
  )

  return null
}
