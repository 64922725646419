import * as React from "react";
import {useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {Customer, TestFixtures} from "@sunrun/design-tools-domain-model";
import {productAndPricingClient} from "@sunrun/design-tools-graphql-clients";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {LoadingProcessNames, LoadingProcessGroups} from "src/types/LoadingScreenProcess";
import {useWorkspace} from "src/hooks/useWorkspace";

/**
 * This is hack to make it easy for TestDataScenarios to mock out the customer object
 * TODO we probably should centralize query state to the context so that we can simply initialize the context exactly
 *   the way we want instead of hacking around in these hooks / repositories / etc
 */
let useCustomerMock: Customer | undefined
export function mockUseCustomer(mock: Customer){
  useCustomerMock = mock
}
export const useCustomer = () => {
  const {state, dispatch} = useWorkspace()
  const {siteModel} = state
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();

  const getCustomer = async () => {
    if (!siteModel?.prospectId) {
      throw Error(`A prospectId is required to get Customer`)
    }
    if (useCustomerMock) {
      return useCustomerMock
    }
    if (siteModel?.prospectId === "testFixture") {
      return TestFixtures.CustomerFixtures.customer;
    } else {
      return productAndPricingClient.queryCustomer(siteModel?.prospectId);
    }
  }

  const query = useQuery(['getCustomer', siteModel?.prospectId], getCustomer, {
    refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!siteModel?.prospectId,        // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newCustomer: Customer) => {
      dispatch({type: 'setCustomer', payload: newCustomer})
    },
    onError: handleError,
  })

  React.useEffect(function addLoadingScreenProcess() {
    if (query.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.CUSTOMER,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      })
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.CUSTOMER)
      }
    }
  }, [query.isFetching])
}

export type UseCustomer = ReturnType<typeof useCustomer>
