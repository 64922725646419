import React from "react";
import {useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {SiteModel} from "@sunrun/design-tools-domain-model";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {repository} from "@sunrun/design-tools-graphql-clients";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {useWorkspace} from "src/hooks/useWorkspace";

type UseSiteModelProps = {
  siteModelId?: string
  siteModelVersion?: number
}

export const useSiteModel = ({siteModelId, siteModelVersion}: UseSiteModelProps) => {
  const {state, dispatch} = useWorkspace()

  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();

  const getSiteModel = async (): Promise<SiteModel> => {
    if (siteModelId) return repository.get(SiteModel, siteModelId, siteModelVersion ?? 0)
    throw new Error("useQuery should be disabled when siteModelId is undefined")
  }

  const query = useQuery(['getSiteModel', siteModelId], getSiteModel, {
    refetchOnWindowFocus: false,            // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!siteModelId,                // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newSiteModel: SiteModel) => {
      dispatch({type: 'setSiteModel', payload:newSiteModel})
    },
    onError: handleError,
  })

  React.useEffect(function addLoadingScreenProcess() {
    if (query.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.SITE_MODEL,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      })
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.SITE_MODEL)
      }
    }
  }, [query.isFetching])
}
