import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";
import {Customer} from "@sunrun/design-tools-domain-model";

export type CustomerAction = PayloadAction<Customer, 'setCustomer'>

export const customerReducer = (state: WorkspaceState, action: WorkspaceAction): Customer | undefined => {
  switch (action.type) {
    case 'setCustomer': {
      return action.payload
    }
    default: {
      return state.customer
    }
  }
}

export const selectCustomer = (state: WorkspaceState) => state.customer
