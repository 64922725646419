import {ReactNode, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {
  Design,
  isFlatRoof,
  ModuleOrientation,
  ModulesAgreeWithRoofs,
  ModulesAre3DClosedRectanglesWithFiniteValues,
  SiteModel,
  SiteModelRoofsAre3DAndMatchAngles,
} from "@sunrun/design-tools-domain-model";
import {useWorkspace} from "src/hooks/useWorkspace";
import {useDomainModelWithSearchParams} from "src/hooks/useDomainModelWithSearchParams";
import {postIFrameMessage, useIFrameHost} from "src/hooks/useIFrameHost";
import useStyles from "../styles/styles";
import {deriveUnMetFinalizeDesignRequirements} from "../features/designGuidance/deriveDesignRequirements";
import {useOffer} from "src/hooks/useOffer";
import {usePromotions} from "src/hooks/usePromotions";
import {useProductAvailability} from "src/hooks/useProductAvailability";
import Workspace from "src/components/Workspace";
import LegacyWorkspace from "src/components/LegacyWorkspace";
import {IFrameEventType, IFrameHostType} from "src/types/IFrame";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

function reportDesignErrors(design: Design, siteModel: SiteModel) {
  const errs: { msg: string }[] = [];
  for (const m of design.modules) {
    errs.push(...ModulesAre3DClosedRectanglesWithFiniteValues(m));
  }
  errs.push(...ModulesAgreeWithRoofs({ design, siteModel }));
  if (errs.length > 0) {
    console.warn(
      `this design contains ${errs.length} inconsistencies which could cause poor behavior or crashes: `,
      errs
    );
  }
}

function reportSiteModelErrors(siteModel: SiteModel) {
  const errs: { msg: string }[] = [];
  errs.push(...SiteModelRoofsAre3DAndMatchAngles(siteModel));
  if (errs.length > 0) {
    console.warn(
      `this site contains ${errs.length} inconsistencies which could cause poor behavior or crashes: `,
      errs
    );
  }
}

export type WorkspaceRouteProps = {
  children: ReactNode;
};

export const WorkspaceRoute = ({ children }: WorkspaceRouteProps) => {
  const { state, dispatch } = useWorkspace();
  const {
    siteModel,
    design,
    solarResource,
    productionSimulation,
    geoRaster,
    customer,
    designConstraints,
    workflowState,
    moduleSelection,
  } = state;
  const { autoSaveStatus, legacyFinalizeDesign, simulateDesign, exportDesign } =
    useDomainModelWithSearchParams();
  const [disableFinalizeDesignButton, setDisableFinalizeDesignButton] =
    useState<boolean>(true);
  const [disableRotateButton, setDisableRotateButton] =
    useState<boolean>(false);
  const [
    disableFillRoofFaceAroundModuleButton,
    setDisableFillRoofFaceAroundModuleButton,
  ] = useState<boolean>(false);
  const [rotateSelectedModulesMsg, setRotateSelectedModulesMsg] =
    useState<string>("rotate selected modules");
  const { refetchOffer } = useOffer();
  useStyles();
  usePromotions();
  useProductAvailability();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const signedRootId = searchParams.get(URLSearchParameterKey.SignedRootId) || undefined;
    const effectiveDate = searchParams.get(URLSearchParameterKey.EffectiveDate) || undefined;
    const signedRootIdDashProduct = searchParams.get(URLSearchParameterKey.SignedRootIdDashProduct) || undefined;
    if (signedRootId) {
      dispatch({ type: "setSignedRootId", payload: signedRootId });
    }
    if (effectiveDate) {
      dispatch({ type: "setEffectiveDate", payload: effectiveDate });
    }
    if (signedRootIdDashProduct) {
      const productType = signedRootIdDashProduct.slice(-1)[0]
      if (productType) {
        dispatch({ type: "setSignedRootProductType", payload: productType });
      }
    }
  }, [searchParams]);

  const iFrameHost = useIFrameHost({
    legacyFinalizeDesign,
    refetchOffer,
    design,
    siteModel,
    productionSimulation,
    customer,
    designConstraints,
    geoRaster,
    solarResource,
  });
  const isOfferBuilderHost = 
    iFrameHost === IFrameHostType.OFFER_BUILDER ||
    iFrameHost === IFrameHostType.LIGHTMILE_OFFER_BUILDER;
  const isNotLightmileHost = iFrameHost !== IFrameHostType.LIGHTMILE;

  useEffect(() => {
    dispatch({ type: "setHost", payload: iFrameHost });
    dispatch({
      type: "setEnforceDesignRestrictionsForSales",
      payload: isNotLightmileHost,
    });
  }, [iFrameHost]);

  const updateHostFinalizeDesignButton = () => {
    const unMetDesignRequirements =
      deriveUnMetFinalizeDesignRequirements(state);

    const disableFinalizeDesign =
      !design ||
      workflowState.isSimulateDesignInProgress ||
      workflowState.isLegacyFinalizeDesignInProgress ||
      unMetDesignRequirements.length > 0;

    setDisableFinalizeDesignButton(disableFinalizeDesign);

    const FINALIZE_DESIGN_BUTTON_EVENT = disableFinalizeDesign
      ? IFrameEventType.DISABLE_FINALIZE_DESIGN_BUTTON
      : IFrameEventType.ENABLE_FINALIZE_DESIGN_BUTTON;
    postIFrameMessage(FINALIZE_DESIGN_BUTTON_EVENT, {
      designId: design?.id as string,
    });
  };

  const updateRotateButton = () => {
    if (design && siteModel) {
      const disableRotate = design.isRotateDisabled(
        moduleSelection.selectedModuleIds,
        siteModel
      );
      setDisableRotateButton(disableRotate);
      if (disableRotate && moduleSelection.selectedModuleIds.size > 0) {
        const modulesToRotate = Array.from(
          moduleSelection.selectedModuleIds
        ).map((id) => design!.getModuleById(id));
        const firstModuleToRotate = modulesToRotate[0]!;
        const roofFaceId = firstModuleToRotate.properties.roofFaceId;
        const roofFace = siteModel.getRoofFaceById(roofFaceId);
        if (roofFace && isFlatRoof(roofFace)) {
          setRotateSelectedModulesMsg(
            "To rotate modules on a flat roof, select one module or a single row of modules with same orientation"
          );
        } else {
          setRotateSelectedModulesMsg("please select modules on a roof face");
        }
      } else {
        setRotateSelectedModulesMsg("");
      }
    }
  };

  const updateFillRoofFaceButton = () => {
    if (design && siteModel) {
      const disableFillRoofFaceFromModuleButton =
        design.isFillRoofFaceAroundModuleDisabled(
          moduleSelection.selectedModuleIds,
          siteModel
        );
      setDisableFillRoofFaceAroundModuleButton(
        disableFillRoofFaceFromModuleButton
      );
    }
  };

  useEffect(
    () => updateHostFinalizeDesignButton(),
    [
      deriveUnMetFinalizeDesignRequirements(state),
      autoSaveStatus.isSaving,
      workflowState.isSimulateDesignInProgress,
      workflowState.isLegacyFinalizeDesignInProgress,
      design?.hasUnsavedChanges,
    ]
  );

  useEffect(() => {
    updateRotateButton();
    updateFillRoofFaceButton();
  }, [moduleSelection.selectedModuleIds]);

  useEffect(
    function runDiagnosticsToHelpFindBugs() {
      if (design && siteModel) reportDesignErrors(design, siteModel);
    },
    [siteModel, design]
  );
  useEffect(
    function runDiagnosticsToHelpFindBugs() {
      if (siteModel) reportSiteModelErrors(siteModel);
    },
    [siteModel]
  );

  useEffect(() => {
    // set module orientation
    // (mixed defaults to portrait)
    dispatch({
      type: "setModuleOrientationSetting",
      payload:
        design?.designParameters?.moduleOrientation ===
        ModuleOrientation.Landscape
          ? ModuleOrientation.Landscape
          : ModuleOrientation.Portrait,
    });
  }, [design?.designParameters?.moduleOrientation]);

  return isOfferBuilderHost ? (
    <Workspace
      children={children}
      // TODO: Refactor our state management to eliminate the need to prop drill here.
      // Calling useDomainModelWithSearchParams in multiple components currently causes issues with react-query.
      disableFinalizeDesignButton={disableFinalizeDesignButton}
      simulateDesign={simulateDesign}
      exportDesign={exportDesign}
    />
  ) : (
    <LegacyWorkspace
      disableFinalizeDesignButton={disableFinalizeDesignButton}
      children={children}
      autoSaveStatus={autoSaveStatus}
      isNotLightmileHost={isNotLightmileHost}
      legacyFinalizeDesign={legacyFinalizeDesign}
      disableRotateButton={disableRotateButton}
      disableFillRoofFaceAroundModuleButton={
        disableFillRoofFaceAroundModuleButton
      }
      rotateSelectedModulesMsg={rotateSelectedModulesMsg}
    />
  );
};
