import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";
import {SolarResource} from "@sunrun/design-tools-domain-model";

export type SolarResourceAction = PayloadAction<SolarResource, 'setSolarResource'>

export const selectSolarResource = (state: WorkspaceState) => state.solarResource

export const solarResourceReducer = (state: WorkspaceState, action: WorkspaceAction): SolarResource | undefined => {
  switch (action.type) {
    case 'setSolarResource': {
      return action.payload
    }
    default: {
      return state.solarResource
    }
  }
}
