import {Typography, Button, Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import {boolean} from "mathjs";
import satellitePng from 'src/assets/satelliteDefault.png'
import sunShadePng from 'src/assets/sunShadeDefault.png'
import {useWorkspace} from "src/hooks/useWorkspace";


export const FluxToggleButton = () => {
  const theme = useTheme<DesignToolsTheme>();
  const {state, dispatch} = useWorkspace()

  return (
    <Box
      component="div"
      data-component="FluxToggleButton"
      onClick={() => dispatch({type: 'toggleHeatMapVisibility'})} // TODO not sure how to fix this typing
      sx={{
        position: 'fixed',
        bottom: theme.spacing(3),
        left: theme.spacing(3),
        overflow: 'hidden',
        borderRadius: '3px',
        border: `2px solid white`, //figma says #ffffff
        width: 90,
        height: 80,
        zIndex: theme.zIndex.floatingActionButton,
        cursor: 'pointer'
      }}>
      <Box component="img"
           src={state.heatMap.isVisible ? satellitePng : sunShadePng}/>
      <Typography variant="body1" sx={{
        color: "white",
        size: "12px",
        zIndex: theme.zIndex.floatingActionButton + 1,
        position: 'absolute',
        bottom: theme.spacing(1),
        width: '100%',
        textAlign: 'center',
        userSelect: 'none'
      }}>{state.heatMap.isVisible ? "Satellite" : "Sun/Shade"}</Typography>
    </Box>)
}
