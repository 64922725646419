import {FeatureGroup, LayersControl, Pane, useMap} from "react-leaflet";
import {obstructionStripes} from "src/styles/stylingPatterns";
import {SiteModel} from "@sunrun/design-tools-domain-model";
import {ObstructionLayer} from "./ObstructionLayer";
import {LeafletLayerZIndexes} from "src/constants";
import { useWorkspace } from "src/hooks/useWorkspace";

type ObstructionLayerGroupProps = {
  siteModel: SiteModel
}

export const ObstructionFeatureGroup = (props: ObstructionLayerGroupProps) => {
  const map = useMap();
  obstructionStripes.addTo(map);
  const { state } = useWorkspace()
  const { settings } = state
  if (!settings.isObstructionsLayerVisible) return null

  return (
    <Pane name="obstructions" style={{zIndex: LeafletLayerZIndexes.obstructions}}>
      <FeatureGroup>
        {props.siteModel.obstructions.map(it =>
          <ObstructionLayer
            key={it.id}
            obstruction={it}
          />
        )}
      </FeatureGroup>
    </Pane>
  )
}
