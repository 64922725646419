import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useWorkspace} from "src/hooks/useWorkspace";
import {
  Customer,
  Design,
  DesignConstraints,
  GeoRaster,
  ProductionSimulation,
  SiteModel,
  SolarResource,
} from "@sunrun/design-tools-domain-model";
import {IFrameCommandType, IFrameEventType, IFrameHostType, IFrameMessage,} from "src/types/IFrame";
import {deriveIsDesignFinalized} from "src/features/designGuidance/deriveIsDesignFinalized";
import {URLSearchParameterKey} from "src/types/URLSearchParameterKey"

export const postIFrameMessage = (
  type: IFrameMessage["type"],
  payload: IFrameMessage["payload"]
) => {
  window.parent.postMessage(
    {
      type,
      payload,
    },
    "*"
  );
};

type UseIFrameHostProps = {
  legacyFinalizeDesign: () => Promise<void>;
  refetchOffer: () => Promise<void>;
  design?: Design;
  siteModel?: SiteModel,
  productionSimulation?: ProductionSimulation,
  customer?: Customer,
  designConstraints?: DesignConstraints,
  geoRaster?: GeoRaster,
  solarResource?: SolarResource,
};

export const useIFrameHost = ({
  legacyFinalizeDesign,
  refetchOffer,
  design,
  siteModel,
  productionSimulation,
  customer,
  designConstraints,
  geoRaster,
  solarResource,
}: UseIFrameHostProps): IFrameHostType => {
  const [searchParams] = useSearchParams();
  const { state: workspaceState, dispatch } = useWorkspace();
  const { workflowState } = workspaceState
  const [isFirstReady, setIsFirstReady] = useState<boolean>(true);

  const hostParam: string | null = searchParams.get(URLSearchParameterKey.Host);
  let iFrameHost: IFrameHostType
  switch (hostParam) {
    case IFrameHostType.LIGHTMILE: iFrameHost = IFrameHostType.LIGHTMILE; break
    case IFrameHostType.SPLAT: iFrameHost = IFrameHostType.SPLAT; break
    case IFrameHostType.OFFER_BUILDER: iFrameHost = IFrameHostType.OFFER_BUILDER; break
    case IFrameHostType.LIGHTMILE_OFFER_BUILDER: iFrameHost = IFrameHostType.LIGHTMILE_OFFER_BUILDER; break
    default: iFrameHost = IFrameHostType.UNDEFINED
  }
  const isHostOfferBuilder =
    iFrameHost === IFrameHostType.OFFER_BUILDER ||
    iFrameHost === IFrameHostType.LIGHTMILE_OFFER_BUILDER;

  useEffect(() => {
    if (
      isFirstReady &&
      design &&
      siteModel &&
      productionSimulation &&
      customer &&
      designConstraints &&
      geoRaster &&
      solarResource
    ) {
      setIsFirstReady(false);
      postIFrameMessage(IFrameEventType.READY, { designId: design.id });
    }
  }, [
    isFirstReady,
    design,
    siteModel,
    productionSimulation,
    customer,
    designConstraints,
    geoRaster,
    solarResource,
  ]);

  useEffect(() => {
    if (!isHostOfferBuilder) {
      const CANCEL_BUTTON_EVENT = workflowState.isLegacyFinalizeDesignInProgress ?
        IFrameEventType.DISABLE_CANCEL_BUTTON :
        IFrameEventType.ENABLE_CANCEL_BUTTON;
      postIFrameMessage(CANCEL_BUTTON_EVENT, { designId: design?.id as string });
    }
  }, [workflowState.isLegacyFinalizeDesignInProgress, isHostOfferBuilder]);

  useEffect(() => {
    const handlePostMessage = (e: MessageEvent<IFrameMessage>) => {
      switch (e.data.type) {
        //cancel design command
        case IFrameCommandType.CANCEL_DESIGN:
          if (!design) break;
          if (!deriveIsDesignFinalized(workspaceState)) {
            dispatch({ 
              type: "setCancelContinueModal", 
              payload: {
                title: 'Design is still in progress.', 
                message:'"Dismiss Changes" will discard any unsaved changes. Please finalize the design to save the modifications.',
                cancelText:'Continue Editing',
                continueText:'Dismiss Changes',
                onContinue:() => {
                  postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, { designId: design.id })
                  dispatch({ type: "dismissCancelContinueModal"})
                },
              }
            })
          } else {
            postIFrameMessage(IFrameEventType.DESIGN_EDIT_CANCEL, { designId: design.id });
          }
          break;
        //finalize design command
        case IFrameCommandType.FINALIZE_DESIGN:
          void legacyFinalizeDesign();
          break;

        // update offer command
        case IFrameCommandType.OFFER_UPDATED: // TODO remove once OE removes its usage
        case IFrameCommandType.IHD_HACK_GO_TO_DESIGN:
          refetchOffer();
          break;
      }
    };
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  });

  return iFrameHost
};
