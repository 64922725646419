import {WorkspaceAction, WorkspaceState} from "src/hooks/useWorkspace";
import {Design, ReadOnlyDesignSet} from "@sunrun/design-tools-domain-model";
import {PayloadAction} from "../../types/state-management/action";

export type ReadOnlyDesignAction = 
  | PayloadAction<ReadOnlyDesignSet, 'setSignedRootDesignSet'>
  | PayloadAction<ReadOnlyDesignSet, 'setInterconnectionAppliedDesignSet'>
  | PayloadAction<Design, 'setCopyFromDesign'>

export const selectSignedRootDesign = (state: WorkspaceState) => state.signedRootDesignSet

export const signedRootDesignSetReducer = (state: WorkspaceState, action: WorkspaceAction): ReadOnlyDesignSet | undefined => {
  switch (action.type) {
    case "setSignedRootDesignSet": {
      return action.payload
    }
    default: {
      return state.signedRootDesignSet
    }
  }
}

export const selectInterconnectionAppliedDesign = (state: WorkspaceState) => state.interconnectionAppliedDesignSet

export const interconnectionAppliedDesignSetReducer = (state: WorkspaceState, action: WorkspaceAction): ReadOnlyDesignSet | undefined => {
  switch (action.type) {
    case "setInterconnectionAppliedDesignSet": {
      return action.payload
    }
    default: {
      return state.interconnectionAppliedDesignSet
    }
  }
}

export const selectCopyFromDesign = (state: WorkspaceState) => state.copyFromDesign

export const copyFromDesignReducer = (state: WorkspaceState, action: WorkspaceAction): Design | undefined => {
  switch (action.type) {
    case "setCopyFromDesign": {
      return action.payload
    }
    default: {
      return state.copyFromDesign
    }
  }
}
