import React, {useEffect, useState} from "react";
import {useIsMutating, useMutation, useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {Design} from "@sunrun/design-tools-domain-model";
import {repository} from "@sunrun/design-tools-graphql-clients";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {IFrameEventType} from "src/types/IFrame";
import {postIFrameMessage} from "./useIFrameHost";
import {useWorkspace} from "src/hooks/useWorkspace";
import * as FullStory from "@fullstory/browser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc)

const AUTOSAVE_TIMER = 5000

type UseDesignProps = {
  designId?: string;
  designVersion?: number;
};

export interface AutoSaveStatus {
  isSaving: boolean;
  lastSavedAt?: Date;
}

let autoSaveTimer: ReturnType<typeof setTimeout>;

export const useDesign = ({designId, designVersion}: UseDesignProps) => {
  const {state, dispatch} = useWorkspace()
  const {design, workflowState} = state
  const [autoSaveStatus, setAutoSaveStatus] = useState<AutoSaveStatus>({
    isSaving: false,
  });
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();
  const getDesign = async (): Promise<Design> => {
    if (designId) return repository.get(Design, designId, designVersion ?? 0);
    throw Error(`useQuery should be disabled when designId is undefined.`);
  };
  const [didShowAutosaveError, setDidShowAutosaveError] = useState(false)
  // useIsMutating to skip any autosaves that would conflict with saveDesignMutation intiated in useSimulateDesign or useLegacyDesign
  const mutationInProgress = useIsMutating(['saveDesignMutation'])
  const saveDesignMutation = useMutation(async (design: Design): Promise<Design> => {
    if (didShowAutosaveError){
      return design
    }
    else if (!mutationInProgress){
      const saveResult = await repository.save(design);
      console.log(`Saving design ${design.id}:${design._version}`)
      return saveResult.v0
    }
    else {
      return design
    }
  }, {
    mutationKey:'saveDesignMutation',
    onMutate: () => {},
    onSettled: () => {},
    onError: async ()=>{
      if (!didShowAutosaveError){
        const serverDesign = await getDesign()
        dispatch({ type: "setInfoModal", payload: 
          { title: `Autosave Conflict`, 
            message: `You are not working on the most recent version of this design. Please close this session. 
              \nThe most recent changes were created by ${serverDesign?.createdBy} at ${dayjs.utc(serverDesign?.updatedAt).local().format('h:mma on YYYY-MM-DD')}.
              \nIf you believe you are seeing this message in error you may continue working and select overwrite when finalizing the design.
              \nYou may also see this message if you are working on the same session in multiple tabs or windows.`
          }})
        setDidShowAutosaveError(true)
      }},
    onSuccess: (newDesign: Design) => {
      setAutoSaveStatus({
        isSaving: false,
        lastSavedAt: new Date(newDesign.updatedAt as string),
      });

      // Update the version of the local design Object without altering the state.
      dispatch({type: 'resolveAutosave', payload: newDesign});
      FullStory.event("Autosaved Design Changes", {designId: newDesign.id, saveVersion: newDesign.latest})
    }
  });

  const query = useQuery(["getDesign", designId], getDesign, {
    refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!designId, // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newDesign: Design) => {
      dispatch({type: 'setDesign', payload: newDesign});
    },
    onError: handleError,
  });

  // Auto-save
  useEffect(() => {
    if (design && design.hasUnsavedChanges) {
      setAutoSaveStatus({
        isSaving: true,
      });
      clearTimeout(autoSaveTimer);
      autoSaveTimer = setTimeout(async () => {
        if (design.hasUnsavedChanges) {
          console.log('Autosaving design now...')
          await saveDesignMutation.mutateAsync(design)
        } else {
          console.log('Skipping autosave...')
        }

      }, AUTOSAVE_TIMER);
      return () => clearTimeout(autoSaveTimer);
    }
  }, [design]);

  useEffect(() => {
    const isOperationInProgress = workflowState.isSimulateDesignInProgress || workflowState.isLegacyFinalizeDesignInProgress
    if (design && !isOperationInProgress) {
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_START, {
        designId: design.id,
      });
    }
  }, [design]);

  React.useEffect(
    function addLoadingScreenProcess() {
      if (query.isFetching) {
        loadingScreenHelpers.addProcess({
          name: LoadingProcessNames.DESIGN,
          group: LoadingProcessGroups.INITIALIZE_IHD,
        });
        return function completeLoadingScreenProcess() {
          loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN);
        };
      }
    },
    [query.isFetching]
  );

  return {
    autoSaveStatus,
    saveDesignMutation
  };
};
