import {EmptyAction, PayloadAction, WorkspaceEvent} from "../../types/state-management/action";
import {produce} from "immer";
import {LoadedWorkspaceState, WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {Position,} from "@sunrun/design-tools-geometry";
import {Module} from "@sunrun/design-tools-domain-model";

export type ModuleDragAction =
  | PayloadAction<WorkspaceEvent, 'initModuleDrag'>
  | PayloadAction<WorkspaceEvent, 'dragModules'>
  | EmptyAction<'resetModuleDrag'>

export type ModuleDrag = {
  isPointerDown: boolean
  isDragging: boolean
  draggedModuleId?: string
  // interesting: using Readonly Vector2D seems internally incompatible with immer...
  originalDraggedModuleCenter?: [number, number]
  startPosition?: Position
  dragPosition?: Position
}

export const dragInitialState = {
  isPointerDown: false,
  isDragging: false,
  draggedModuleId: undefined,
  originalDraggedModuleCenter: undefined,
  startPoint: undefined,
  dragPoint: undefined
}

export const selectIsLeafletDragging = (state: WorkspaceState) => state.moduleDrag.isDragging

export const dragReducer = (state: WorkspaceState, action: WorkspaceAction): ModuleDrag => {
  const {design} = state as LoadedWorkspaceState
  switch (action.type) {
    case "initModuleDrag": {
      const event = action.payload
      const layer = event.propagatedFrom.layer as Module
      const moduleId = layer.id
      const draggedModuleCentroid = design.getModuleById(moduleId)!.centroid() as [number, number];
      return produce(state.moduleDrag, (draft) => {
        draft.isPointerDown = true
        draft.startPosition = event.position
        draft.draggedModuleId = moduleId
        draft.originalDraggedModuleCenter = draggedModuleCentroid
      });
    }

    case "dragModules": {
      return produce(state.moduleDrag, (draft) => {
        draft.isDragging = true
        draft.dragPosition = action.payload.position
      })
    }

    case "resetModuleDrag": {
      // reset to initial state
      return {...dragInitialState}
    }

    default: {
      return state.moduleDrag
    }
  }
}

