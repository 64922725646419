"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppSyncApiGraphqlApiKey = exports.getAppSyncApiGraphqlUrl = exports.appSyncClient = void 0;
const client_appsync_1 = require("@aws-sdk/client-appsync");
exports.appSyncClient = new client_appsync_1.AppSyncClient({ region: "us-west-2" });
function findAppSyncGraphqlApiByName(name) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        console.log(`Attempting to find AppSync graphql API with name ${name}`);
        let nextToken = undefined;
        do {
            const listGraphqlApis = yield exports.appSyncClient.send(new client_appsync_1.ListGraphqlApisCommand({ nextToken: nextToken }));
            const graphqlApi = (_a = listGraphqlApis === null || listGraphqlApis === void 0 ? void 0 : listGraphqlApis.graphqlApis) === null || _a === void 0 ? void 0 : _a.find(api => api.name === name);
            if (graphqlApi) {
                return graphqlApi;
            }
            nextToken = listGraphqlApis === null || listGraphqlApis === void 0 ? void 0 : listGraphqlApis.nextToken;
        } while (nextToken);
    });
}
function getAppSyncApiGraphqlUrl(name) {
    return __awaiter(this, void 0, void 0, function* () {
        const foundIt = yield findAppSyncGraphqlApiByName(name);
        if (!foundIt) {
            throw new Error(`Could not find an AppSync Graphql API with name ${name}`);
        }
        return foundIt.uris['GRAPHQL'];
    });
}
exports.getAppSyncApiGraphqlUrl = getAppSyncApiGraphqlUrl;
function getAppSyncApiGraphqlApiKey(name) {
    return __awaiter(this, void 0, void 0, function* () {
        const foundIt = yield findAppSyncGraphqlApiByName(name);
        if (!foundIt) {
            throw new Error(`Could not find an AppSync Graphql API with name ${name}`);
        }
        const listApiKeysResp = yield listApiKeys(foundIt.apiId);
        return listApiKeysResp[0].id;
    });
}
exports.getAppSyncApiGraphqlApiKey = getAppSyncApiGraphqlApiKey;
function listApiKeys(appSyncApiId) {
    return __awaiter(this, void 0, void 0, function* () {
        let apiKeys = [];
        let nextToken = undefined;
        do {
            const resp = yield exports.appSyncClient.send(new client_appsync_1.ListApiKeysCommand({
                apiId: appSyncApiId
            }));
            if (resp.apiKeys) {
                apiKeys = apiKeys.concat(resp.apiKeys);
            }
            nextToken = resp === null || resp === void 0 ? void 0 : resp.nextToken;
        } while (nextToken);
        return apiKeys;
    });
}
