import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {PayloadAction} from "../../types/state-management/action";
import {Design, ProductionSimulationType} from "@sunrun/design-tools-domain-model";

export type HackAction = PayloadAction<Design, 'setDesignAtSimulationTime'>

export const selectDesignAtSimulationTime = (state: WorkspaceState) => state.HACKS.designAtSimulationTime

export const hacksReducer = (state: WorkspaceState, action: WorkspaceAction) => {
  switch (action.type) {
    case 'setDesignAtSimulationTime': {
      return produce(state.HACKS, draft => {
        draft.designAtSimulationTime = action.payload
      })
    }
    case "setProductionSimulation": {
      const {simulation: newProductionSimulation, design: design} = action.payload
      if (newProductionSimulation.type===ProductionSimulationType.HighResolution){
        return produce(state.HACKS, draft => {
          const {design: newDesign} = action.payload
          draft.designAtSimulationTime = newDesign
        })
      }
      return state.HACKS
    }
    default: {
      return state.HACKS
    }
  }
}
