import React, {useState, SyntheticEvent } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DesignToolsTheme } from "@sunrun/design-tools-themes";
import {RotateRight, SelectAll, Delete, Info as InfoIcon } from '@material-ui/icons';
import { createStyles, makeStyles } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import { ModuleIssuesDialogButton } from "./ModuleIssuesDialogButton";

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "spin 4s linear infinite"
    }
  })
);

export type FloatingButtonContainerProps = {
  rotateModules: () => void;
  removeModules: () => void;
  fillRoofFaceAroundModule: () => void;
  isAnyModuleSelected: boolean;
  isMinimized: boolean;
  isRotateDisabled: boolean;
  isFillRoofFaceAroundModuleDisabled: boolean;
  rotateSelectedModulesMsg: string;
};

const buttonStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  padding: '10px 12px',
  borderRadius: '5px',
  cursor: 'pointer',
  transition: 'background .2s',
  background: '#ffffff',
  '&:hover': {
    background: '#f5f5f5',
  },
  '&:active': {
    background: '#eaeaea',
  },
  '&:nth-child(1)': {
    marginRight: '30px',
  }
}

export const FloatingActionButtonContainer = ({
  rotateModules,
  removeModules,
  fillRoofFaceAroundModule,
  isAnyModuleSelected,
  isMinimized,
  isRotateDisabled,
  isFillRoofFaceAroundModuleDisabled,
  rotateSelectedModulesMsg
}: FloatingButtonContainerProps) => {
  const theme = useTheme<DesignToolsTheme>();

  const onRotate = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    rotateModules()
  }

  const onFillRoofFaceAroundModule = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    fillRoofFaceAroundModule()
  }

  return isAnyModuleSelected ? (
    <Box
      sx={{
        position: "absolute",
        bottom: theme.spacing(2),
        width: "380px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: theme.zIndex.floatingActionButton,
        textAlign: "center",
      }}
    >
      <Tooltip
        title={rotateSelectedModulesMsg}
        placement="top"
      >
        <span>
        <Button disabled={isRotateDisabled}
          variant="contained"
          color="inherit"
          onClick={onRotate}
          size="small"
          style={{
            textTransform: "none",
            marginRight: theme.spacing(1),
            ...buttonStyles
          }}
          data-component="RotateModulesButton"
        >
          <RotateRight />{isMinimized ? '' : ' Rotate'}
        </Button>
      </span>
      </Tooltip>
      <Button
        variant="contained"
        color="inherit"
        onClick={removeModules}
        size="small"
        style={{textTransform: "none", ...buttonStyles}}
        data-component="RemoveModulesButton"
      >
        <Delete style={{color: '#CC0000'}} />{isMinimized ? '' : ' Remove'}
      </Button>
      
      <Tooltip
        title="fill the roof face when there is only one module on it"
        placement="top"
      >
        <span>
          <Button disabled={isFillRoofFaceAroundModuleDisabled}
            variant="contained"
            color="inherit"
            onClick={onFillRoofFaceAroundModule}
            size="small"
            style={{
              textTransform: "none",
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
              ...buttonStyles
            }}
            data-component="FillRoofFaceAroundModule"
          >
            <SelectAll />{isMinimized ? '' : ' FillAround'}
          </Button>
        </span>
      </Tooltip>

      <ModuleIssuesDialogButton 
        isMinimized={isMinimized} 
        buttonStyles={{
          ...buttonStyles, 
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(1)
        }} 
        />
    </Box>
  ) : null;
};
