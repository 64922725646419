import {LeafletMapContainer} from "src/components/LeafletMapContainer";
import {LeafletMapContainerEventHandler} from "src/components/LeafletMapEventHandler";
import {MapImageLayer} from "src/components/MapImageLayer";
import {RoofFaceFeatureGroup} from "src/components/RoofFaceFeatureGroup";
import {RoofFaceLayer} from "src/components/RoofFaceLayer";
import HeatMapLayer from "src/components/HeatMapLayer";
import {SetbackFeatureGroup} from "src/components/SetbackFeatureGroup";
import {ObstructionFeatureGroup} from "src/components/ObstructionFeatureGroup";
import {TreeFeatureGroup} from "src/components/TreeFeatureGroup";
import {ModuleFeatureGroup} from "src/components/ModuleFeatureGroup";
import {ModuleSpacingFeatureGroup} from "src/components/ModuleSpacingFeatureGroup";
import {ModuleLayer} from "src/components/ModuleLayer";
import {ModuleSpacingLayer} from "src/components/ModuleSpacingLayer";
import React from "react";
import {useWorkspace, WorkspaceState} from "src/hooks/useWorkspace";
import {ZoomControl, LayersControl} from "react-leaflet";
import {IFrameHostType} from "../types/IFrame";
import {selectHost} from "../features/host/hostSlice";
import {ZoomControl as CustomZoomControl} from "./MapControl";
import {Container} from "@mui/material";

export const WorkspaceLeafletMap = () => {
  const { state } = useWorkspace();
  const { siteModel, design, geoRaster } = state;
  const isOfferBuilderHost = 
    selectHost(state) === IFrameHostType.OFFER_BUILDER ||
    selectHost(state) === IFrameHostType.LIGHTMILE_OFFER_BUILDER;

  return (
    <>
      {design && siteModel && geoRaster && (
        <LeafletMapContainer siteModel={siteModel}>
          <LeafletMapContainerEventHandler />
          {isOfferBuilderHost ? offerBuilderLeafletMapLayers(state) : moduleAdjustmentLeafletMapLayers(state)}
        </LeafletMapContainer>
      )}
    </>
  );
};

const moduleAdjustmentLeafletMapLayers = (state: WorkspaceState) => {
  const { siteModel, design, solarResource, geoRaster } = state;
  return siteModel && design && solarResource && geoRaster && (
      <>
        <ZoomControl position={"bottomright"} />
        <LayersControl position="bottomright">
          <LayersControl.Overlay checked name="Map Image">
            <MapImageLayer geoRaster={geoRaster} />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Roof Faces">
            <RoofFaceFeatureGroup>
              {siteModel.roofFaces.map((it) => (
                <RoofFaceLayer key={it.id} roofFace={it} />
              ))}
            </RoofFaceFeatureGroup>
          </LayersControl.Overlay>
          {solarResource && (
          <HeatMapLayer
            solarResource={solarResource}
            siteModel={siteModel}
          />
        )}
        <LayersControl.Overlay checked name="Setbacks">
          <SetbackFeatureGroup siteModel={siteModel} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Obstructions">
          <ObstructionFeatureGroup siteModel={siteModel} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Module Spacing">
          <ModuleSpacingFeatureGroup>
            {design?.modules?.map((it) => {
              const roofFace = siteModel?.getRoofFaceById(it.properties.roofFaceId)
              return roofFace && (
                <ModuleSpacingLayer
                  key={it.getModuleSpacing(roofFace).id}
                  moduleSpacing={it.getModuleSpacing(roofFace)}
                />
              )
            })}
          </ModuleSpacingFeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Modules">
          <ModuleFeatureGroup>
            {design?.modules?.map((it) => (
              <ModuleLayer key={it.id} module={it} design={design} />
            ))}
          </ModuleFeatureGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Trees">
          <TreeFeatureGroup siteModel={siteModel}/>
        </LayersControl.Overlay>
      </LayersControl>
    </>
  )
}

const offerBuilderLeafletMapLayers = (state: WorkspaceState) => {
  const { siteModel, design, solarResource, geoRaster } = state;
  if (!siteModel || !design || !solarResource || !geoRaster) return;
  return (
    <>
      <MapImageLayer geoRaster={geoRaster} />
      <RoofFaceFeatureGroup>
        {siteModel.roofFaces.map((it) => (
          <RoofFaceLayer key={it.id} roofFace={it} />
        ))}
      </RoofFaceFeatureGroup>
      {solarResource && (
        <HeatMapLayer
          solarResource={solarResource}
          siteModel={siteModel}
        />
      )}
      <SetbackFeatureGroup siteModel={siteModel} />
      <ObstructionFeatureGroup siteModel={siteModel} />
      <ModuleSpacingFeatureGroup>
        {design?.modules?.map((it) => {
          const roofFace = siteModel?.getRoofFaceById(it.properties.roofFaceId)
          return roofFace && (
            <ModuleSpacingLayer
              key={it.getModuleSpacing(roofFace).id}
              moduleSpacing={it.getModuleSpacing(roofFace)}
            />
          )
      })}
      </ModuleSpacingFeatureGroup>
      <ModuleFeatureGroup>
        {design?.modules?.map((it) => (
          <ModuleLayer key={it.id} module={it} design={design} />
        ))}
      </ModuleFeatureGroup>
      <TreeFeatureGroup siteModel={siteModel} />
      <Container
        maxWidth="xl"
        sx={{ 
          position: "absolute", 
          bottom: 0,  
          left: 0, 
          right: 0, 
          marginX: "auto", 
          marginBottom: 2 
        }}
      >
        <CustomZoomControl />
      </Container>
    </>
  );
};
