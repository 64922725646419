import {Design, LowResProductionSimulation, Module, SnapPoint} from "@sunrun/design-tools-domain-model";
import {GeoJSON, useMap} from "react-leaflet";
import {ModuleProblemDecorator} from "./ModuleProblemDecorator";
import {Circle, LatLngBounds, LatLngExpression, Layer, LeafletEventHandlerFnMap, PathOptions} from "leaflet";
import {MapLabel, MapLabelProps} from "./MapLabel";
import {useWorkspace} from "src/hooks/useWorkspace";
import {GeoJsonLayer} from "src/components/GeoJsonLayer";
import React from "react";
import {deriveShortStringModules} from "src/features/designGuidance/deriveShortStringModules";
import {
  deriveLowResProductionSimulation
} from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import {deriveIsDesignFinalized} from "src/features/designGuidance/deriveIsDesignFinalized";
import {deriveCollidingModules} from "src/features/designGuidance/deriveCollidingModules";
import {deriveIslandedModules} from "src/features/designGuidance/deriveIslandedModules";
import {deriveLowSunHourModules} from "src/features/designGuidance/deriveLowSunHourModules";
import {deriveOffRoofModules} from "src/features/designGuidance/deriveOffRoofModules";
import {createEventHandlers} from "./LeafletEventHandler";
import {InteractiveLeafletLayer} from "src/types/state-management/action";
import {MarqueeSelectionLayer} from "./MarqueeSelectionLayer";
import { G2Rectangle } from "@sunrun/design-tools-geometry";

export type ModuleProps = {
  module: Module
  design: Design
}

function getSvgDataForLabel(module: Module, moduleSunHours: number | undefined, isModuleLowSunHours: boolean, useRedForLowSunHours: boolean): MapLabelProps | undefined {
  const rectangle = G2Rectangle.fromGeoJsonAndXAxis(module.geometry)
  //for landscape modules, we show sunhour label at roofface xAxis direction
  //for portrait modules, we show sunhour label at left to right direction
  const reverseYAxis = rectangle.yAxis().scale(-1)
  const reverseXAxis = rectangle.xAxis().scale(-1)
  const showLabelAtDirection = rectangle.isPortrait() ? 
    (reverseYAxis.x<0? rectangle.yAxis() : reverseYAxis) : 
    (reverseYAxis.y<0.1? rectangle.xAxis() : reverseXAxis)  
  const rectangleOrientedWithShowLabelDirectionAsXAxis = G2Rectangle.fromFourCornersCounterClockWiseAndXAxis(
    rectangle.fourCornersCounterClockwise(),
    showLabelAtDirection
  )
  //bbox is a polygon, since we get this from a rectangle, the first vertice is the rectnagle.lowerleft
  const bboxRectangleOrientedWithSouthernLongerSideAsXAxis = rectangleOrientedWithShowLabelDirectionAsXAxis.toPolygon().bbox()
  const bounds: LatLngBounds = new LatLngBounds([
      ...bboxRectangleOrientedWithSouthernLongerSideAsXAxis.vertices[0].toVector2D().toNumArray().reverse() as [number, number]
    ],[
      ...bboxRectangleOrientedWithSouthernLongerSideAsXAxis.vertices[2].toVector2D().toNumArray().reverse() as [number, number]
    ]
  );
  const angleRadians = Math.atan2(
    rectangleOrientedWithShowLabelDirectionAsXAxis.xAxis().toVector2D().y,
    rectangleOrientedWithShowLabelDirectionAsXAxis.xAxis().toVector2D().x
  )
  
  return {
    bounds,
    leafletPane: 'tooltipPane',
    fontStroke: (useRedForLowSunHours && isModuleLowSunHours) ? "red" : "white",
    angleDegrees: -180 * angleRadians / Math.PI,
    label: moduleSunHours?.toFixed(0)
  };
}

const moduleStyleFunctions = (isSelected: boolean): PathOptions => {
  if (isSelected) {
    return {
      fillOpacity: 1,
      fillColor: "#124B82",
      color: "#39A2FF",
      weight: 1,
    }
  }
  return {
    fillOpacity: 1,
    fillColor: "#232323",
    color: "#979797",
    weight: 1,
  }
}

const pointToLayer = (point: SnapPoint, latlng:LatLngExpression): Layer => {
  return new Circle(latlng, {radius: 0.08}) as Layer;
}
const getSnapPointColor = (module: Module, snapPoint: SnapPoint) => {
  return module.properties.orientation === snapPoint.properties.orientation ? "green" : "orange";
}

export const ModuleLayer = ({
                              module,
                              design,
                            }: ModuleProps) => {
  const map = useMap()
  const {state, dispatch} = useWorkspace()

  const [eventHandlers] = React.useState<LeafletEventHandlerFnMap>(createEventHandlers(InteractiveLeafletLayer.module, dispatch, map))

  const [pathOptions, setPathOptions] = React.useState<PathOptions>()

  React.useEffect(() => {
    setPathOptions(moduleStyleFunctions(isSelected))
  }, [state.moduleSelection.selectedModuleIds.has(module.id)])

  const isSelected = state.moduleSelection.selectedModuleIds.has(module.id)
  const collidingModules = deriveCollidingModules(state);
  const islandedModules = deriveIslandedModules(state);
  const shortStringModules = deriveShortStringModules(state);
  const hasError =
    collidingModules.has(module.id) ||
    shortStringModules.has(module.id) ||
    (state.settings.enforceDesignRestrictionsForSales && islandedModules.has(module.id))
  const showSnapPoints = false; // enable only for debugging purposes

  const roofFace = state?.siteModel?.getRoofFaceById(module.properties.roofFaceId)
  const [labelProps, setLabelProps] = React.useState<MapLabelProps>()
  const lowResProductionSimulation: LowResProductionSimulation | undefined = deriveLowResProductionSimulation(state);
  const moduleSunHours = lowResProductionSimulation?.sunHoursForModule(module.id);
  const lowSunHourModules = deriveLowSunHourModules(state);
  const isModuleLowSunHours = lowSunHourModules.has(module.id);
  React.useEffect(() => {
    const svgData = getSvgDataForLabel(module, moduleSunHours, isModuleLowSunHours, true)
    setLabelProps(svgData) // Always display low sun hours in Red. See LS-983
  }, [module.geometry, moduleSunHours])

  return (
    <>
      <GeoJsonLayer
        data={module}
        pathOptions={pathOptions}
        eventHandlers={eventHandlers}
        key={module.id}
        bubblingMouseEvents={false}
      />
      {
        showSnapPoints && roofFace &&
        module.getSnapPoints(roofFace).map(it => (
          <GeoJSON
            data={it}
            pointToLayer={pointToLayer}
            pathOptions={{color: getSnapPointColor(module, it)}}
            key={it.id}
          />
        ))
      }
      {
        state.settings.isSunHoursVisible && labelProps !== undefined && !hasError && !deriveIsDesignFinalized(state) &&
        <MapLabel
          leafletPane="tooltipPane"
          fontStroke={labelProps.fontStroke}
          bounds={labelProps.bounds}
          label={labelProps.label}
          angleDegrees={labelProps.angleDegrees}/>
      }
      {
        hasError &&
        <ModuleProblemDecorator
          module={module}
          isModuleSelected={isSelected}
        />
      }
      {
        state.marquee.selectionPolygon &&
        <MarqueeSelectionLayer marquee={state.marquee}/>
      }
    </>
  )
}

