import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {PayloadAction} from "../../types/state-management/action";
import {IFrameHostType} from "../../types/IFrame";

export type HostAction = PayloadAction<IFrameHostType, "setHost">

export const hostReducer = (state: WorkspaceState, action: WorkspaceAction): IFrameHostType | undefined => {
  switch(action.type) {
    case "setHost": {
      return action.payload
    }
    default: {
      return state.host ? state.host : undefined
    }
  }
}

export const selectHost = (state: WorkspaceState) => state.host
