import React from "react";
import { Button } from "@mui/material";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {ModuleIssuesDialog} from "./ModuleIssuesDialog";
import {useWorkspace} from "../hooks/useWorkspace";
import {deriveModulesWithIssues} from "src/features/designGuidance/deriveModulesWithIssues";

export const ModuleIssuesDialogButton = ({
  isMinimized,
  buttonStyles
}: { isMinimized: boolean, buttonStyles: any }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const {state} = useWorkspace();
  const {
    moduleSelection: { selectedModuleIds }
  } = state

  const modulesWithIssues = deriveModulesWithIssues(state);
  const selectedModulesHaveIssues = [...selectedModuleIds].some(moduleId => modulesWithIssues.has(moduleId));
  const showButton = selectedModuleIds.size > 0 && selectedModulesHaveIssues;
  return showButton ? (
    <>
      { isDialogOpen && <ModuleIssuesDialog closeDialog={() => setIsDialogOpen(false)} /> }
      <Button
        variant="contained"
        color="inherit"
        onClick={() => setIsDialogOpen(true)}
        style={ buttonStyles ? buttonStyles : {} }
        size="small"
        data-component="ModuleIssuesDialogButton"
      >
        <DoNotDisturbIcon style={{color: '#CC0000'}} />{isMinimized ? '' : ' Module Issues'}
      </Button>
    </>

  ) : null;
};
