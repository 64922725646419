import React, {useState} from "react";
import {Box, Collapse, Divider, IconButton, Paper, Stack, useMediaQuery, useTheme,} from "@mui/material";
import {DesignToolsTheme} from "@sunrun/design-tools-themes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ToolbarStats from "./components/ToolbarStats";
import {useWorkspace} from "src/hooks/useWorkspace";
import {deriveIsDesignFinalized} from "src/features/designGuidance/deriveIsDesignFinalized";
import {
  deriveLowResProductionSimulation
} from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";
import MoreDetails from "./components/MoreDetails";
import {WorkflowButton} from "./components/WorkflowButton";
import DesignStatus from "../DesignStatus";
import DesignMenu from "../DesignMenu";

export interface ToolbarProps {
  disableFinalizeDesignButton: boolean;
  simulateDesign: () => void;
  exportDesign: () => void;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { state, dispatch } = useWorkspace();
  const { design, solarResource, productionSimulation, customer, designConstraints, workflowState} = state
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);
  const theme = useTheme<DesignToolsTheme>();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const designFinalized = deriveIsDesignFinalized(state)
  const simulationToUse = designFinalized ? productionSimulation : deriveLowResProductionSimulation(state);
  const sunHours = isNaN(simulationToUse?.getSystemSunHours(design) ?? 0) ? 0 : simulationToUse?.getSystemSunHours(design) ?? 0;

  return (
    <Paper
      elevation={2}
      sx={{ 
        zIndex: theme.zIndex.appBar, 
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <DesignMenu />
        <Divider orientation="vertical" flexItem />
        <ToolbarStats
          customerUsage={customer?.annualUsagekWh ?? 0}
          annualProductionKwh={simulationToUse?.annualProductionKwh ?? 0}
          targetOffset={design?.designParameters?.targetUsageOffsetPercent ?? 0}
          maxOffset={designConstraints?.defaultConstraints?.maxOffsetPercentConstraint?.value ?? 0}
          dcSystemSizeKw={design?.dcSystemSizeKw ?? 0}
          sunHours={sunHours}
          minSunHours={designConstraints?.defaultConstraints?.minSunHoursConstraint?.value ?? 0}
          maxSunHours={solarResource?.siteMaxSunHours ?? 0}
        />
        <Divider orientation="vertical" flexItem />
        <DesignStatus />
        <Divider orientation="vertical" flexItem />
        {!isXs && (
          <Box sx={{
            paddingX: 2
          }}>
            <WorkflowButton {...props} buttonProps={{size: "sm", width: "100%"}} /> 
          </Box>
        )}
        <Divider orientation="vertical" flexItem />
        <IconButton
          color="primary"
          size="large"
          onClick={toggleExpand}
          sx={{ borderRadius: "inherit" }}
        >
          {expanded ? (
            <ExpandLessIcon fontSize="inherit" />
          ) : (
            <ExpandMoreIcon fontSize="inherit" />
          )}
        </IconButton>
      </Stack>
      <Box overflow="auto">
        <Divider />
        <Collapse in={expanded}>
          <MoreDetails />
        </Collapse>
      </Box>
    </Paper>
  );
};

export default Toolbar;
