import { Offers, ProductId } from "@sunrun/design-tools-domain-model";

import { deriveIsDesignFinalized } from "src/features/designGuidance/deriveIsDesignFinalized";
import { deriveLowResProductionSimulation } from "src/features/lowResProductionSimulation/deriveLowResProductionSimulation";

import type { WorkspaceState } from "src/hooks/useWorkspace";

const EVCHARGER_PROMOTION_ID = "01GXRHRCEZ5634GGKHRP54PNM3";

export const isEvChargerPromotionAvailable = (state: WorkspaceState): boolean => {
  // Promotions are only available on offers
  if (state.offer === undefined) {
    return false;
  }

  // EVCharger promotion is available for this offer
  const isEvChargerPromotionAvailable = !!state.promotions?.find((promotion) => promotion.id === EVCHARGER_PROMOTION_ID);
  if (!isEvChargerPromotionAvailable) {
    return false;
  }

  // EVCharger product is available for this offer
  const availableEvChargingProduct = state.productAvailability?.products?.find((product) => product.id === ProductId.EvCharging);
  const isEvChargerProductAvailable = !!availableEvChargingProduct?.variants?.length;
  if (!isEvChargerProductAvailable) {
    return false;
  }

  return true;
}

export const isEligibleForEvChargerPromotion = (state: WorkspaceState): boolean => {
  const usage = state.customer?.annualUsagekWh ?? 0;
  // TODO: Extract logic?, copy-pasted similarly in a few places
  const designFinalized = deriveIsDesignFinalized(state);
  const simulationToUse = designFinalized ? state.productionSimulation : deriveLowResProductionSimulation(state);
  const systemSize = simulationToUse?.annualProductionKwh ?? 0

  if (usage === 0) {
    throw new Error("Unable to determine promotion eligibility because customer shows no annual usage.\nPlease update cutomer usage in the Salesforce Opportunity.");
  }
  return (systemSize - usage >= 2000)
}

export const isEvChargerPromotionApplied = (state: WorkspaceState): boolean => {
  if (state.offer === undefined) {
    return false;
  }

  const hasEvCharger = !!Offers.getEvChargingOfferLine(state.offer)?.quantity
  return isEligibleForEvChargerPromotion(state) && hasEvCharger
}
