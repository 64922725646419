import React from "react";
import {useWorkspace} from "../../../hooks/useWorkspace";
import {useOfferLineUpdates} from "../../../hooks/useOfferLineUpdates";
import {postIFrameMessage} from "../../../hooks/useIFrameHost";
import {IFrameEventType, SourceApp} from "../../../types/IFrame";
import { SrButton } from "@sunrun/experience-ui-components";
import { ButtonProps } from "@sunrun/experience-ui-components/lib/typescript/components/Button/Button";
import {ProductChanges} from "@sunrun/design-tools-domain-model";
import {deriveDesignReadyForExport} from "../../../features/designGuidance/deriveDesignReadyForExport";
import { deriveOfferProductChanges } from "src/features/designGuidance/deriveOfferProductChanges";

export type WorkflowButtonProps = {
  disableFinalizeDesignButton: boolean
  simulateDesign: () => void;
  exportDesign: () => void;
  buttonProps?: ButtonProps;
}

export const WorkflowButton: React.FC<WorkflowButtonProps> = (props: WorkflowButtonProps) => {
  const {state, dispatch} = useWorkspace()
  const {design, offer} = state
  const {disableFinalizeDesignButton, simulateDesign, exportDesign, buttonProps} = props
  const {updateOfferLines} = useOfferLineUpdates()
  const readyToGoToPricing = deriveDesignReadyForExport(state)
  const productChanges = deriveOfferProductChanges(state)

  React.useEffect(() => {
    if (readyToGoToPricing) {
      if (productChanges.solar.newVariantId || productChanges.backup.newVariantId) {
        dispatch({type: "displayOfferUpdateConfirmationModal"})
      }
    }
  }, [readyToGoToPricing, productChanges])

  const exportDesignAndUpdateOffer = React.useCallback(async () => {
    await exportDesign()
    const {success} = await updateOfferLines(productChanges!)
    if (success) {
      postIFrameMessage(IFrameEventType.DESIGN_EDIT_COMPLETE, {designId: design!.id});
    }
  }, [productChanges, updateOfferLines]);

  if (readyToGoToPricing) {
    return <SrButton
      onPress={exportDesignAndUpdateOffer}
      onLongPress={exportDesignAndUpdateOffer}
      {...buttonProps}
    >
      Go To Pricing
    </SrButton>
  }
  return <SrButton
    isDisabled={disableFinalizeDesignButton}
    onPress={simulateDesign}
    onLongPress={simulateDesign}
    {...buttonProps}
  >
    Finalize Design
  </SrButton>

}
