import {Position} from "@sunrun/design-tools-geometry";
import {
  InputDevice,
  InteractiveLeafletLayer,
  WorkspaceEvent,
  MouseButtonOrKeyboardKey,
  WorkspaceLayer,
  WorkspaceEventType,
} from "../types/state-management/action";
import {Map as LeafletMap} from "leaflet";
import {WorkspaceAction} from "../hooks/useWorkspace";

// A WorkspaceEvent is an abstraction around Leaflet and window events:
//  * so they can both be handled the same way
//  * so we are binding to objects that are easier to mock during testing.
export const makeWorkspaceEvent = (
  position: Position,
  layer: WorkspaceLayer,
  button: MouseButtonOrKeyboardKey = MouseButtonOrKeyboardKey.left, // left mouse click by default
  stopEventPropagation: () => void = () => {}
): WorkspaceEvent => {
  return {
    position: position,
    propagatedFrom: {
      layer: layer
    },
    button: button,
    stopEventPropagation: stopEventPropagation
  }
}

export const createWorkspaceAction = (
  eventType: WorkspaceEventType,
  mapLayerKey: InteractiveLeafletLayer,
  device: InputDevice,
  event: WorkspaceEvent,
  map: LeafletMap
): WorkspaceAction => {
  return {
    type: eventType,
    meta: {layer: mapLayerKey, device: device, map: map},
    payload: event
  } as WorkspaceAction
}
