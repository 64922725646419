import type { Promotion } from "@sunrun/design-tools-domain-model";
import type { PayloadAction } from "src/types/state-management/action";
import type { WorkspaceAction, WorkspaceState } from "src/hooks/useWorkspace";

export type PromotionAction = PayloadAction<Promotion[], "setPromotions">;

export interface PromotionDisplayComponentProps {
  isEligible: boolean
  isApplied: boolean
  onCallToActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const promotionsReducer = (state: WorkspaceState, action: WorkspaceAction): Array<Promotion> | undefined => {
  switch (action.type) {
    case "setPromotions": {
      return action.payload;
    }
    default: {
      return state.promotions;
    }
  }
};
