import React from "react"
import {GeoJsonLayer} from "./GeoJsonLayer";
import {CollidableMarqueePolygon, Marquee} from "../features/marquee/marqueeSlice";

type MarqueeSelectionLayerProps = {
  marquee: Marquee
}

/**
 * A non-interactive leaflet layer that renders the marquee selection bounding box using a polygon generated by the
 * marquee state machine. That state machine is updated mainly by events on the roof face layer: there are no event
 * handlers for this component and it is specifically configured to ignore all events and pass them through to lower
 * layers.
 * @constructor
 */
export const MarqueeSelectionLayer = (props: MarqueeSelectionLayerProps) => {
  if (!props.marquee.selectionPolygon)  {
    return null
  }
  return (
    <GeoJsonLayer
      data={props.marquee.selectionPolygon}
      pathOptions={pathOptions}
    />
  )
}

const pathOptions = {
  stroke: true,
  color: '#39A2FF',
  weight: 1,
  fillOpacity: 0,
  dashArray: '3, 3',
  interactive: false
}
