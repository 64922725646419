export const Constants = {
  LeafletDragThresholdInMeters: .20,
  nudgeDistanceInMeters: 0.01
}

// https://leafletjs.com/examples/map-panes/
export const LeafletLayerZIndexes = {
  roofFaces: 410,
  setbacks: 411,
  obstructions: 412,
  moduleSpacing: 413,
  modules: 414,
  trees: 415
}
