import { IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useWorkspace } from "src/hooks/useWorkspace";
import { DesignMenuItemComponent } from "./DesignMenuItemComponent";
import { isMacOs, isMobile } from "react-device-detect";

export type DesignMenuItem = {
  title: string;
  value?: string;
  action: () => void;
  hotkey?: string | undefined;
};

export const DesignMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { state, dispatch } = useWorkspace();

  const getSystemHotkey = (hotkey: string) => {
    const modifierString = isMacOs ? "⌘" : "Ctrl+";
    if (isMobile) {
      return undefined;
    }
    return modifierString + hotkey;
  };

  let designMenuItems: DesignMenuItem[] = [
    {
      title: "Default Orientation",
      value: state.settings.moduleOrientation,
      action: () => dispatch({ type: "toggleModuleOrientationSetting" }),
      hotkey: getSystemHotkey("R")
    },
    {
      title: "Magnetic Snap",
      value: state.settings.isMagneticSnapEnabled ? "On" : "Off",
      action: () => dispatch({ type: "toggleIsMagneticSnapEnabled" }),
      hotkey: getSystemHotkey("M")
    },
    {
      title: "Magnetic Slide",
      value: state.settings.isMagneticSlideEnabled ? "On" : "Off",
      action: () => dispatch({ type: "toggleIsMagneticSlideEnabled" }),
      hotkey: getSystemHotkey("L")
    },
    {
      title: "What's New",
      action: () => {
        handleClose();
        dispatch({ type: "openWhatsNew" });
      },
    },
  ];

  if (!isMobile) {
    designMenuItems.push({
      title: "Hot Key Descriptions",
      action: () => {
        handleClose();
        dispatch({ type: "openHotKeyDescriptions" });
      },
      hotkey: getSystemHotkey("/")
    });
  }

  return (
    <>
      <IconButton
        id="design-button"
        aria-controls={open ? "design-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="primary"
        size="large"
        sx={{ borderRadius: "inherit" }}
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Menu
        id="design-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "design-button",
        }}
      >
        {designMenuItems.map((menuItem, index) => (
          <DesignMenuItemComponent
            key={menuItem.title}
            menuItem={menuItem}
            isLast={index === designMenuItems.length - 1}
          />
        ))}
      </Menu>
    </>
  );
};
