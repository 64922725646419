import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { ModalLayout } from "./components/ModalLayout";
import useModuleIssues from "src/hooks/useModuleIssues";

type ModuleIssuesModalProps = {
  open: boolean;
  onClose: () => void;
}

export const ModuleIssuesModal = ({ open, onClose }: ModuleIssuesModalProps) => {
  const {uniqueIssuesOfSelectedModules, getIssueDescription} = useModuleIssues()
  return(
    <ModalLayout 
      open={open}
      title="Module Issues"
      onClose={onClose}
      actionButtons
    >
      <Stack spacing={2}>
        <Typography
          variant="h6"
          color="primary"
        >
          The selected modules have the following issues:
        </Typography>
        <List>
          {[...uniqueIssuesOfSelectedModules].map((issue) => (
            <ListItem>
              <ListItemIcon>
                <ErrorIcon color="primary" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">
                  {getIssueDescription(issue)}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Stack>
    </ModalLayout>
  )
}