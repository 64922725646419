import {createSelector} from "reselect";
import {selectIsLeafletDragging} from "src/features/moduleDrag/moduleDragSlice";
import {LowResProductionSimulation, StaticReferences} from "@sunrun/design-tools-domain-model";
import {deriveLowResProductionSimulation} from "../lowResProductionSimulation/deriveLowResProductionSimulation";
import {selectIsModuleNudging} from "../moduleNudge/moduleNudgeSlice";

export const deriveLowSunHourModules = createSelector(
  [deriveLowResProductionSimulation, selectIsLeafletDragging, selectIsModuleNudging],
  (lowResProductionSimulation, isLeafletDragging, isModuleNudging): Set<string> => {
    if (isLeafletDragging || isModuleNudging) {
      // Only derive when drag/nudge completes
      return deriveLowSunHourModules.lastResult()
    }
    if (lowResProductionSimulation && lowResProductionSimulation instanceof LowResProductionSimulation) {
      return new Set<string>(lowResProductionSimulation.lowSunHourModules.map(m => m.moduleId));
    }
    return StaticReferences.EMPTY_SET;
  }
);
