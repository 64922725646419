
import { Typography } from "@mui/material";
import { ModalLayout } from "./components/ModalLayout";

type InfoModalProps = {
  title: string | undefined;
  message: string | undefined;
  open: boolean;
  onClose: () => void;
}

export const InfoModal = ({ title, message, open, onClose }: InfoModalProps) => {
  return(
    <ModalLayout 
      open={open}
      title={title}
      onClose={onClose}
      actionButtons
    >
      <Typography
        variant="body2"
      >
        {message}
      </Typography>
    </ModalLayout>
  )
}