import {useEffect} from "react";
import {useWorkspace} from "src/hooks/useWorkspace";
import {Map} from "leaflet";
import {
  EmptyAction,
  InputDevice,
  InteractiveLeafletLayer,
  KeyboardEventType,
  MouseButtonOrKeyboardKey,
  WorkspaceEvent
} from "src/types/state-management/action";
import {createWorkspaceAction, makeWorkspaceEvent} from "../actionResolvers/workspaceEvent";

export type HotkeyAction =
  EmptyAction<'keyupWindowShift'>
  | EmptyAction<'keydownWindowShift'>

const ArrowKeys: string[] = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

export type UseHotkeyProps = {
  map: Map
}

export const useHotkeys = ({ map }: UseHotkeyProps) => {
  const { state, dispatch } = useWorkspace();
  const {design, siteModel, moduleSelection} = state

  useEffect(() => {
    const keyupEventHandler = (keyupEvent: KeyboardEvent) => {
      const shift = keyupEvent.key === "Shift";
      if (shift) dispatch({type: "keyupWindowShift"});
      if (ArrowKeys.includes(keyupEvent.key)) {
        if (design && siteModel && moduleSelection.selectedModuleIds.size > 0) {
          const keyEvent: KeyboardEventType = keyupEvent.key as KeyboardEventType // type coercion: not great! FIXME
          const moduleId = Array.from(moduleSelection.selectedModuleIds)[0]; // this is arbitrary and that's ok
          const module = design!.getModuleById(moduleId)!;
          const event: WorkspaceEvent = makeWorkspaceEvent(module.centroid(), module, MouseButtonOrKeyboardKey.keyUp)
          dispatch(createWorkspaceAction(keyEvent, InteractiveLeafletLayer.module, InputDevice.Keyboard, event, map))
        }

      }
    }

    const keydownEventHandler = (keydownEvent: KeyboardEvent) => {
      const shift = keydownEvent.shiftKey;
      const ctrl = keydownEvent.ctrlKey;
      const cmd = keydownEvent.metaKey;

      const isMac = navigator.userAgent.indexOf("Mac") !== -1;
      const defaultCtrl = isMac ? cmd : ctrl;
      if(shift){
        dispatch({type: "keydownWindowShift"});
      } else if (defaultCtrl) {
        // control+ keys
        switch (keydownEvent.key) {
          case "h":
          case "H":
            keydownEvent.preventDefault();
            dispatch({ type: "toggleIsSunHoursVisible" })
            break;
          case "l":
          case "L":
            keydownEvent.preventDefault();
            dispatch({ type: "toggleIsMagneticSlideEnabled" })
            break;
          case "m":
          case "M":
            keydownEvent.preventDefault();
            dispatch({ type: "toggleIsMagneticSnapEnabled" })
            break;
          case "r":
          case "R":
            keydownEvent.preventDefault();
            dispatch({ type: "toggleModuleOrientationSetting" })
            break;
          //fillRoof
          case "f":
          case "F":
            keydownEvent.preventDefault();
            if (design && design.moduleCount > 0) {
              dispatch({type: "showSnackbarMessage", payload: "Can ONLY fill roof for empty design, please remove all modules first to continue."})
            } else {
              dispatch({type: "centerFillAllRoofFaces"});
            }
            break;
          case "/":
          case "?":
            dispatch({ type: "closeDesignMenu" })
            dispatch({ type: "openHotKeyDescriptions" })
            break;
        }
      } else {
        // no modifiers... will have to maybe refactor again if we ever care about shift
        switch (keydownEvent.key) {
          case "r":
          case "R":
            dispatch({ type: "rotateModules" })
            break;
          case "Delete":
          case "Backspace":
            dispatch({ type: "removeModules" })
            dispatch({ type: "clearSelectedModules" }) // smell: that you must know to clear selection after dispatching "removeModules"
            break;
          case "Escape":
            dispatch({ type: "clearSelectedModules" })
            break;
          case "ArrowLeft":
          case "ArrowRight":
          case "ArrowUp":
          case "ArrowDown":
            if (design && siteModel && moduleSelection.selectedModuleIds.size > 0) {
              const moduleId = Array.from(moduleSelection.selectedModuleIds)[0]; // this is arbitrary and that's ok
              const module = design!.getModuleById(moduleId)!;
              const event: WorkspaceEvent = makeWorkspaceEvent(module.centroid(), module, MouseButtonOrKeyboardKey.keyDown)
              dispatch(createWorkspaceAction(keydownEvent.key, InteractiveLeafletLayer.module, InputDevice.Keyboard, event, map))
            }
            break;
        }
      }
    }

    // Global keydown listener
    window.addEventListener('keydown', keydownEventHandler);
    window.addEventListener('keyup', keyupEventHandler);
    return () => {
      window.removeEventListener('keydown', keydownEventHandler);
      window.removeEventListener('keyup', keyupEventHandler);
    }
  }, [state.design, state.moduleSelection.selectedModuleIds]);
}
