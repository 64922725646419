import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Fragment } from "react";

type DesignStatusSectionProps = {
  title: string;
  status?: string;
  items?: string[];
};

const DesignStatusSection = ({
  title,
  status,
  items,
}: DesignStatusSectionProps) => {
  return (
    <>
      <ListItem>
        <Stack>
          <Typography variant="body1">{title}</Typography>
          {status && (
            <Typography variant="body1" color="primary">
              {status}
            </Typography>
          )}
        </Stack>
      </ListItem>
      {!!items?.length && (
        <ListItem divider>
          <List sx={{ pt: 0 }}>
            {items.map((item, index) => (
              <Fragment key={`item${index}`}>
                <ListItem dense>
                  <ListItemIcon>
                    <ErrorIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item} color="primary" />
                </ListItem>
                {items.length !== index + 1 && <Divider variant="inset" />}
              </Fragment>
            ))}
          </List>
        </ListItem>
      )}
    </>
  );
};

export default DesignStatusSection;
