import React from "react";
import {Button, Grid, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const ImportLightmileProspectByProspectForm = () => {
  const [prospectId, setProspectId] = React.useState<string>()
  const [offerId, setOfferId] = React.useState<string>()
  const [formButtonEnabled, setFormButtonEnabled] = React.useState(false)
  const navigate = useNavigate()

  const onClick = () => {
    if (prospectId && offerId)
      navigate(`/imports/lightmile/prospects/${prospectId}/offers/${offerId}`)
  }

  React.useEffect(() => {
    if (prospectId && offerId) setFormButtonEnabled(true)
    else setFormButtonEnabled(false)
  }, [prospectId, offerId])

  return (
    <div>
      <h2>Import Lightmile Projects by ProspectId</h2>
      <Grid item container direction={"row"} spacing={0}>
        <Grid item>
          <TextField
            label="prospectId"
            type="text"
            variant="outlined"
            required
            onChange={(event) => {
              setProspectId(event.target.value)
            }}
          />
          <TextField
            label="offerId"
            type="text"
            variant="outlined"
            required
            onChange={(event) => {
              setOfferId(event.target.value)
            }}
          />
        </Grid>
        <Grid item/>
        <Grid item>
          <Button
            variant="contained"
            onClick={onClick}
            disabled={!formButtonEnabled}
          >
            Import
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
