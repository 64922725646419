import * as React from "react";
import {useQuery} from "react-query";
import {useErrorHandler} from "react-error-boundary";
import {DesignConstraints, TestFixtures} from "@sunrun/design-tools-domain-model";
import {useLoadingScreen} from "@sunrun/design-tools-loading-screen";
import {productAndPricingClient} from "@sunrun/design-tools-graphql-clients";
import {parseLambdaError} from "src/utils/lambdaErrorParser";
import {LoadingProcessGroups, LoadingProcessNames} from "src/types/LoadingScreenProcess";
import {useWorkspace} from "src/hooks/useWorkspace";

/**
 * This is hack to make it easy for TestDataScenarios to mock out the DesignConstraints object
 * TODO we probably should centralize query state to the context so that we can simply initialize the context exactly
 *   the way we want instead of hacking around in these hooks / repositories / etc
 */
let useDesignConstraintsMock: DesignConstraints | undefined
export function mockUseDesignConstraints(mock: DesignConstraints){
  useDesignConstraintsMock = mock
}

export const useDesignConstraints = () => {
  const {state, dispatch} = useWorkspace()
  const {customer, mostRecentSignedDesign: {effectiveDate, productType}} = state
  const {helpers: loadingScreenHelpers} = useLoadingScreen();
  const handleError = useErrorHandler();

  const getDesignConstraints = async () => {
    if (!customer) {
      throw Error(`A customer is required to get DesignConstraints`)
    }
    if (useDesignConstraintsMock){
      return useDesignConstraintsMock
    }
    if (customer.prospectId === "testFixture") {
      return TestFixtures.DesignConstraintsFixtures.buildSimpleDesignConstraints();
    } else {
      return productAndPricingClient.queryDesignConstraintsByCustomer(customer, effectiveDate, productType);
    }
  }

  const query = useQuery(['getDesignConstraints', customer, effectiveDate, productType], getDesignConstraints, {
    refetchOnWindowFocus: false, // TODO support these use cases for conflict resolution
    refetchOnReconnect: false,
    enabled: !!customer,          // https://react-query.tanstack.com/guides/dependent-queries
    onSuccess: (newDesignConstraints: DesignConstraints) => {
      dispatch({type: "setDesignConstraints", payload: newDesignConstraints})
    },
    onError: (error: Error) => {
      const parsedError = parseLambdaError(error, new Error('Failed to get Design Constraints.'));
      handleError(parsedError);
    },
  })

  React.useEffect(function addLoadingScreenProcess() {
    if (query.isFetching) {
      loadingScreenHelpers.addProcess({
        name: LoadingProcessNames.DESIGN_CONSTRAINTS,
        group: LoadingProcessGroups.INITIALIZE_IHD,
      })
      return function completeLoadingScreenProcess() {
        loadingScreenHelpers.completeProcess(LoadingProcessNames.DESIGN_CONSTRAINTS)
      }
    }
  }, [query.isFetching])

}
