import { Box, Button, Dialog, Fab, Paper, Stack, SxProps, Typography, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SrButton } from "@sunrun/experience-ui-components";
import { useParams, useSearchParams } from "react-router-dom";
import { URLSearchParameterKey } from "src/types/URLSearchParameterKey"
import { useState } from "react";

type ModalLayoutProps = {
  title?: string;
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
  onContinue?: () => void | undefined;
  actionButtons?: boolean;
  cancelText?: string;
  continueText?: string;
  fullscreen?: boolean;
  displayCloseButton?: boolean;
  error?: Error;
};

export const ModalLayout = ({
  title,
  children,
  open,
  onClose,
  onContinue,
  actionButtons,
  cancelText,
  continueText,
  fullscreen,
  displayCloseButton = true,
  error,
}: ModalLayoutProps) => {
  const paperStyle: SxProps = {
    padding: 5,
    height: "100%",
  };
  const paperStyleFullScreen: SxProps = {
    ...paperStyle,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: 10,
  };
  const stackStyle: SxProps = {};
  const stackStyleFullScreen: SxProps = {
    ...stackStyle,
    width: "700px",
  };
  return (
    <>
    {/* transitionDuration is set to 0 to fix an visual bug for modals with payloads */}
      <Dialog open={open} onClose={onClose} fullScreen={fullscreen} transitionDuration={0}>
        <Paper elevation={2} sx={fullscreen ? paperStyleFullScreen : paperStyle}>
          <Stack spacing={2} sx={fullscreen ? stackStyleFullScreen : stackStyle}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
              {title && (
                <Typography variant="h5" color="primary">
                  {title}
                </Typography>
              )}
              {displayCloseButton && (
                <Fab
                  size="small"
                  color="primary"
                  onClick={onClose}
                  sx={{
                    mt: -3,
                    mr: -3
                  }}
                >
                  <CloseIcon />
                </Fab>
              )}
            </Box>
            {/* styling with whiteSpace pre-wrap so that children strings can pass \n for newline */}
            <Box overflow="auto" style={{whiteSpace: 'pre-wrap'}}>{children}</Box>
            {error?.stack && <ErrorStack errorStack={error?.stack} />}
            {actionButtons && (
              <ActionButtons
                onCancel={onClose}
                cancelText={cancelText}
                onContinue={onContinue}
                continueText={continueText}
              />
            )}
          </Stack>
        </Paper>
      </Dialog>
    </>
  );
};

type ActionButtonsProps = {
  onCancel: () => void;
  cancelText?: string;
  onContinue?: () => void;
  continueText?: string;
};

const ActionButtons = ({
  onCancel,
  cancelText,
  onContinue,
  continueText = "Continue",
}: ActionButtonsProps) => {
  cancelText = cancelText || (onContinue === undefined ? "Ok, Got it" : "Cancel")
  return (
    <Stack mt={2} direction="row" justifyContent="space-between" alignContent="center" spacing={2}>
       <Box width="238px">
        <SrButton
          size="sm"
          type={onContinue ? "secondary" : "primary"}
          onPress={onCancel}
        >
          {cancelText}
        </SrButton>
      </Box> 
      {onContinue && (
        <Box width="238px">
          <SrButton size="sm" onPress={onContinue}>
            {continueText}
          </SrButton>
        </Box>
      )}
    </Stack>
  );
};

const ErrorStack = ({ errorStack }: { errorStack: string }) => {
  const [displayErrorDetails, setDisplayErrorDetails] = useState(false);
  const onClickErrorDetails = () => {
    console.log(`onClickErrorDetails, displayErrorDetails=`, displayErrorDetails);
    setDisplayErrorDetails(!displayErrorDetails);
  };
  const { projectId, designIndex } = useParams();
  const [searchParams] = useSearchParams();
  const designId = searchParams.get(URLSearchParameterKey.DesignId);
  const offerId = searchParams.get(URLSearchParameterKey.OfferId);
  const lightmileProjectId = searchParams.get(
    URLSearchParameterKey.LightmileProjectId
  );
  const signedRootId = searchParams.get(URLSearchParameterKey.SignedRootId);
  const effectiveDate = searchParams.get(URLSearchParameterKey.EffectiveDate);
  const getDevInfoText = () => `
    designId: ${designId || "unknown"}
    lightmileProjectId: ${projectId || lightmileProjectId || "unknown"}
    designIndex: ${designIndex || "--"}
    offerId: ${offerId || "--"}
    signedRootId: ${signedRootId || "--"}
    effectiveDate: ${effectiveDate || "--"}
  `;

  return (
    <>
      <Button
        variant="text"
        color="info"
        onClick={onClickErrorDetails}
        size="small"
        sx={{
          width: "100%",
        }}
      >
        {displayErrorDetails ? "Hide Details" : "More Details"}
      </Button>
      {displayErrorDetails && (
        <Typography
          //@ts-ignore
          variant="mono"
          sx={{
            display: "block",
            overflow: "auto",
            border: "1px solid #aaaaaa",
            width: "100%",
            maxHeight: 200,
            marginTop: 1,
            padding: 1,
          }}
        >
          {getDevInfoText() + "\n" + errorStack.toString()}
        </Typography>
      )}
    </>
  );
};

