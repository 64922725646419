// https://thewebdev.info/2021/02/27/how-to-detect-a-touch-screen-device-using-javascript
import React from "react";

// Inform Typescript of legacy Microsoft navigatory property
// TODO: Remove this and the corresponding mxMaxTouchpoints logic below in favor of
// either 3rd party device detection or to ignore unsupported devices
declare global {
  interface Navigator {
    msMaxTouchPoints: number
  }
}

export const isTouchDevice = (('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  (navigator.msMaxTouchPoints > 0));

export const isWindowsDevice = navigator.userAgent.includes("Windows") // disable touch support on windows
export const isNonWindowsTouchDevice = isTouchDevice && !isWindowsDevice
