import {WorkspaceAction, WorkspaceState} from "../../hooks/useWorkspace";
import {produce} from "immer";
import {PayloadAction} from "../../types/state-management/action";
import {Design, ProductionSimulation} from "@sunrun/design-tools-domain-model";

export type ProductionSimulationAction = PayloadAction<{simulation: ProductionSimulation, design?: Design}, 'setProductionSimulation'>

export const selectProductionSimulation = (state: WorkspaceState) => state.productionSimulation

export const productionSimulationReducer = (state: WorkspaceState, action: WorkspaceAction): ProductionSimulation | undefined => {
  switch (action.type) {
    case 'setProductionSimulation': {
      const {simulation: newProductionSimulation} = action.payload
      return newProductionSimulation
    }
    default: {
      return state.productionSimulation
    }
  }
}
